import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Advertisement from "./pages/Advertisement";
import Requisition from "./pages/Requisition";

import UserGuide from "./pages/UserGuide";

import MenuBar from "./components/MenuBar";
import PanelistOralExam from "./pages/PanelistOralExam";
import PrivateRoute from "../common/guard/PrivateRoute";
import InvigilatorWrittenExam from "./pages/InvigilatorWrittenExam";
import Appeal from "./pages/Appeal";
import WaitingListPositions from "./pages/WaitingListPositions";
import WaitingListRequisitions from "./pages/WaitingListRequisitions";
import WaitingListCandidates from "./pages/WaitingListCandidates";
import WaitingListOffers from "./pages/WaitingListOffers";
import { useRequiredAuth } from "../common/guard/requiredAuth";
import InProgressAppeals from "./pages/InProgressAppeals";
import ArchivedAppeals from "./pages/ArchivedAppeals";
import AllOverdueAppeals from "./pages/AllOverdueAppeals";
import RecruitmentAdmin from "../admin/pages/Recruitment";
import RecruitmentRequest from "./pages/RecruitmentRequest";
import { isEmptyObject } from "jquery";
import { connect } from "react-redux";
import {
  countRecruitmentRequests,
  countNPSCReports,
} from "../../store/recruitment/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NPSCRecruitmentReport from "./pages/NPSCRecruitmentReport";
import NPSCEmployeeAppeals from "./pages/NPSCEmployeeAppeals";
const defaultRouters = [
  {
    to: "/recruitment/requisitions",
    permissions: ["IS_HR", "IS_CBM", "IS_DAF"],
  },
  {
    to: "/recruitment/oversight",
    permissions: ["IS_NPSC", "IS_RECRUITMENT_ADMIN"],
  },

  {
    to: "/recruitment/appeals/in-progress",
    permissions: [
      "IS_HR",
      "IS_CBM",
      "IS_DAF",
      "IS_NPSC",
      "IS_RECRUITMENT_MEMBER",
       "IS_RDB_SECOND_LEVEL"
    ],
  },
  {
    to: "/recruitment/advertisements",
    permissions: ["IS_HR", "IS_CBM", "IS_DAF", "IS_RECRUITMENT_MEMBER"],
  },
  {
    to: "/recruitment/panels",
    permissions: ["IS_PANELIST"],
  },
  {
    to: "/recruitment/invigilators",
    permissions: ["IS_INVIGILATOR"],
  },

  {
    to: "/recruitment/waiting-list",
    permissions: ["IS_HR", "IS_CBM", "IS_DAF", "IS_RECRUITMENT_ADMIN"],
  },
  {
    to: "/recruitment/requests",
    permissions: [
      "IS_HR",
      "IS_CBM",
      "IS_DAF",
      "IS_HEAD_OF_INSTITUTION",
      "IS_DOCUMENT_VERIFIER",
    ],
  },
  {
    to: "/recruitment/npsc-reports",
    permissions: [
      "IS_HR",
      "IS_CBM",
      "IS_DAF",
      "IS_NPSC",
      "IS_HEAD_OF_INSTITUTION",
      "IS_DOCUMENT_VERIFIER",
    ],
  },
];
const Layout = (props) => {
  const { hasPermissions } = useRequiredAuth();
  const { user, countRecruitmentRequests, countNPSCReports, env } = props;
  const history = useHistory();
  useEffect(() => {
    if (env !== "prod") {
      countRecruitmentRequests(user?.selectedEntitySector?.id);
      countNPSCReports(user?.selectedEntitySector?.id);
    }

    if (isEmptyObject(user)) return history.push("/");
  }, [user, env]);
  const renderDefaultRoute = () => {
    for (const router of defaultRouters) {
      if (hasPermissions(router.permissions)) {
        return router.to;
      }
    }

    // If no route is found with the necessary permissions, return the default route
    return "/recruitment/requisitions";
  };

  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/recruitment/dashboard" component={Dashboard} />
        <PrivateRoute
          children={<Requisition />}
          permissions={["IS_HR", "IS_CBM", "IS_DAF", "IS_TEST_USER"]}
          path="/recruitment/requisitions"
        />
        <PrivateRoute
          children={<Advertisement />}
          permissions={[
            "IS_HR",
            "IS_CBM",
            "IS_DAF",
            "IS_RECRUITMENT_MEMBER",
            "IS_TEST_USER",
          ]}
          path="/recruitment/advertisements"
        />
        <PrivateRoute
          children={<AppealsRouter />}
          permissions={[
            "IS_HR",
            "IS_CBM",
            "IS_DAF",
            "IS_NPSC",
            "IS_NPSC_APPROVER",
            "IS_RECRUITMENT_MEMBER",
            "IS_RECRUITMENT_ADMIN","IS_RDB_SECOND_LEVEL"
          ]}
          path="/recruitment/appeals"
        />

        <PrivateRoute
          children={<PanelistOralExam />}
          permissions={["IS_PANELIST"]}
          path="/recruitment/panels"
        />
        <PrivateRoute
          children={<InvigilatorWrittenExam />}
          permissions={["IS_INVIGILATOR"]}
          path="/recruitment/invigilators"
        />

        <PrivateRoute
          children={<RecruitmentAdmin />}
          permissions={["IS_NPSC", "IS_RECRUITMENT_ADMIN", "IS_TEST_USER"]}
          path="/recruitment/oversight"
        />
        {/* <PrivateRoute
          children={<MemberApplication />}
          permissions={["IS_RECRUITMENT_MEMBER"]}
          path="/recruitment/applications"
        /> */}

        <PrivateRoute
          children={<WaitingListRouter />}
          permissions={["IS_HR", "IS_CBM", "IS_DAF", "IS_RECRUITMENT_ADMIN"]}
          path="/recruitment/waiting-list"
        />
        <PrivateRoute
          children={<RecruitmentRequest />}
          permissions={[
            "IS_HR",
            "IS_CBM",
            "IS_DAF",
            "IS_HEAD_OF_INSTITUTION",
            "IS_DOCUMENT_VERIFIER",
          ]}
          path="/recruitment/requests"
        />
        <PrivateRoute
          children={<NPSCRecruitmentReport />}
          permissions={[
            "IS_HR",
            "IS_CBM",
            "IS_DAF",
            "IS_NPSC",
            "IS_HEAD_OF_INSTITUTION",
            "IS_DOCUMENT_VERIFIER",
          ]}
          path="/recruitment/npsc-reports"
        />

        <Route path="/recruitment/user-guide" component={UserGuide} />

        <Route
          path="/recruitment"
          component={() => <Redirect to={renderDefaultRoute()} />}
        />
      </Switch>
    </React.Fragment>
  );
};
const mapStateToProps = ({ user, env }) => {
  return {
    user,
    env,
  };
};
export default connect(mapStateToProps, {
  countRecruitmentRequests,
  countNPSCReports,
})(Layout);

const WaitingListRouter = () => {
  return (
    <React.Fragment>
      <Switch>
        <PrivateRoute
          children={<WaitingListPositions />}
          permissions={["IS_HR", "IS_CBM", "IS_DAF", "IS_RECRUITMENT_ADMIN"]}
          path="/recruitment/waiting-list/positions"
        />

        <PrivateRoute
          children={<WaitingListRequisitions />}
          permissions={["IS_HR", "IS_CBM", "IS_DAF"]}
          path="/recruitment/waiting-list/requisitions"
        />
        <PrivateRoute
          children={<WaitingListCandidates />}
          permissions={["IS_RECRUITMENT_ADMIN"]}
          path="/recruitment/waiting-list/candidates"
        />
        <PrivateRoute
          children={<WaitingListOffers />}
          permissions={["IS_RECRUITMENT_ADMIN"]}
          path="/recruitment/waiting-list/offers"
        />

        <Route
          path="/recruitment/waiting-list"
          component={() => (
            <Redirect to="/recruitment/waiting-list/positions" />
          )}
        />
      </Switch>
    </React.Fragment>
  );
};

const AppealsRouter = () => {
  const { hasPermissions } = useRequiredAuth();
  return (
    <React.Fragment>
      <Switch>
        <PrivateRoute
          children={<InProgressAppeals />}
          permissions={[
            "IS_HR",
            "IS_CBM",
            "IS_DAF",
            "IS_NPSC",
            "IS_NPSC_APPROVER",
            "IS_RECRUITMENT_MEMBER",
            "IS_RDB_SECOND_LEVEL"
          ]}
          path="/recruitment/appeals/in-progress"
        />

        <PrivateRoute
          children={<ArchivedAppeals />}
          permissions={[
            "IS_HR",
            "IS_CBM",
            "IS_DAF",
            "IS_NPSC",
            "IS_NPSC_APPROVER",
            "IS_RECRUITMENT_MEMBER",
            "IS_RDB_SECOND_LEVEL"
          ]}
          path="/recruitment/appeals/archived"
        />

        <PrivateRoute
          children={<NPSCEmployeeAppeals />}
          permissions={["IS_RECRUITMENT_ADMIN"]}
          path="/recruitment/appeals/npsc"
        />
        <PrivateRoute
          children={<AllOverdueAppeals />}
          permissions={["IS_NPSC", "IS_NPSC_APPROVER","IS_RDB_SECOND_LEVEL"]}
          path="/recruitment/appeals/overdue"
        />

        <Route
          path="/recruitment/appeals"
          component={() => (
            <Redirect
              to={
                hasPermissions(["IS_RECRUITMENT_ADMIN"])
                  ? "/recruitment/appeals/npsc"
                  : "/recruitment/appeals/in-progress"
              }
            />
          )}
        />
      </Switch>
    </React.Fragment>
  );
};
