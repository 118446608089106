import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { connect } from "react-redux";
import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: -10,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Breadcrumb = (props) => {
  const { env, noOfRecruitmentRequests, noOfNPSCReports } = props;

  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        {/* <>
       <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/recruitment/dashboard"
          className={` px-1 rounded   ${
            location.pathname.includes("/recruitment/dashboard")
              ? " active  "
              : " "
          }`}
        >
          <span className="text-truncate  menu-item">Dashboard</span>
        </Link>
        <HorizontalRuleIcon className="rotated" />

       </> */}

        {hasPermissions(["IS_HR", "IS_DAF", "IS_CBM", "IS_TEST_USER"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/recruitment/requisitions"
              className={` px-1 rounded   ${
                location.pathname.includes("/recruitment/requisitions")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Requisitions</span>
            </Link>
          </>
        )}
        {hasPermissions([
          "IS_HR",
          "IS_DAF",
          "IS_CBM",
          "IS_RECRUITMENT_MEMBER",
          "IS_TEST_USER",
        ]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/recruitment/advertisements"
              className={` px-1 rounded   ${
                location.pathname.includes("/recruitment/advertisements")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Advertisements</span>
            </Link>
          </>
        )}

        {hasPermissions([
          "IS_HR",
          "IS_DAF",
          "IS_CBM",
          "IS_NPSC_APPROVER",
          "IS_NPSC",
          "IS_RECRUITMENT_MEMBER",
          "IS_RECRUITMENT_ADMIN",
          "IS_RDB_SECOND_LEVEL"
        ]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/recruitment/appeals"
              className={` px-1 rounded   ${
                location.pathname.includes("/recruitment/appeals")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Appeals</span>
            </Link>
          </>
        )}

        {hasPermissions([
          "IS_HR",
          "IS_DAF",
          "IS_CBM",
          "IS_RECRUITMENT_ADMIN",
        ]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/recruitment/waiting-list"
              className={`  px-1 rounded  ${
                location.pathname.includes("/recruitment/waiting-list")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Waiting Lists</span>
            </Link>
          </>
        )}
        {hasPermissions(["IS_PANELIST"]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/recruitment/panels"
              className={`  px-1 rounded  ${
                location.pathname.includes("/recruitment/panels")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Oral Panels</span>
            </Link>
          </>
        )}

        {hasPermissions(["IS_INVIGILATOR"]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/recruitment/invigilators"
              className={`  px-1 rounded  ${
                location.pathname.includes("/recruitment/invigilators")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">
                Invigilator Exams
              </span>
            </Link>
          </>
        )}

        {hasPermissions([
          "IS_NPSC",
          "IS_RECRUITMENT_ADMIN",
          "IS_TEST_USER",
        ]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/recruitment/oversight"
              className={`  px-1 rounded  ${
                location.pathname.includes("/recruitment/oversight")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Oversights</span>
            </Link>
          </>
        )}

        {hasPermissions([
          "IS_HR",
          "IS_DAF",
          "IS_CBM",
          "IS_HEAD_OF_INSTITUTION",
          "IS_DOCUMENT_VERIFIER",
        ]) &&
          env !== "prod" && (
            <>
              <HorizontalRuleIcon className="rotated" />
              <Link
                style={{ textDecoration: "none", color: "#fff" }}
                to="/recruitment/requests"
                className={` px-1 rounded   ${
                  location.pathname.includes("/recruitment/requests")
                    ? "active"
                    : " "
                }`}
              >
                <StyledBadge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  color="error"
                  badgeContent={noOfRecruitmentRequests}
                >
                  <span className="text-truncate font-weight-bold menu-item">
                    All requests
                  </span>
                </StyledBadge>
              </Link>
            </>
          )}
        {hasPermissions([
          "IS_HR",
          "IS_DAF",
          "IS_CBM",
          "IS_NPSC",
          "IS_HEAD_OF_INSTITUTION",
          "IS_DOCUMENT_VERIFIER",
        ]) &&
          env !== "prod" && (
            <>
              <HorizontalRuleIcon className="rotated" />
              <Link
                style={{ textDecoration: "none", color: "#fff" }}
                to="/recruitment/npsc-reports"
                className={` px-1 rounded  ${
                  location.pathname.includes("/recruitment/npsc-reports")
                    ? "active"
                    : " "
                }`}
              >
                <StyledBadge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  color="error"
                  badgeContent={noOfNPSCReports}
                >
                  <span className="text-truncate font-weight-bold menu-item">
                    NPSC Reports
                  </span>
                </StyledBadge>
              </Link>
            </>
          )}
          
      </div>
    </>
  );
};

const mapStateToProps = ({ env, noOfRecruitmentRequests, noOfNPSCReports }) => {
  return {
    env,
    noOfRecruitmentRequests,
    noOfNPSCReports,
  };
};
export default connect(mapStateToProps, {})(Breadcrumb);
