import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import {
  Toolbar,
  Typography,
  Box,
  Button,
  IconButton,
  LinearProgress,
  CircularProgress,
  TextField,
} from "@mui/material";

import {
  getUnsignedTmpFiles,
  onUploadUnsignedFile,
  onRemoveUnsignedFile,
  onSignFiles,
} from "../../../store/e-files/actions";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { showError } from "../../toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NoResults from "../../common/components/NoResults";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NewUpload = (props) => {
  const {
    getUnsignedTmpFiles,
    onUploadUnsignedFile,
    unsignedTmpFiles,
    onRemoveUnsignedFile,
    onSignFiles,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [pdfAttachment, setPdfAttachment] = useState(null);
  const fileInputRef = useRef(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [showApproverForm, setShowApproverForm] = useState(false);
  const [pkiPassword, setPkiPassword] = useState("");
  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!unsignedTmpFiles.length) getUnsignedTmpFiles();
  }, []);

  const [errors, setErrors] = useState({
    hasError: false,
    attachmentHasErrors: [],
    pdfAttachmentHasError: false,
  });
  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 2074190 ? true : false;
  };

  const handleUploadedPDFFile = (event) => {
    setErrors({ ...errors, pdfAttachmentHasError: false });
    let pdfAttachment = event.target.files[0];

    if (
      convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf" &&
      pdfAttachment?.type !== "application/pdf"
    ) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
      fileInputRef.current.value = "";
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("Allowed file should not exceed 2MB");
      fileInputRef.current.value = "";
    } else {
      const reader = new FileReader();

      reader.onload = (e) => {
        setFileUrl(e.target.result);
      };

      reader.readAsDataURL(pdfAttachment);
      setPdfAttachment(pdfAttachment);
    }
  };
  const cleanUploadInput = () => {
    setFileUrl(null);
    fileInputRef.current.value = "";
  };
  const addPDFFile = () => {
    const error = {
      pdfAttachmentHasError: false,
      hasError: false,
    };
    if (!pdfAttachment) {
      error.pdfAttachmentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }

    const payload = new FormData();
    payload.append("file", pdfAttachment);

    onUploadUnsignedFile(payload, cleanUploadInput, setIsUploading);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="d-flex align-items-center justify-content-between">
              <Typography
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>Document</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>
                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    New Document
                  </span>
                </span>
              </Typography>

              {/* {hasPermissions(["IS_HR"]) && (
                <Button
                  onClick={() => {
                    setShowRequestForm(true);
                  }}
                  style={{
                    ...ActiveButton,
                    // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                    float: "right",
                  }}
                  size="md"
                >
                  <span className="material-icons">add</span>New Upload
                </Button>
              )} */}
            </Toolbar>
          </AppBar>
          {showUploadedDocument && uploadedDocument && (
            <PreviewPdfUrl
              showDocument={showUploadedDocument}
              setShowDocument={setShowUploadedDocument}
              document={{
                name: `View ${uploadedDocument?.name || ""}`,
                url:
                  process.env.REACT_APP_FILES_URL +
                  "/preview/" +
                  uploadedDocument?.id,
              }}
            />
          )}
          <div className="card service-card valid mb-2 mt-1">
            <div className="card-header" style={{ padding: "0.25rem 1.2rem" }}>
              Add Attachment
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-11">
                  <input
                    type="file"
                    name="file"
                    className="form-control"
                    accept="application/pdf"
                    placeholder="Select pdf file"
                    onChange={(e) => handleUploadedPDFFile(e)}
                    ref={fileInputRef}
                  />
                  {errors.pdfAttachmentHasError && (
                    <small className="text-danger mb-1">
                      Please, Type attachment name
                    </small>
                  )}
                </div>
                <div className="col-lg-1 mt-1">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!!errors.pdfAttachmentHasError || isUploading}
                    onClick={() => addPDFFile()}
                    size="md"
                  >
                    {isUploading ? "Uploading" : "Upload"}
                  </Button>
                </div>
              </div>
              <div className="mt-2">{isUploading && <LinearProgress />}</div>
            </div>
          </div>

          <div className="card service-card valid mb-2 mt-1">
            <div className="card-header" style={{ padding: "0.25rem 1.2rem" }}>
              Attachments
            </div>
            <div className="card-body">
              <div className="row">
                {!!errors?.attachmentHasErrors?.length && (
                  <>
                    {errors.attachmentHasErrors.map((document, index) => (
                      <div key={index}>
                        <div className={`form-group border border-danger p-2`}>
                          <legend
                            className="w-auto px-2"
                            style={{
                              fontFamily:
                                '"Roboto","Helvetica","Arial",sans-serif',
                              fontWeight: 400,
                              fontSize: "0.82rem",
                              lineHeight: "1em",
                              letterSpacing: "0.00938em",
                              color: `red `,
                            }}
                          >
                            {index + 1}.{" "}
                            <span className="ml-1">{document.name}</span>
                          </legend>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {unsignedTmpFiles.length > 0 && (
                  <div className="col-lg-12">
                    {unsignedTmpFiles.map((document, index) => (
                      <ItemCard
                        key={index}
                        document={document}
                        setShowUploadedDocument={setShowUploadedDocument}
                        setUploadedDocument={setUploadedDocument}
                        onRemoveUnsignedFile={onRemoveUnsignedFile}
                      />
                    ))}
                  </div>
                )}

                {unsignedTmpFiles.length === 0 && (
                  <div className="col-lg-12">
                    <NoResults />
                  </div>
                )}
              </div>
            </div>
          </div>
          {unsignedTmpFiles.length > 0 && (
            <div className="d-flex align-items-center justify-content-center mt-1">
              <Button
                color="success"
                variant="contained"
                disabled={!!errors.pdfAttachmentHasError || isSigning || isUploading}
                onClick={() => setShowApproverForm(true)}
                size="md"
              >
                {isSigning ? "Signing..." : "Sign document(s)"}
              </Button>
            </div>
          )}
          {showApproverForm && (
            <ConfirmationDialog
              disabled={!pkiPassword || isSigning}
              confirmationDialog={showApproverForm}
              message={
                <>
                  <p>Enter your RISA Digital signature password to sign</p>
                  <form>
                    <TextField
                      fullWidth
                      size="small"
                      id="pki-password"
                      rows={4}
                      name="pki-password"
                      type="password"
                      label="Enter your RISA Digital signature password to sign"
                      variant="outlined"
                      placeholder="Enter your RISA Digital signature password to sign"
                      value={pkiPassword}
                      onChange={(e) => {
                        setPkiPassword(e.target.value || "");
                      }}
                    />
                  </form>
                </>
              }
              setConfirmationDialog={setShowApproverForm}
              onYes={() => {
                onSignFiles(
                  { password: pkiPassword },
                  setIsSigning,
                  setShowApproverForm,
                  setPkiPassword,
                  history
                );
              }}
            />
          )}
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, unsignedTmpFiles }) => {
  return { user, loading, unsignedTmpFiles };
};
export default connect(mapStateToProps, {
  getUnsignedTmpFiles,
  onUploadUnsignedFile,
  onRemoveUnsignedFile,
  onSignFiles,
})(NewUpload);

const ItemCard = (props) => {
  const {
    document,
    index,
    setShowUploadedDocument,
    setUploadedDocument,
    onRemoveUnsignedFile,
  } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <>
      <div>
        <div className="form-group1 mb-2">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fas fa-paperclip"></i>
              </span>
            </div>

            <div
              className="form-control"
              style={{
                backgroundColor: "rgb(229, 246, 253)",

                color: "#007bff",
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowUploadedDocument(true);
                  setUploadedDocument({
                    name: document.name,
                    id: document.id,
                  });
                }}
              >
                {document.name}
              </span>

              <IconButton
                disabled={isDeleting}
                onClick={() => {
                  onRemoveUnsignedFile(document, setIsDeleting);
                }}
                style={{ marginTop: "-6px" }}
                color="error"
                className="float-right"
                aria-label="delete"
              >
                {isDeleting ? <CircularProgress size="20px" /> : <DeleteIcon />}
              </IconButton>
              <IconButton
                onClick={() => {
                  setShowUploadedDocument(true);
                  setUploadedDocument({
                    name: document.name,
                    id: document.id,
                  });
                }}
                style={{ marginTop: "-6px" }}
                color="info"
                className="float-right"
                aria-label="view"
              >
                <VisibilityIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
