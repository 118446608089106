import { defaultState } from "./state";
import e_files from "./action-types";
import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...e_files };

const unsignedTmpFiles = (tmpFilesState = defaultState.unsignedTmpFiles, action) => {
  switch (action.type) {
    case types.SET_UNSIGNED_TMP_FILES:
      return action.data;
    case types.ADD_UNSIGNED_TMP_FILE: {
      const _tmpFilesState = [...tmpFilesState];
      _tmpFilesState.unshift(action.data);
      return _tmpFilesState;
    }

    case types.DELETE_UNSIGNED_TMP_FILE:
      const _tmpFilesState = [...tmpFilesState];
      const index = _tmpFilesState.findIndex(({ id }) => id === action.data.id);

      _tmpFilesState.splice(index, 1);

      return _tmpFilesState;
    case types.CLEAN_STATE:
      return defaultState.unsignedTmpFiles;
    default:
      return tmpFilesState;
  }
};

export default {
  unsignedTmpFiles,
};
