import axios from "axios";
import download from "downloadjs";
import pako from "pako";
import { showSuccess, showError } from "../../app/toastify";
import { isSessionExpired, setUser } from "../common/actions";
import rbmTypes from "./action-types";
import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...rbmTypes };

export const getFiscalYears = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/rbm/fiscal-years");

      dispatch({
        type: types.SET_FISCAL_YEARS,
        data: data,
      });

      setUser(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const selectFiscalYear = (fiscalYear, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/api/rbm/select-fiscal-year", {
        id: fiscalYear.id,
      });

      dispatch({
        type: types.SET_OUTPUTS,
        data: [],
      });

      dispatch({
        type: types.SET_UNITY_INDICATORS,
        data: [],
      });

      dispatch({
        type: types.SET_MY_ACTIVITIES,
        data: [],
      });

      dispatch({
        type: types.SET_OVERSIGHT_EVALUATIONS,
        data: [],
      });

      dispatch({
        type: types.SET_UNITS_EVALUATIONS,
        data: [],
      });

      dispatch({
        type: types.SET_EMPLOYEES_EVALUATIONS,
        data: [],
      });

      dispatch({
        type: types.SET_SELECTED_FISCAL_YEAR,
        data: { ...fiscalYear },
      });
      // history.push("/rbm/outputs");
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSelectedFiscalYear = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/rbm/selected-fiscal-year");

      dispatch({
        type: types.SET_SELECTED_FISCAL_YEAR,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getUserEntities = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/rbm/user-entities");

      dispatch({ type: types.SET_USER_ENTITIES, data });

      setUser(data);
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const selectUserEntity = (userEntity, history) => {
  return async (dispatch) => {
    try {
      await axios.post("/api/rbm/select-user-entity", {
        id: userEntity.id,
      });

      dispatch({
        type: types.SET_OUTPUTS,
        data: [],
      });

      dispatch({
        type: types.SET_SELECTED_USER_ENTITY,
        data: { ...userEntity },
      });
      history.push("/rbm/outputs");
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSelectedUserEntity = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/rbm/selected-user-entity");

      dispatch({
        type: types.SET_SELECTED_USER_ENTITY,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getOutputs = (query, setTotalRecords, setLoader) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);

      const { data } = await axios.get(
        "/api/rbm/outputs?" + new URLSearchParams(query).toString()
      );

      setLoader(false);

      dispatch({
        type: types.SET_OUTPUTS,
        data: data.records,
      });

      setTotalRecords(data.totalRecords);

      dispatch({
        type: types.SET_SELECTED_OUTPUT,
        data: {},
      });

      dispatch({ type: types.END_LOADING });

      // history.push("/rbm/outputs");
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveOutput = (
  expectedResult,
  setFormData,
  setIsEditing,
  closeExpectedResultForm,
  increaseTotalRecods
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/rbm/outputs", expectedResult);

      dispatch({ type: types.END_LOADING });

      // setTimeout(() => {
      setFormData({
        id: null,
        reference: "",
        name: "",
        weight: 3,
        budget: 0,
      });

      if (expectedResult.id)
        dispatch({
          type: types.UPDATE_OUTPUT,
          data,
        });
      else {
        dispatch({
          type: types.ADD_NEW_OUTPUT,
          data,
        });
        increaseTotalRecods();
      }

      // }, 500);

      closeExpectedResultForm();
      setIsEditing(false);

      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteOutput = (id, setConfirmationDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete("/api/rbm/outputs/" + id);

      setConfirmationDialog(false);
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.DELETE_OUTPUT,
        id,
      });
      showSuccess(data.message);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const markOutputAsKey = ({ id, isRRAKey }, setConfirmationDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/rbm/outputs/mark-as-key/" + id, {
        isRRAKey,
      });

      setConfirmationDialog(false);
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveOutputAssignment = (assignmentData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/rbm/output-assignments", {
        assignmentData,
      });

      dispatch({ type: types.END_LOADING });

      if (assignmentData.length === 1) {
        dispatch({
          type: types.UPDATE_OUTPUT,
          data,
        });
      } else {
        dispatch({
          type: types.SET_OUTPUTS,
          data: data.records,
        });
      }

      onClose();
      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const removeOutputAssignment = (
  { outputId, assignmentId, contractId, quarterId, statusId },
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/rbm/output-assignments/" + outputId + "/" + assignmentId
      );

      onClose();

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data,
      });

      if (contractId) {
        dispatch({
          type: types.REMOVE_CONTRACT_ASSIGNMENT_ITEM,
          data: { assignmentId, quarterId, contractId, statusId },
        });
      }

      showSuccess("Assignment removed");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getIndicators = (expectedResultId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/rbm/indicators/" + expectedResultId
      );

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_INDICATORS,
        data,
      });

      dispatch({
        type: types.SET_SELECTED_INDICATOR,
        data: {},
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveIndicator = (
  indicator,
  setFormData,
  setIsEditing,
  setShowIndicatorForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/rbm/indicators", indicator);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (!!indicator.id)
        dispatch({
          type: types.UPDATE_INDICATOR,
          data: data.indicator,
        });
      else
        dispatch({
          type: types.ADD_NEW_INDICATOR,
          data: data.indicator,
        });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data.output,
      });

      setFormData({
        id: null,
        name: "",
        measurementUnit: "",
        weight: 3,
        baseline: 0,
        annualTarget: 0,
        quarter1Target: 0,
        quarter2Target: 0,
        quarter3Target: 0,
        quarter4Target: 0,
      });

      setShowIndicatorForm(false);
      setIsEditing(false);
      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteIndicator = (indicator, setConfirmationDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/rbm/indicators/" + indicator.id
      );

      setConfirmationDialog(false);
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.DELETE_INDICATOR,
        data: {
          id: indicator.id,
          // outputId: indicator.outputId,
        },
      });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data.output,
      });

      showSuccess("Deleted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveIndicator = (id, approvalStatus, setConfirmationDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put("/api/rbm/indicators/" + id, {
        approvalStatus,
      });

      setConfirmationDialog(false);
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_INDICATOR,
        data: data.indicator,
      });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data.output,
      });

      showSuccess(approvalStatus ? "Approved" : "Disapproved");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveAllIndicators = (outputId, setConfirmationDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put("/api/rbm/indicators/" + outputId, {
        outputId,
      });

      setConfirmationDialog(false);
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_INDICATORS,
        data: data.indicators,
      });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data.output,
      });

      showSuccess("All approved");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getIndicatorAssignments = (
  indicatorId,
  setShowAssignmentForm,
  handleCloseMenu
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/rbm/indicator-assignments/" + indicatorId
      );

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_INDICATOR_ASSIGNMENTS,
        data,
      });

      handleCloseMenu();
      setShowAssignmentForm(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveIndicatorAssignment = (
  assignmentData,
  resetFormData,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/rbm/indicator-assignments", {
        assignmentData,
      });

      resetFormData();
      onClose();

      dispatch({ type: types.END_LOADING });

      if (assignmentData.length === 1)
        dispatch({
          type: types.UPDATE_INDICATOR,
          data: data.indicator,
        });
      else
        dispatch({
          type: types.SET_INDICATORS,
          data: data.indicators,
        });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data.output,
      });

      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateIndicatorTargetAssignment = (
  indicatorId,
  assignmentData,
  setUserQuarterValues,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.put("/api/rbm/indicator-assignments-target", {
        indicatorId,
        assignmentData,
      });

      dispatch({ type: types.END_LOADING });

      onClose();

      setUserQuarterValues([]);

      // dispatch({
      //   type: types.UPDATE_INDICATOR,
      //   data: data.assignments,
      // });

      dispatch({
        type: types.UPDATE_INDICATOR_TARGET_SHARE,
        data: { indicatorId, assignmentData },
      });

      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removeIndicatorAssignment = (
  {
    employeePositionId,
    indicatorId,
    assignmentId,
    contractId,
    quarterId,
    statusId,
  },
  setConfirmRemoveAssignment
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/rbm/indicator-assignments/" +
          employeePositionId +
          "/" +
          indicatorId +
          "/" +
          assignmentId
      );

      setConfirmRemoveAssignment(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_INDICATOR,
        data: data.indicator,
      });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data.output,
      });

      if (contractId) {
        dispatch({
          type: types.REMOVE_CONTRACT_ASSIGNMENT_ITEM,
          data: { assignmentId, quarterId, contractId, statusId },
        });
      }

      showSuccess("Assignment removed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getIndicatorActivities = (indicatorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_INDICATOR_ACTIVITIES,
        data: [],
      });

      const { data } = await axios.get("/api/rbm/activities/" + indicatorId);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_INDICATOR_ACTIVITIES,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveActivity = (
  activity,
  setFormData,
  setIsEditing,
  setShowActivityForm,
  quarter
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/rbm/activities", activity);

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      if (!!activity.id)
        dispatch({
          type: types.UPDATE_ACTIVITY,
          data: data.activity,
        });
      else
        dispatch({
          type: types.ADD_NEW_ACTIVITY,
          data: data.activity,
        });

      dispatch({
        type: types.UPDATE_INDICATOR,
        data: data.indicator,
      });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data.output,
      });

      setFormData({
        id: null,
        name: "",
        weight: 3,
        targetShare: 0,
        startOn: quarter.startOn,
        endOn: quarter.endOn,
      });

      setShowActivityForm(false);
      setIsEditing(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteActivity = (activity, setConfirmationDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete("/api/rbm/activities/" + activity.id);

      dispatch({ type: types.END_LOADING });
      setConfirmationDialog(false);

      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_ACTIVITY,
        data: {
          quarterTargetId: activity.quarterTargetId,
          id: activity.id,
        },
      });

      dispatch({
        type: types.UPDATE_INDICATOR,
        data: data.indicator,
      });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data.output,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getUnits = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/rbm/units");

      dispatch({
        type: types.SET_UNITS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSubordinates = (supervisorId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/rbm/subordinates");

      dispatch({
        type: types.SET_SUBORDINATES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSubordinateContracts = (query, setTotalRecords, setLoader) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setLoader(true);

      const { data } = await axios.get(
        "/api/rbm/subordinate-contracts?" +
          new URLSearchParams(query).toString()
      );
      setLoader(false);
      dispatch({
        type: types.SET_SUBORDINATE_CONTRACTS,
        data: data.records,
      });

      setTotalRecords(data.totalRecords);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      setLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      dispatch({ type: types.END_LOADING });
    }
  };
};

export const getSupervisors = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SUPERVISORS,
        data: [],
      });

      const { data } = await axios.get("/api/rbm/supervisors");

      dispatch({
        type: types.SET_SUPERVISORS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getTechnicalHeads = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/rbm/technical-heads");

      dispatch({
        type: types.SET_TECHNICAL_HEADS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveContract = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/rbm/contracts", formData);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_SUBORDINATE_CONTRACT,
        data,
      });

      onClose();

      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getMyActivities = (contractId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_MY_ACTIVITIES,
        data: [],
      });

      const { data } = await axios.get(
        `/api/rbm/my-contract-items/${contractId}`
      );

      dispatch({
        type: types.SET_MY_ACTIVITIES,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getContractItems = (contractId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_CONTRACT_ITEMS,
        data: [],
      });

      const { data } = await axios.get(`/api/rbm/contract-items/${contractId}`);

      dispatch({
        type: types.SET_CONTRACT_ITEMS,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveActivityAssignment = (assignmentFormData, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/rbm/activity-assignments", {
        assignmentFormData,
      });

      setFormData([]);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_INDICATOR,
        data: data.indicator,
      });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data.output,
      });

      dispatch({
        type: types.UPDATE_ACTIVITY,
        data: data.activity,
      });

      if (data.subordinates) {
        dispatch({
          type: types.SET_SUBORDINATES,
          data: data.subordinates,
        });
      }

      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removeActivityAssignment = (
  { id, contractId, quarterId, statusId },
  setConfirmRemoveAssignment
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/rbm/activity-assignments/" + id
      );

      setConfirmRemoveAssignment(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_ACTIVITY,
        data: data.activity,
      });

      dispatch({
        type: types.UPDATE_INDICATOR,
        data: data.indicator,
      });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data.output,
      });

      if (data.subordinates) {
        dispatch({
          type: types.SET_SUBORDINATES,
          data: data.subordinates,
        });
      }

      if (contractId) {
        dispatch({
          type: types.REMOVE_CONTRACT_ASSIGNMENT_ITEM,
          data: { assignmentId: id, quarterId, statusId, contractId },
        });
      }

      showSuccess("Removed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// export const evaluateSubmission = (
//   { submissionId, accepted, description, quality, assignment },
//   cb
// ) => {
//   cb(true, false);
//   return async (dispatch) => {
//     try {
//       dispatch({ type: types.START_LOADING });

//       await axios.post("/api/rbm/submission-evaluations", {
//         submissionId,
//         accepted,
//         description,
//         quality,
//       });

//       dispatch({ type: types.END_LOADING });

//       showSuccess(
//         `${accepted ? "Submission accepted" : "Submission rejected"}`
//       );

//       dispatch({
//         type: types.SET_ITEMS_EVALUATION,
//         data: assignment,
//       });

//       // dispatch({
//       //   type: types.SET_SELECTED_ACTIVITY,
//       //   data: {
//       //     ...data.activity,
//       //     // assignments: selectedActivity.assignments,
//       //     submissions: [
//       //       { ...selectedActivity.submissions[0], evaluation: data.evaluation },
//       //     ],
//       //   },
//       // });

//       // dispatch({
//       //   type: types.UPDATE_INDICATOR,
//       //   data: data.indicator,
//       // });

//       // dispatch({
//       //   type: types.UPDATE_OUTPUT,
//       //   data: data.expectedResult,
//       // });
//       cb(false, true);
//     } catch (error) {
//       cb(false, false);
//       dispatch({ type: types.END_LOADING });
//       showError(error);
//     }
//   };
// };

export const evaluateSubmission = (
  evaluationFormData,
  setIsSubmitting,
  setConfirm
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);
      dispatch({ type: types.START_WAIT });

      const { data } = await axios.post(
        "/api/rbm/submission-evaluations",
        evaluationFormData
      );

      dispatch({
        type: types.UPDATE_CONTRACT_ITEM,
        data: data.item,
      });

      dispatch({
        type: types.SET_SUBMISSIONS,
        data: data.submissions,
      });

      dispatch({
        type: types.UPDATE_SUBORDINATE_CONTRACT,
        data: data.subordinate,
      });

      dispatch({ type: types.END_WAIT });
      setIsSubmitting(false);
      setConfirm(false);

      showSuccess("Saved successfully");
    } catch (error) {
      setIsSubmitting(false);
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removeEvaluation = (
  evaluationFormData,
  setConfirmRemoveEvaluation
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/rbm/delete-submission-evaluation",
        evaluationFormData
      );

      dispatch({ type: types.END_LOADING });

      setConfirmRemoveEvaluation(false);

      dispatch({
        type: types.UPDATE_CONTRACT_ITEM,
        data: data.item,
      });

      dispatch({
        type: types.SET_SUBMISSIONS,
        data: data.submissions,
      });

      dispatch({
        type: types.UPDATE_SUBORDINATE_CONTRACT,
        data: data.subordinate,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getUnityIndicators = (query, setTotalRecords, setLoader) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);

      const { data } = await axios.get(
        "/api/rbm/unit-indicators?" + new URLSearchParams(query).toString()
      );

      setLoader(false);

      dispatch({
        type: types.SET_UNITY_INDICATORS,
        data: data.records,
      });

      setTotalRecords(data.totalRecords);

      dispatch({
        type: types.SET_SELECTED_INDICATOR,
        data: {},
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const selectActivity = (
  activity,
  handleCloseMenu,
  setShowEvaluationDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/rbm/submissions/" + activity.id);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_SELECTED_ACTIVITY,
        data: { ...activity, submissions: data },
      });

      handleCloseMenu();
      setShowEvaluationDialog(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const submitActivityReport = (
  activity,
  reportData,
  setReportFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/rbm/submissions", reportData);

      dispatch({ type: types.END_LOADING });

      setReportFormData({
        achievedTarget: "",
        completed: "",
        description: "",
        file: "",
      });

      dispatch({
        type: types.ADD_ACTIVITY_SUBMISSION,
        data: { activity },
      });

      dispatch({
        type: types.SET_SELECTED_ACTIVITY,
        data: {
          ...activity,
          status: "Reported",
          submissions: [data.submission],
        },
      });

      dispatch({
        type: types.UPDATE_INDICATOR,
        data: data.indicator,
      });

      dispatch({
        type: types.UPDATE_OUTPUT,
        data: data.expectedResult,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadActivityReportFile = (submission) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/rbm/activity-reports-files/" + submission.submissionId,
        { responseType: "blob" }
      );

      // Create a temporary URL to download the file
      const url = window.URL.createObjectURL(new Blob([data]));

      // Trigger the file download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "1689587410192-scan.pdf");
      document.body.appendChild(link);
      link.click();

      // Clean up the temporary URL
      URL.revokeObjectURL(url);
      // download(new Blob([data]), "Submission report", submission.fileMimeType);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const getOversightEvaluations = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/rbm/oversight-evaluations");

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_OVERSIGHT_EVALUATIONS,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getUnitsEvaluations = (query, setTotalRecords, setLoader) => {
  return async (dispatch) => {
    setLoader(true);
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/rbm/unit-evaluations?" + new URLSearchParams(query).toString()
      );

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_UNITS_EVALUATIONS,
        data: data.records,
      });
      setLoader(false);
      setTotalRecords(data.totalRecords);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      setLoader(false);
    }
  };
};

export const getEmployeesEvaluations = (query, setTotalRecords, setLoader) => {
  return async (dispatch) => {
    try {
      setLoader(true);
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/rbm/employee-evaluations?" + new URLSearchParams(query).toString()
      );

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_EMPLOYEES_EVALUATIONS,
        data: data.records,
      });
      setLoader(false);
      setTotalRecords(data.totalRecords);
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadCSVEmployeeEvaluations = (setShowProgressBar) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowProgressBar(true);
      const { data } = await axios.get("/api/rbm/csv-employee-evaluations", {
        responseType: "blob",
      });

      download(new Blob([data]), `RBM_STAFF_EVALUATION.xlsx`, ".xlsx");

      dispatch({ type: types.END_LOADING });

      setShowProgressBar(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowProgressBar(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadAllInstitutionsPerformanceReport = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/rbm-reports/institutions", {
        responseType: "blob",
      });

      download(
        new Blob([data]),
        "PUBLIC_INSTITUTIONS_PERFORMANCE_REPORT.pdf",
        ".pdf"
      );

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const downloadInstitutionPerformanceReport = (
  entitySectorId,
  planningEntityId
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/rbm-reports/institution/${entitySectorId}/${planningEntityId}`,
        {
          responseType: "blob",
        }
      );

      download(
        new Blob([data]),
        `INSTITUTIONAL_PERFORMANCE_REPORT.pdf`,
        ".pdf"
      );

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const downloadInstitutionEmployeesScores = (
  entitySectorId,
  planningEntityId,
  query
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/rbm-reports/institution-employees-scores/${entitySectorId}/${planningEntityId}` +
          "?" +
          new URLSearchParams(query).toString(),
        {
          responseType: "blob",
        }
      );

      download(new Blob([data]), `EMPLOYEES_PERFORMANCE_REPORT.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const downloadUnitPerformanceReport = (unitId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/rbm-reports/unit/" + unitId, {
        responseType: "blob",
      });

      download(new Blob([data]), `UNIT_PERFORMANCE_REPORT.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }

      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const addActionPlanTemp = (actionPlan, setTotalRecods, cb) => {
  cb(true, false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const compressedData = pako.deflate(JSON.stringify({ actionPlan }), {
        to: "string",
      });

      const { data } = await axios.post(
        "/api/rbm/action-plan-temp",
        { compressJSON: compressedData }
        // {
        //   headers: {
        //     "Content-Encoding": "gzip",
        //     "Content-Type": "application/json",
        //   },
        // }
      );

      dispatch({ type: types.END_LOADING });

      cb(false, true);

      setTotalRecods(data.totalRecords);

      dispatch({ type: types.SET_OUTPUTS, data: data.records });

      // showSuccess(data.message);
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadActionPlanTemplate = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/rbm/action-plan-template/", {
        responseType: "blob",
      });

      download(new Blob([data]), `Action Plan Template.xlsx`, ".xlsx");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const getRBMDashboardData = (quarterId, query, cb) => {
  cb(true, null);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/rbm/dashboard/${quarterId}?` +
          new URLSearchParams(query).toString()
      );

      dispatch({ type: types.END_LOADING });

      cb(false, data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      cb(false, null);
    }
  };
};

export const getRBMDashboardSummary = (quarterId, cb) => {
  cb(true, null);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/rbm/dashboard-summary/${quarterId}`
      );

      dispatch({ type: types.END_LOADING });

      cb(false, data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      cb(false, null);
    }
  };
};

export const getMyContracts = (fiscalYearId, setLoadingContracts) => {
  setLoadingContracts(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_MY_CONTRACTS,
        data: [],
      });

      const { data } = await axios.get(`/api/rbm/my-contracts/${fiscalYearId}`);

      dispatch({
        type: types.SET_MY_CONTRACTS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      setLoadingContracts(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      setLoadingContracts(false);
    }
  };
};

export const getMyContractActivities = (contractId, cb) => {
  cb(null);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/rbm/my-contract-activities/${contractId}`
      );

      dispatch({
        type: types.SET_MY_CONTRACT_ACTIVITIES,
        data: { contractId, data },
      });

      dispatch({
        type: types.SET_SELECTED_ACTIVITY,
        data: {},
      });

      dispatch({ type: types.END_LOADING });

      cb(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      cb(null);
    }
  };
};

export const submitAssignmentReport = (
  reportData,
  setIsSubmitting,
  resetFormData
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);
      dispatch({ type: types.START_WAIT });

      const { data } = await axios.post("/api/rbm/submissions", reportData);

      dispatch({
        type: types.UPDATE_ACTIVITY,
        data: data.item,
      });

      dispatch({
        type: types.SET_SUBMISSIONS,
        data: data.submissions,
      });

      dispatch({
        type: types.UPDATE_CONTRACT,
        data: data.contract,
      });

      setIsSubmitting(false);

      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      resetFormData();

      showSuccess("Report submitted successfully");
    } catch (error) {
      setIsSubmitting(false);
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removeSubmission = (
  { submissionId, assignmentId, contractId, itemId, itemTypeId },
  setConfirmRemoveSubmission
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/rbm/delete-submission`, {
        submissionId,
        assignmentId,
        contractId,
        itemId,
        itemTypeId,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.DELETE_SUBMISSION,
        id: submissionId,
      });

      dispatch({
        type: types.UPDATE_ACTIVITY,
        data: data.item,
      });

      dispatch({
        type: types.UPDATE_CONTRACT,
        data: data.contract,
      });

      setConfirmRemoveSubmission(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removeActionPlan = (cb) => {
  cb(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete("/api/rbm/action-plan");

      dispatch({ type: types.END_LOADING });

      showSuccess(data);

      cb(true);

      dispatch({
        type: types.REMOVE_ACTION_PLAN,
      });
    } catch (error) {
      cb(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSubmissions = ({ assignmentId, itemTypeId }, setIsLoading) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SUBMISSIONS,
        data: [],
      });

      setIsLoading(true);

      const { data } = await axios.get(
        `/api/rbm/submissions/${assignmentId}/${itemTypeId}`
      );

      setIsLoading(false);

      dispatch({
        type: types.SET_SUBMISSIONS,
        data: data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      setIsLoading(false);
    }
  };
};

export const getUnitEvaluationSummary = (cb) => {
  cb(null);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/rbm/unit-evaluation-summary`);

      dispatch({ type: types.END_LOADING });
      cb(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      cb(null);
    }
  };
};

export const getEmployeeEvaluationSummary = (cb) => {
  cb(null);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/rbm/employee-evaluation-summary`);

      dispatch({ type: types.END_LOADING });
      cb(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      cb(null);
    }
  };
};

export const getEntityOutputs = (
  query,
  setEntityOutput,
  setTotalRecords,
  setLoader
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);

      const { data } = await axios.get(
        "/api/rbm/outputs?" + new URLSearchParams(query).toString()
      );

      setLoader(false);

      setEntityOutput(data.records);
      setTotalRecords(data.totalRecords);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntityIndicatorByOutput = (
  output,
  setIndicators,
  setSummary,
  setLoader
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);

      const { data } = await axios.get(
        `/api/rbm/output-indicators/${output.id}/${output.searchTerm}`
      );

      setLoader(false);

      setSummary(data.summary);
      setIndicators(data.records);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getActivityByIndicatorId = (
  indicatorId,
  setActivities,
  setSummary,
  setLoader
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);

      const { data } = await axios.get(
        `/api/rbm/indicator-activities/${indicatorId}`
      );

      setLoader(false);

      setSummary(data.summary);
      setActivities(data.records);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntityTopOutputs = (
  query,
  setEntityOutput,
  setTotalRecords,
  setLoader
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);

      const { data } = await axios.get(
        "/api/rbm/entity-outputs?" + new URLSearchParams(query).toString()
      );

      setLoader(false);

      setEntityOutput(data.records);
      setTotalRecords(data.totalRecords);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadEmployeeContractReport = (contractId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/rbm-reports/contract/" + contractId,
        {
          responseType: "blob",
        }
      );

      download(
        new Blob([data]),
        `EMPLOYEE_CONTRACT_PERFORMANCE_REPORT.pdf`,
        ".pdf"
      );

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const getCompetencies = (cb) => {
  cb && cb(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/rbm/competencies");

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_COMPETENCIES,
        data: data,
      });
      cb && cb(false);
    } catch (error) {
      cb && cb(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getContractCompetencyEvaluations = (contractId, cb) => {
  cb && cb(true);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/rbm/contract-competency-evaluations/" + contractId
      );

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_CONTRACT_COMPETENCY_EVALUATIONS,
        data: data,
      });
      cb && cb(false);
    } catch (error) {
      cb && cb(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const submitContractCompetency = (results, cb) => {
  cb(true, false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/rbm/submit-competency-evaluation",
        results
      );

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.ADD_CONTRACT_COMPETENCY_EVALUATION,
        data: data.evaluation,
      });

      cb(false, true);
    } catch (error) {
      cb(false, false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const publishContractCompetencies = (contractId, cb) => {
  cb(true, false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/rbm/publish-competency-evaluation/" + contractId
      );

      dispatch({ type: types.END_LOADING });

      showSuccess(data.message);

      dispatch({
        type: types.SET_SUBORDINATE_CONTRACT_COMPETENCY_SCORES,
        data: data.contract,
      });

      cb(false, true);
    } catch (error) {
      cb(false, false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removeContractPendingAssignment = (contractId, cb) => {
  cb(true, false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/rbm/remove-pending-assignments/" + contractId
      );

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.REMOVE_SUBORDINATE_CONTRACT_PENDING_ASSIGNMENTS,
        data: data.contract,
      });

      showSuccess(data.message);

      cb(false, true);
    } catch (error) {
      cb(false, false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadEmployeePerformanceReport = (employeeId, fiscalYearId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/rbm-reports/employee/${employeeId}/${fiscalYearId}`,
        {
          responseType: "blob",
        }
      );

      download(new Blob([data]), `EMPLOYEE_PERFORMANCE_REPORT.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const downloadContractPerformanceReport = (contractId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/rbm-reports/contract/" + contractId,
        {
          responseType: "blob",
        }
      );

      download(new Blob([data]), `RBM_CONTRACT_PERFORMANCE_REPORT.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const getSupervisorSubordinates = (supervisorId, cb) => {
  return async (dispatch) => {
    try {
      cb(true, []);
      const { data } = await axios.get("/api/rbm/subordinates/" + supervisorId);

      cb(false, data);
    } catch (error) {
      cb(false, []);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSubordinatePendingReports = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/rbm/subordinate-pending-reports");
      dispatch({
        type: types.SET_SUBORDINATE_PENDING_REPORTS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const requestCompetencyReEvaluation = (contractId, cb) => {
  cb(true, false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/rbm/publish-competency-re-evaluation/" + contractId
      );

      dispatch({ type: types.END_LOADING });

      showSuccess(data.message);

      dispatch({
        type: types.SET_SUBORDINATE_CONTRACT_COMPETENCY_RE_EVALUATION,
        data: { contractId },
      });

      cb(false, true);
    } catch (error) {
      cb(false, false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const requestContractCancel = (contractId, cb) => {
  cb(true, false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/rbm/cancel-contract/" + contractId
      );

      dispatch({ type: types.END_LOADING });

      showSuccess(data.message);

      dispatch({
        type: types.SET_CONTRACT_CANCEL,
        data: { contractId },
      });

      cb(false, true);
    } catch (error) {
      cb(false, false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPerformanceEmployeeEmployments = (employeeId, cd) => {
  return async (dispatch) => {
    cd(true, []);
    try {
      const { data } = await axios.get(
        `/api/rbm/performance-employee-employment/${employeeId}`
      );
      cd(false, data);
    } catch (error) {
      cd(false, []);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const changeBonusEmployment = (bonusId, formData, cd) => {
  return async (dispatch) => {
    cd(true, false);
    try {
      const { data } = await axios.put(
        `/api/rbm/change-bonus-employment/${bonusId}`,
        formData
      );
      showSuccess(data.message);
      cd(false, true);
    } catch (error) {
      cd(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const selectPlanningEntity = (planningEntityId, cb) => {
  return async (dispatch) => {
    cb(true);
    try {
      await axios.post("/api/rbm/select-planning-entity", {
        id: planningEntityId,
      });

      dispatch({
        type: types.SET_SELECTED_PLANNING_ENTITY,
        data: { planningEntityId },
      });
      cb(false);
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      cb(false);
      showError(error);
    }
  };
};

export const getSelectedPlanningEntity = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/rbm/selected-planning-entity");

      dispatch({
        type: types.SET_SELECTED_PLANNING_ENTITY,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const downloadSpecialOutputReportingTemplate = (cb) => {
  return async (dispatch) => {
    cb(true);
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/rbm/special-output-reporting-sheet/",
        {
          responseType: "blob",
        }
      );

      download(
        new Blob([data]),
        `Special Assignment Reporting Template.xlsx`,
        ".xlsx"
      );

      cb(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

