import structure from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";
import * as arrayToTree from "array-to-tree";
import commonTypes from "../common/action-types";
import download from "downloadjs";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...structure };

export function arrangingPostions(data) {
  let tempRes = [...data];

  let temPositions1 = [];
  let temPositions2 = [];
  let temPositions3 = [];

  tempRes.forEach((e) => {
    if (e.isHeadOfInstitution || e.isHeadOfUnit) temPositions1.push({ ...e });
    else if (e.isShared) temPositions3.push({ ...e });
    else temPositions2.push({ ...e });
  });

  return [...temPositions1, ...temPositions2, ...temPositions3];
}

function arrangingWithholds(data) {
  let tempRes = [...data];

  let temWithholds1 = [];
  let temWithholds2 = [];
  let temWithholds3 = [];
  tempRes.forEach((e) => {
    if (e.isRequest && !e.isRequestRejected) temWithholds1.push({ ...e });
    if (e.isRequest && e.isRequestRejected) temWithholds2.push({ ...e });
    if (!e.isRequest) temWithholds3.push({ ...e });
  });

  return [...temWithholds1, ...temWithholds2, ...temWithholds3];
}

export const getUnits = (unitId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/structure/units/" + unitId);

      dispatch({
        type: types.SET_UNITS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//unit-types
export const getUnitTypes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/unit-types");

      dispatch({
        type: types.SET_UNIT_TYPES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getTreeUnits = (
  unitId,
  setExpandedNodes,
  entitySectorId = null
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      let data;

      if (!!entitySectorId) {
        const { data: data1 } = await axios.get(
          "/api/structure/entity-sector-units/" + entitySectorId
        );
        data = data1;
      } else {
        const { data: data2 } = await axios.get(
          "/api/structure/units/" + unitId
        );
        data = data2;
      }

      const treeData = arrayToTree(data, {
        parentProperty: "parentUnitId",
        childrenProperty: "children",
        customID: "unitId",
      });

      dispatch({
        type: types.SET_UNITS,
        data: data,
      });

      dispatch({
        type: types.SET_TREE_UNITS,
        data: treeData[0] || {},
      });

      const ids = [];
      data.forEach(({ unitId }) => ids.push(unitId));
      setExpandedNodes && setExpandedNodes([...ids]);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getOrganigramData = (unitId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/organigram-data/" + unitId
      );

      dispatch({
        type: types.SET_ORGANIGRAM_DATA,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveOrUpdateUnit = (
  unit,
  setFormData,
  setIsEditing,
  setShowUnitForm,
  setExpandedNodes,
  units,
  isEditing
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.START_LOADING });

      const formData = {
        unitName: unit.unitName,
        parentUnitId: unit.parentUnitId,
        unitTypeId: unit.unitTypeId,
        isOnStructure: unit.isOnStructure,
      };

      const { data } = !isEditing
        ? await axios.post("/api/structure/units", formData)
        : await axios.put("/api/structure/units/" + unit.unitId, formData);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess(!isEditing ? "Saved successfully" : "Updated successfully");

      setFormData({
        unitId: null,
        unitName: "",
        parentUnitId: "",
        unitTypeId: 0,
        isOnStructure: false,
      });
      setShowUnitForm(false);
      setIsEditing(false);

      const tmpUnits = [...units];
      if (isEditing) {
        const index = tmpUnits.findIndex(
          ({ unitId }) => unitId === data.unitId
        );
        tmpUnits[index] = data;
      } else tmpUnits.push(data);

      //units

      const treeData = arrayToTree(tmpUnits, {
        parentProperty: "parentUnitId",
        childrenProperty: "children",
        customID: "unitId",
      });

      dispatch({
        type: types.SET_UNITS,
        data: tmpUnits,
      });

      dispatch({
        type: types.SET_TREE_UNITS,
        data: treeData[0] || {},
      });

      const ids = [];
      tmpUnits.forEach(({ unitId }) => ids.push(unitId));
      setExpandedNodes([...ids]);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//saveOrUpdateQualification
export const saveJobClassificationQualification = (
  formData,
  setFormData,
  setShowQualificationForm,
  setSelectedQualification,
  setDegree,
  setQualification,
  setCurrentQualifications,
  setExperience
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/job-classification-qualifications",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      setShowQualificationForm(false);
      setSelectedQualification(null);
      setDegree(null);
      setQualification(null);
      setCurrentQualifications([]);
      setExperience(null);

      dispatch({
        type: types.ADD_JOB_CLASSIFICATION_QUALIFICATION,
        data: data,
      });

      setFormData({
        qualificationId: null,
        jobClassificationId: formData.jobClassificationId,
        yearsOfExperience: 0,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const savePositionQualification = (
  formData,
  setFormData,
  setShowQualificationForm,
  setSelectedQualification,
  setDegree,
  setQualification,
  setExperience
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/position-qualifications",
        formData
      );

      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      setShowQualificationForm(false);
      setSelectedQualification(null);
      setDegree(null);
      setQualification(null);
      setExperience(null);

      dispatch({
        type: types.ADD_POSITION_QUALIFICATION,
        data: data,
      });

      setFormData({
        qualificationId: null,
        positionId: formData.positionId,
        yearsOfExperience: 0,
      });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const savePositionCertificate = (formData, certificate, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/position-certificates",
        formData
      );

      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");
      certificate.positionCertificateId = data;
      dispatch({
        type: types.ADD_POSITION_CERTIFICATE,
        data: certificate,
      });

      // dispatch({
      //   type: types.REMOVE_NON_ADDED_POSITION_CERTIFICATE,
      //   data: certificate,
      // });

      onClose();
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//deletePositionCertificate

export const deletePositionCertificate = (
  selectedCertificate,
  setConfirmRemoveCertificate,
  setSelectedCertificate
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/position-certificates",
        {
          positionId: selectedCertificate.positionId,
          certificateId: selectedCertificate.id,
          added: false,
        }
      );

      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Removed successfully");
      dispatch({
        type: types.REMOVE_POSITION_CERTIFICATE,
        data: selectedCertificate,
      });

      // dispatch({
      //   type: types.ADD_NON_ADDED_POSITION_CERTIFICATE,
      //   data: selectedCertificate,
      // });

      setConfirmRemoveCertificate(false);
      setSelectedCertificate(null);
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//deletePositionCompetency
export const deletePositionCompetency = (
  selectedCompetency,
  setConfirmRemoveCompetency,
  setSelectedCompetency
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/position-competencies",
        {
          positionId: selectedCompetency.positionId,
          competencyId: selectedCompetency.id,
          added: false,
        }
      );

      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Removed successfully");
      dispatch({
        type: types.REMOVE_POSITION_COMPETENCY,
        data: selectedCompetency,
      });

      // dispatch({
      //   type: types.ADD_NON_ADDED_POSITION_CERTIFICATE,
      //   data: selectedCertificate,
      // });

      setConfirmRemoveCompetency(false);
      setSelectedCompetency(null);
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const deletePositionQualification = (
  selectedQualification,
  setConfirmRemoveQualification,
  setSelectedQualification
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/position-qualifications/" + selectedQualification.id
      );

      // showSuccess(data.message);
      showSuccess("Removed successfully");
      dispatch({
        type: types.DELETE_POSITION_QUALIFICATION,
        data: selectedQualification,
      });
      // dispatch({
      //   type: types.ADD_NON_ADDED_POSITION_CERTIFICATE,
      //   data: selectedQualification,
      // });

      setConfirmRemoveQualification(false);
      setSelectedQualification(null);
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveJcCompetency = (
  formData
  // setFormData,
  // setShowCompetencyForm,
  // setCompetency
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/job-classification-competencies",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      // setShowCompetencyForm(false);
      // setCompetency(null);

      dispatch({
        type: types.UPDATE_JOB_CLASSIFICATION_COMPETENCY,
        data: data,
      });

      // setFormData({
      //   competencyId: null,
      //   jobClassificationId: formData.jobClassificationId,
      // });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//saveJcCertificate

export const saveJcCertificate = (
  formData
  // setFormData,
  // setShowCertificateForm,
  // setCertificateField,
  // setCertificate,
  // setCurrentCertificates
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/job-classification-certificates",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      // setShowCertificateForm(false);
      // setCertificate(null);
      // setCertificateField(null);
      // setCurrentCertificates([]);

      dispatch({
        type: types.UPDATE_JOB_CLASSIFICATION_CERTIFICATE,
        data: data,
      });

      // setFormData({
      //   certificateId: null,
      //   jobClassificationId: formData.jobClassificationId,
      // });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveProfessionalCertificate = (
  formData,
  setFormData,
  setShowProfessionalCertificateForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/professional-certificate",
        formData
      );

      dispatch({ type: types.END_LOADING });

      showSuccess("Saved successfully");

      setShowProfessionalCertificateForm(false);

      dispatch({
        type: types.ADD_PROFESSIONAL_CERTIFICATE,
        data: { ...data, ...formData },
      });

      setFormData({
        certificateTypeId: null,
        experience: null,
        professionalCertificateTypeId: null,
        positionId: null,
        professionalCertificateType: null,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteJcQualification = (
  selectedQualification,
  setConfirmRemoveQualification,
  setSelectedQualification
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/job-classification-qualifications/" +
          selectedQualification.id
      );

      showSuccess(data.message);

      dispatch({
        type: types.DELETE_JOB_CLASSIFICATION_QUALIFICATION,
        data: selectedQualification,
      });

      setConfirmRemoveQualification(false);
      setSelectedQualification(null);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteJcCompetency = (
  selectedCompetency,
  setConfirmRemoveCompetency,
  setSelectedCompetency
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/job-classification-competencies/" +
          selectedCompetency.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.DELETE_JOB_CLASSIFICATION_COMPETENCY,
        data: selectedCompetency,
      });

      setConfirmRemoveCompetency(false);
      setSelectedCompetency(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//deleteJcCertificate
export const deleteJcCertificate = (
  selectedCertificate,
  setConfirmRemoveCertificate,
  setSelectedCertificate
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/job-classification-certificates/" +
          selectedCertificate.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.DELETE_JOB_CLASSIFICATION_CERTIFICATE,
        data: selectedCertificate,
      });

      setConfirmRemoveCertificate(false);
      setSelectedCertificate(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteUnit = (
  unitToBeRemoved,
  setConfirmRemoveUnit,
  setUnitToBeRemoved,
  setExpandedNodes,
  units
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/units/" + unitToBeRemoved.unitId
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      setConfirmRemoveUnit(false);

      const tmpUnits = [...units];
      const index = tmpUnits.findIndex(
        ({ unitId }) => unitId === unitToBeRemoved.unitId
      );
      tmpUnits.splice(index, 1);

      const treeData = arrayToTree(tmpUnits, {
        parentProperty: "parentUnitId",
        childrenProperty: "children",
        customID: "unitId",
      });

      dispatch({
        type: types.SET_UNITS,
        data: tmpUnits,
      });

      dispatch({
        type: types.SET_TREE_UNITS,
        data: treeData[0] || {},
      });

      const ids = [];
      tmpUnits.forEach(({ unitId }) => ids.push(unitId));
      setExpandedNodes([...ids]);
      setUnitToBeRemoved("");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const setSelectedUnit = (unit) => {
  return (dispatch) => {
    dispatch({
      type: types.SELECTED_UNIT,
      data: unit,
    });
  };
};

export const setSearchUnit = (searchTeam, units, setExpandedNodes) => {
  return (dispatch) => {
    dispatch({
      type: types.SEARCH_UNIT,
      data: "",
    });

    let data = [...units].filter(({ unitName }) =>
      unitName.toLowerCase().includes(searchTeam.toLowerCase())
    );
    const treeData = arrayToTree(data, {
      parentProperty: "parentUnitId",
      childrenProperty: "children",
      customID: "unitId",
    });

    dispatch({
      type: types.SET_TREE_UNITS,
      data: treeData[0] || {},
    });

    const ids = [];
    data.forEach(({ unitId }) => ids.push(unitId));
    setExpandedNodes([...ids]);
  };
};

//setSearchPositions
export const setSearchPositions = (
  searchTeam,
  positions,
  searchCurrentPositions
) => {
  return (dispatch) => {
    dispatch({
      type: types.SEARCH_POSITION,
      data: "",
    });

    dispatch({
      type: types.SEARCH_CURRENT_POSITIONS,
      data: !!searchCurrentPositions.length
        ? searchCurrentPositions
        : positions,
    });

    let data = [];

    if (!searchTeam) {
      data = searchCurrentPositions;
    } else {
      data = [...positions].filter(({ name }) =>
        name.toLowerCase().includes(searchTeam.toLowerCase())
      );
    }

    dispatch({
      type: types.SET_POSITIONS,
      data,
    });
  };
};

export const getUnitPositionsByPagination = (
  unitId,
  query,
  setTotalPositions,
  setPositionLoader
) => {
  return async (dispatch) => {
    try {
      setPositionLoader(true);
      const { data } = await axios.get(
        "/api/structure/paginated-positions/" +
          unitId +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_POSITIONS,
        data: data.records,
      });

      setTotalPositions(data.totalRecords);
      setPositionLoader(false);
    } catch (error) {
      setPositionLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getUnitPositions = (unitId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_POSITIONS,
        data: [],
      });
      const { data } = await axios.get("/api/structure/positions/" + unitId);

      dispatch({
        type: types.SET_POSITIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntitySectorPositions = (
  entitySectorId,
  isEntitySectorPositions = false
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      if (isEntitySectorPositions) {
        dispatch({
          type: types.SET_ENTITY_SECTOR_POSITIONS,
          data: [],
        });
      } else {
        dispatch({
          type: types.SET_POSITIONS,
          data: [],
        });
      }

      const { data } = await axios.get(
        "/api/structure/entity-sector-positions/" + entitySectorId
      );
      if (isEntitySectorPositions) {
        dispatch({
          type: types.SET_ENTITY_SECTOR_POSITIONS,
          data,
        });
      } else {
        dispatch({
          type: types.SET_POSITIONS,
          data,
        });
      }

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//getPosition
export const getPosition = (
  positionId,
  handleCloseMenu,
  setShowUpdatePositionModal
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      // dispatch({
      //   type: types.SET_POSITION,
      //   data: null,
      // });

      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/show/position/" + positionId
      );

      dispatch({
        type: types.SET_POSITION,
        data,
      });

      dispatch({ type: types.END_LOADING });

      handleCloseMenu();

      setShowUpdatePositionModal(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//savePosition
export const savePosition = (formData, setShowPositionForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/structure/positions", formData);

      dispatch({
        type: types.ADD_POSITION,
        data: data,
      });

      showSuccess("Saved successfully");

      setShowPositionForm(false);

      dispatch({
        type: types.SET_SALARY_STRUCTURE_AS_ADDED,
        id: formData.salaryStructureId,

        data: { id: formData.salaryStructureId, added: true },
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveSharedPosition = (formData, setShowPositionForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/structure/shared-positions",
        formData
      );

      dispatch({
        type: types.ADD_POSITION,
        data: data,
      });
      showSuccess("Saved successfully");

      setShowPositionForm(false);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updatePosition1 = (position, formData, setEdited) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      if (position.isShared)
        await axios.put(
          "/api/structure/shared-positions/" + position.sharedPositionId,
          formData
        );
      else await axios.put("/api/structure/positions/" + position.id, formData);

      dispatch({
        type: types.UPDATE_POSITION,
        data: {
          ...position,
          rraGradeId: formData.rraGradeId,
          rraDepartmentId: formData.rraDepartmentId,
          reportsTo: formData.reportsTo,
          description: formData.description,
        },
      });

      setEdited(false);

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updatePosition2 = (positionId, formData, setShowUpdateDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/positions/" + positionId,
        formData
      );

      dispatch({
        type: types.UPDATE_POSITION,
        data,
      });

      setShowUpdateDialog(false);

      dispatch({ type: types.END_LOADING });
      showSuccess("Updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//deletePosition

export const editPositionRoles = (payroad, setShowPositionRolesModel) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/position-roles",
        payroad
      );

      // data.forEach((position) =>
      const position = data[0];

      // console.log(position);
      dispatch({
        type: types.UPDATE_POSITION,
        data: position,
      });
      //);
      // setShowPositionRolesModel(false);

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      if (payroad.role === "isHeadOfUnit") {
        const { data: supervisors } = await axios.get(
          "/api/structure/supervisors"
        );

        dispatch({
          type: types.SET_SUPERVISORS,
          data: supervisors,
        });
      }
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updatePositionSalarySettings = (payroad, setEdited) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/position-salary-settings",
        payroad
      );

      dispatch({
        type: types.UPDATE_POSITION,
        data: data.position,
      });

      dispatch({
        type: "SET_SOURCE_FOUNDS",
        data: data.sourceOfFunds,
      });

      setEdited(false);

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateSharedPosition = (
  formData,
  setShowUpdateSharedPositionModal,
  setSourceOfFund,
  setPayrollGroup,
  setCostCenter
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        "/api/structure/shared-positions/" + formData.sharedId,
        formData
      );

      dispatch({
        type: types.UPDATE_POSITION,
        data: data,
      });
      setSourceOfFund(null);
      setPayrollGroup(null);
      setCostCenter(null);
      setShowUpdateSharedPositionModal(false);
      dispatch({ type: types.END_LOADING });
      showSuccess("Updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deletePosition = (
  positionToBeRemoved,
  setConfirmRemovePosition,
  setPositionToBeRemoved
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/positions/" + positionToBeRemoved.id
      );

      console.log(positionToBeRemoved);

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      setConfirmRemovePosition(false);

      dispatch({
        type: types.DELETE_POSITION,
        data: positionToBeRemoved,
      });

      if (!!positionToBeRemoved.salaryStructureId)
        dispatch({
          type: types.SET_SALARY_STRUCTURE_AS_ADDED,
          data: { id: positionToBeRemoved.salaryStructureId, added: false },
        });

      setPositionToBeRemoved("");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//deleteProfessionalCertificate

export const deleteSharedPosition = (
  positionToBeRemoved,
  setConfirmRemovePosition,
  setPositionToBeRemoved
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/shared-positions/" + positionToBeRemoved.sharedId
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      setConfirmRemovePosition(false);

      dispatch({
        type: types.DELETE_POSITION,
        data: positionToBeRemoved,
      });

      setPositionToBeRemoved("");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const deleteProfessionalCertificate = (
  selectedCertificate,
  setConfirmRemoveCertificate,
  setSelectedCertificate
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const formData = {
        experience: selectedCertificate.experience,
        professionalCertificateId:
          selectedCertificate.professionalCertificateId,
        positionId: selectedCertificate.positionId,
      };

      const { data } = await axios.post(
        "/api/structure/delete-professional-certificate",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      setSelectedCertificate(null);
      setConfirmRemoveCertificate(false);

      dispatch({
        type: types.DELETE_PROFESSIONAL_CERTIFICATE,
        data: selectedCertificate,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//deletePositionKnowledge

export const deletePositionKnowledge = (
  selectedPositionKnowledge,
  setConfirmRemovePositionKnowledge,
  setSelectedPositionKnowledge
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const formData = {
        knowledgeId: selectedPositionKnowledge.knowledgeId,
        positionId: selectedPositionKnowledge.positionId,
      };

      const { data } = await axios.post(
        "/api/structure/delete-position-knowledge",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.DELETE_POSITION_KNOWLEDGE,
        data: selectedPositionKnowledge,
      });

      setSelectedPositionKnowledge(null);
      setConfirmRemovePositionKnowledge(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const savePositionKnowledge = (
  formData,
  setFormData,
  setShowPositionKnowledgeForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const payload = {
        knowledgeId: formData.knowledgeId,
        positionId: formData.positionId,
      };

      const { data } = await axios.post(
        "/api/structure/position-knowledge",
        payload
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.ADD_POSITION_KNOWLEDGE,
        data: { ...data, ...formData },
      });

      setFormData({
        knowledgeId: null,
        positionId: null,
        knowledgeName: null,
      });

      setShowPositionKnowledgeForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const setAsPlanner = (position) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/set-as-planner/" + position.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.SET_POSITION_PLANNER,
        data: position,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const stopActing = (formData, setConfirmStopActing) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/structure/employee/stop-acting", formData);

      setConfirmStopActing(false);

      dispatch({ type: types.END_LOADING });
      showSuccess("Updated successfully!");

      dispatch({
        type: types.DELETE_EMPLOYEE,
        employeePositionId: formData.employeePositionId,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//set-as-head-unity
export const setAsHeadOfUnity = (position) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/set-as-head-unity/" + position.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);

      dispatch({
        type: types.SET_POSITION_HEAD_UNITY,
        data: position,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get SalaryStructure
 * GET /api/structure/salary-structures?mainEntityId
 */

export const getSalaryStructure = (
  entitySectorId,
  cb1 = null,
  cb2 = null,
  isOverSight = false
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SALARY_STRUCTURES,
        data: [],
      });

      dispatch({ type: types.START_LOADING });

      let data;

      if (isOverSight) {
        const { data: data1 } = await axios.get(
          "/api/structure/salary-structures-oversight/" + entitySectorId
        );

        data = data1;
      } else {
        const { data: data2 } = await axios.get(
          "/api/structure/salary-structures/" + entitySectorId
        );
        data = data2;
      }

      dispatch({
        type: types.SET_SALARY_STRUCTURES,
        data,
      });

      if (!!cb1) cb1();
      if (!!cb2) cb2();

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get EmployeeGroup
 * GET /api/structure/employee-groups
 */

export const getEmployeeGroups = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/employee-groups");

      dispatch({
        type: types.SET_EMPLOYEE_GROUPS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get AllowanceTypes
 * GET /api/structure/allowance-types
 */

export const getAllowanceTypes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/allowance-types");

      dispatch({
        type: types.SET_ALLOWANCE_TYPES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get HiringMode
 * GET /api/structure/hiring-modes
 */

export const getHiringModes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/hiring-modes");

      dispatch({
        type: types.SET_HIRING_MODES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @ Get PositionEmployees
 * GET /api/structure/position-employees
 */

export const getPositionEmployees = (positionId, setEmployeeLoader) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_POSITION_EMPLOYEES,
        data: [],
      });
      setEmployeeLoader(true);
      const { data } = await axios.get(
        "/api/structure/position-employees/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_EMPLOYEES,
        data,
      });
      setEmployeeLoader(false);
    } catch (error) {
      setEmployeeLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @ Get levels
 * GET /api/structure/levels
 */

export const getLevels = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/levels");

      dispatch({
        type: types.SET_LEVELS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const editJobLevel = (payload, onCloseDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/structure/levels", payload);

      dispatch({
        type: types.UPDATE_LEVEL,
        data: data,
      });

      dispatch({ type: types.END_LOADING });

      onCloseDialog();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @ Get Echelons
 * GET /api/structure/echelons
 */

export const getEchelons = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/scales");

      dispatch({
        type: types.SET_ECHELONS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @ Get Banks
 * GET /api/lookup/banks
 */

export const getBanks = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/banks");

      dispatch({
        type: types.SET_BANKS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getMissingEmployeeDataSummary = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/structure/missing-employee-data-summary"
      );

      dispatch({
        type: types.SET_MISSING_EMPLOYEE_DATA_SUMMARY,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getMissingEmployeeData = (column, setLoader) => {
  return async (dispatch) => {
    try {
      setLoader(true);
      dispatch({
        type: types.SET_MISSING_EMPLOYEE_DATA,
        data: [],
      });
      const { data } = await axios.get(
        "/api/structure/missing-employee-data/" + column
      );

      dispatch({
        type: types.SET_MISSING_EMPLOYEE_DATA,
        data,
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @ Get Banks
 * GET /api/lookup/banks
 */

export const getMedicalInsuranceTypes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/medical-insurance-types");

      dispatch({
        type: types.SET_MEDICAL_INSURANCE_TYPES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get supervisors
 * GET /api/structure/supervisors
 */

export const getSupervisors = (entitySectorId = "") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SUPERVISORS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/structure/supervisors" +
          `${!!entitySectorId ? "/" + entitySectorId : ""}`
      );

      dispatch({
        type: types.SET_SUPERVISORS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get getPostionQualifications
 * GET /api/structure/position-qualifications
 */
export const getJobClassificationQualifications = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/job-classification-qualifications/" +
          jobClassificationId
      );

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_QUALIFICATIONS,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getJobClassificationCompetencies = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_COMPETENCIES,
        data: [],
      });

      const { data } = await axios.get(
        "/api/structure/job-classification-competencies/" + jobClassificationId
      );

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_COMPETENCIES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//getJobClassificationCertificates

export const getJobClassificationCertificates = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_CERTIFICATES,
        data: [],
      });

      const { data } = await axios.get(
        "/api/structure/job-classification-certificates/" + jobClassificationId
      );

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_CERTIFICATES,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get getProfessionalCertificates
 * GET /api/structure/position-qualifications
 */
export const getProfessionalCertificates = (positionId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/structure/professional-certificates/" + positionId
      );

      dispatch({
        type: types.SET_PROFESSIONAL_CERTIFICATES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get getPositionKnowledges
 * GET /api/structure/position-knowledges
 */
export const getPositionKnowledges = (positionId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/structure/position-knowledges/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_KNOWLEDGES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
/*
 * @Get getDegrees
 * GET /api/structure/degrees
 */
export const getDegrees = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/degrees");

      dispatch({
        type: types.SET_PROFILE_DEREES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get DegreeQualifications
 * GET /api/structure/degree-qualifications
 */
export const getDegreeQualifications = (degreeId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_QUALIFICATIONS,
        data: [],
      });

      dispatch({ type: types.START_WAIT });
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/degree-qualifications/" + degreeId
      );

      dispatch({
        type: types.SET_QUALIFICATIONS,
        data,
      });

      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCompetencies = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_COMPETENCIES,
        data: [],
      });

      const { data } = await axios.get("/api/structure/competencies");

      dispatch({
        type: types.SET_COMPETENCIES,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCertificateFields = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_CERTIFICATE_FIELDS,
        data: [],
      });

      const { data } = await axios.get("/api/structure/certificate-fields");

      dispatch({
        type: types.SET_CERTIFICATE_FIELDS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCertificates = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_CERTIFICATES,
        data: [],
      });

      const { data } = await axios.get("/api/structure/certificates");

      dispatch({
        type: types.SET_CERTIFICATES,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeePromotions = (
  employeePositionId,
  setShowEmployeePromotionsDialog,
  handleCloseMenu
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/employee-promotions/" + employeePositionId
      );

      dispatch({
        type: types.SET_EMPLOYEE_PROMOTIONS,
        data,
      });

      setShowEmployeePromotionsDialog(true);
      handleCloseMenu(true);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployeePromotion = (payroad, setShowPromitionDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee-promotions",
        payroad
      );

      dispatch({
        type: types.SET_EMPLOYEE_PROMOTIONS,
        data: data.promotions,
      });

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data: data.employee,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      setShowPromitionDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateEmployeeEmploymentDate = (
  payroad,
  setShowEmploymentDateDialog,
  callback
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      // dispatch({ type: types.START_LOADING });
      callback(null);
      const { data } = await axios.post(
        "/api/structure/employee-employment-date",
        payroad
      );

      // dispatch({
      //   type: types.UPDATE_EMPLOYEE,
      //   data: data,
      // });
      showSuccess("Updated successfully");
      callback(data);
      // dispatch({
      //   type: types.SET_USER_PROFILE,
      //   data: {
      //     ...data,
      //     employments: [
      //       {
      //         positionName: data.positionName,
      //         employeeGroupId: data.employeeGroupId,
      //         levelName: data.levelName,
      //         scaleName: data.scaleName,
      //         entityName: data.entityName,
      //         unitName: data.unitName,
      //         isActing: data.isActing,
      //         startDate: data.startDate,
      //         id: data.employeePositionId,
      //       },
      //     ],
      //   },
      // });
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      setShowEmploymentDateDialog(false);
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      callback(null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get knowledge
 * GET /api/lookup/knowledge
 */
export const getknowledge = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_KNOWLEDGE,
        data: [],
      });

      const { data } = await axios.get("/api/lookup/knowledge");

      dispatch({
        type: types.SET_KNOWLEDGE,
        data,
      });
    } catch (error) {
      dispatch({
        type: types.SET_KNOWLEDGE,
        data: [],
      });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get certificateTypes
 * GET /api/lookup/certificate-types
 */
export const getCertificateTypes = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_CERTIFICATE_TYPES,
        data: [],
      });

      const { data } = await axios.get("/api/lookup/certificate-types");

      dispatch({
        type: types.SET_CERTIFICATE_TYPES,
        data,
      });
    } catch (error) {
      dispatch({
        type: types.SET_CERTIFICATE_TYPES,
        data: [],
      });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get professionalCertificateTypes
 * GET /api/lookup/professional-certificate-types/:certificateTypeId
 */
export const getProfessionalCertificateTypes = (certificateTypeId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_PROFESSIONAL_CERTIFICATE_TYPES,
        data: [],
      });

      const { data } = await axios.get(
        "/api/lookup/professional-certificate-types/" + certificateTypeId
      );

      dispatch({
        type: types.SET_PROFESSIONAL_CERTIFICATE_TYPES,
        data,
      });
    } catch (error) {
      dispatch({
        type: types.SET_PROFESSIONAL_CERTIFICATE_TYPES,
        data: [],
      });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/********************************************* EMPLOYEEEEE***************************************** */

//setSearchEmployees

export const setSearchEmployees = (
  searchTeam,
  employees,
  searchCurrentEmployees
) => {
  return (dispatch) => {
    dispatch({
      type: types.SEARCH_EMPLOYEES,
      data: "",
    });

    dispatch({
      type: types.SEARCH_CURRENT_EMPLOYEES,
      data: !!searchCurrentEmployees.length
        ? searchCurrentEmployees
        : employees,
    });

    let data = [];

    if (!searchTeam) {
      data = searchCurrentEmployees;
    } else {
      data = [...employees].filter(
        ({ firstName, lastName, positionName }) =>
          firstName.toLowerCase().includes(searchTeam.toLowerCase()) ||
          lastName.toLowerCase().includes(searchTeam.toLowerCase()) ||
          positionName.toLowerCase().includes(searchTeam.toLowerCase())
      );
    }

    dispatch({
      type: types.SET_EMPLOYEES,
      data: data,
    });
  };
};

export const getUnitEmployeesByPagination = (
  unitId,
  query,
  setTotalEmployees,
  setEmployeeLoader
) => {
  return async (dispatch) => {
    try {
      setEmployeeLoader(true);
      const { data } = await axios.get(
        "/api/structure/paginated-employees/" +
          unitId +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_EMPLOYEES,
        data: data.records,
      });

      setTotalEmployees(data.totalRecords);
      setEmployeeLoader(false);
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      setEmployeeLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getUnitEmployees = (unitId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_EMPLOYEES,
        data: [],
      });
      const { data } = await axios.get("/api/structure/employees/" + unitId);

      dispatch({
        type: types.SET_EMPLOYEES,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const findEmployees = (payroad) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_SEARCHED_EMPLOYEES,
        data: [],
      });

      const { data } = await axios.post(
        "/api/structure/employees-by-search-terms",
        payroad
      );

      dispatch({
        type: types.SET_SEARCHED_EMPLOYEES,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//user-by-search-terms

export const findUserDetails = (payroad, setIsSearching) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });
      setIsSearching(true);
      dispatch({
        type: types.SET_SEARCHED_USER_DETAILS,
        data: [],
      });

      const { data } = await axios.post(
        "/api/structure/user-details-by-search-terms",
        payroad
      );

      dispatch({
        type: types.SET_SEARCHED_USER_DETAILS,
        data: data,
      });
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const findEmployeeDetails = (payroad, setIsSearching) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });
      setIsSearching(true);
      dispatch({
        type: types.SET_SEARCHED_USER_DETAILS,
        data: [],
      });

      const { data } = await axios.post(
        "/api/structure/find-employee-from-entity-sector",
        payroad
      );

      dispatch({
        type: types.SET_SEARCHED_USER_DETAILS,
        data: data,
      });
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntitySectorEmployees = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      // dispatch({
      //   type: types.SET_EMPLOYEES,
      //   data: [],
      // });

      // dispatch({
      //   type: types.SEARCH_CURRENT_EMPLOYEES,
      //   data: [],
      // });

      // dispatch({
      //   type: types.SEARCH_EMPLOYEES,
      //   data: "",
      // });

      const { data } = await axios.get("/api/structure/employees");

      dispatch({
        type: types.SET_EMPLOYEES,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getNationalIdDetail = (idNumber, employeeId = 0) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_NATIONAL_ID_DETAIL,
        data: null,
      });

      const { data } = await axios.get(
        "/api/structure/check-employee-nid-number-detail/" +
          idNumber +
          "/" +
          employeeId
      );

      dispatch({
        type: types.SET_NATIONAL_ID_DETAIL,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployee = (employeeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_EMPLOYEE,
        data: null,
      });

      const { data } = await axios.get(
        "/api/structure/single-employee/" + employeeId
      );

      dispatch({
        type: types.SET_EMPLOYEE,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployee = (
  formData,
  setShowEmployeeForm,
  isPullRequest = false
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/structure/employees", formData);

      if (isPullRequest) {
        dispatch({
          type: types.REMOVE_EMPLOYEE_PULL_REQUEST,
          id: data.employee.id,
        });
        dispatch({
          type: types.ADD_EMPLOYEE,
          data: data.employee,
        });
      } else
        dispatch({
          type: types.UPDATE_POSITION,
          data: data.position,
        });

      setShowEmployeeForm(false);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//updatePersonalInfo

export const updatePersonalInfo = (formData, employee, setChanged) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee/personal-info",
        formData
      );
      if (formData.canEditIdNumber) {
        employee.nidVerified = true;
        employee.profileImage = data;
      }

      if (formData.canEditOtherInfo) {
        employee.phoneNumber = formData.phoneNumber;
        employee.email = formData.email;
      }

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data,
      });

      setChanged(false);

      dispatch({ type: types.END_LOADING });
      showSuccess("Changes saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//updateEmployment

export const updateUserProfile = (
  formData,
  employee,
  setChanged,
  setEditUserProfileDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/support/user-personal-info",
        formData
      );

      employee.nidVerified = true;
      employee.profileImage = data;

      employee.phoneNumber = formData.phoneNumber;
      employee.email = formData.email;

      dispatch({
        type: types.SET_USER_PROFILE,
        data: employee,
      });

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data,
      });

      setChanged(false);
      setEditUserProfileDialog(false);
      dispatch({
        type: types.SET_SEARCHED_USER_DETAILS,
        data: [],
      });
      dispatch({ type: types.END_LOADING });
      showSuccess("Changes saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployeeProfessionalEmail = (
  formData,
  setShowProfessionalEmailDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee-professional-email",
        formData
      );

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data,
      });

      setShowProfessionalEmailDialog(false);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateEmployment = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee/employment",
        formData
      );

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess("Employment updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//updateEmployeeInsurance

export const updateEmployeeInsurance = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee/insurance",
        formData
      );

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess("Insurance updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//updateBanking

export const updateBanking = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee/banking",
        formData
      );

      // dispatch({
      //   type: types.UPDATE_EMPLOYEE,
      //   data
      // });
      dispatch({ type: types.END_LOADING });
      showSuccess("Banking updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const removeSecondBank = (
  formData,
  setAddBank2,
  setConfirmRemoveBank2
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/structure/employee/remove-second-bank", formData);

      dispatch({ type: types.END_LOADING });
      showSuccess("Second Bank removed successfully");
      setAddBank2(false);
      setConfirmRemoveBank2(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSalaryIndexGrid = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_SALARAY_INDEX_GRID,
        data: null,
      });

      const { data } = await axios.get("/api/structure/salary-index-grid");

      let columns = [];
      if (!!data.length) {
        const obj = data[0];
        columns = Object.keys(obj);
      }

      dispatch({
        type: types.SET_SALARAY_INDEX_GRID,
        data: {
          rows: data,
          columns: columns,
        },
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateSalaryIndexGrid = (level, scale, value, type, setValue) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      // console.log(setValue);

      if (type === "number") setValue("");

      await axios.post("/api/structure/update-salary-index-grid", {
        level: level,
        scale: scale,
        value: value,
      });

      setValue(value);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      // if (type === "number") setValue("");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getJobClassifications = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_JOB_CLASSIFICATIONS,
        data: [],
      });

      const { data } = await axios.get("/api/structure/job-classifications");

      dispatch({
        type: types.SET_JOB_CLASSIFICATIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//view-job-classifications

export const getVJobClassifications = (entityClassId) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_VJOB_CLASSIFICATIONS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/structure/v-job-classifications/" + entityClassId
      );

      dispatch({
        type: types.SET_VJOB_CLASSIFICATIONS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//shared-scopes

export const getSharedScopes = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SHARED_SCOPES,
        data: [],
      });

      const { data } = await axios.get("/api/structure/shared-scopes");

      dispatch({
        type: types.SET_SHARED_SCOPES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveJobClassification = (
  formData,
  setFormData,
  setShowAddJobForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/job-classifications",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.UPDATE_JOB_CLASSIFICATION,
        data,
      });

      setFormData({
        jobId: "",
        entityClassId: "",
        jobCategoryId: "",
        levelId: "",
      });

      setShowAddJobForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntityClasses = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/entity-classes");

      dispatch({
        type: types.SET_ENTITY_CLASSES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getJobCategories = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/job-categories");

      dispatch({
        type: types.SET_JOB_CATEGORIES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadAllEmployeesPerJob = (job, setJob, setEmployees) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/employees-per-job/" + job.id
      );

      dispatch({ type: types.END_LOADING });

      if (!data.length) {
        setJob({ id: null, name: "", jobFieldId: "" });
        return showError("No record found");
      }
      setEmployees(data);

      setJob({ id: null, name: "", jobFieldId: "" });
    } catch (error) {
      setJob({ id: null, name: "", jobFieldId: "" });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getJobs = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/jobs");

      dispatch({
        type: types.SET_JOBS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addNewJob = (job, setShowAdd, setJob) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/structure/jobs", {
        id: job.id,
        name: job.name,
        jobFieldId: job.jobFieldId,
      });

      setShowAdd(false);

      setJob({ id: null, name: "", jobFieldId: "" });

      if (!!job.id)
        dispatch({
          type: types.UPDATE_JOB,
          data,
        });
      else
        dispatch({
          type: types.ADD_JOB,
          data,
        });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteJob = (id, setConfirmRemoveJob, setJob) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/structure/jobs/" + id);

      setConfirmRemoveJob(false);
      setJob({ id: null, name: "", jobFieldId: "" });

      dispatch({
        type: types.DELETE_JOB,
        id,
      });

      dispatch({ type: types.END_LOADING });

      showSuccess("Deleted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// //updateJob
// export const updateJob = (formData, setFormData, setShowUpdateJobForm) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: types.START_LOADING });

//       const { data } = await axios.put(
//         "/api/structure/jobs/" + formData.jobId,
//         formData
//       );

//       dispatch({ type: types.END_LOADING });
//       showSuccess("Saved successfully");

//       dispatch({
//         type: types.UPDATE_JOB_CLASSIFICATION,
//         data,
//       });

//       setFormData({
//         name: "",
//         jobId: "",
//         entityClassId: "",
//       });

//       setShowUpdateJobForm(false);
//     } catch (error) {
//       dispatch({ type: types.END_LOADING });
// showError(error);
//     }
//   };
// };
//updateJobClassificationField

export const saveJobClassificationAllowance = (
  formData,
  setFormData,
  setAdd
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/job-classification-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_JOB_CLASSIFICATION_ALLOWANCE,
        data,
      });

      setFormData({
        jobClassificationId: formData.jobClassificationId,
        allowanceId: "",
        isActive: true,
      });

      setAdd(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//job-classification-allowances
export const getJobClassificationAllowances = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_JOB_CLASSIFICATION_ALLOWANCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/job-classification-allowances/" + jobClassificationId
      );

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_ALLOWANCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//deleteJobClassification

export const deleteJobClassification = (
  jobClassificationId,
  setConfirmRemoveJob
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/job-classifications/" + jobClassificationId
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.UPDATE_JOB_CLASSIFICATION,
        data,
      });

      setConfirmRemoveJob(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteJobClassificationAllowance = (
  selectedJobClassificationAllowance,
  setConfirmRemoveJobClassificationAllowance,
  setSelectedJobClassificationAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/job-classification-allowances/" +
          selectedJobClassificationAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_JOB_CLASSIFICATION_ALLOWANCE,
        data: selectedJobClassificationAllowance,
      });

      setConfirmRemoveJobClassificationAllowance(false);
      setSelectedJobClassificationAllowance(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//unit-allowances
export const getUnitAllowances = (unitId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_UNIT_ALLOWANCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/unit-allowances/" + unitId
      );

      dispatch({
        type: types.SET_UNIT_ALLOWANCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntitySectorAllowances = (entitySectorId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_ENTITY_SECTOR_ALLOWANCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/entity-sector-allowances/" + entitySectorId
      );

      dispatch({
        type: types.SET_ENTITY_SECTOR_ALLOWANCES,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//getPositionAllowances

export const getPositionAllowances = (positionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_POSITION_ALLOWANCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/position-allowances/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_ALLOWANCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//shared-position-allowances

export const getSharedPositionAllowances = (sharedPositionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SHARED_POSITION_ALLOWANCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/shared-position-allowances/" + sharedPositionId
      );

      dispatch({
        type: types.SET_SHARED_POSITION_ALLOWANCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeAllowances = (employeePositionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_ALLOWANCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/employee-allowances/" + employeePositionId
      );

      dispatch({
        type: types.SET_EMPLOYEE_ALLOWANCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveUnitAllowance = (
  formData,
  setFormData,
  setAdd,
  setAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/unit-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_UNIT_ALLOWANCE,
        data,
      });

      setFormData({
        unitId: formData.unitId,
        allowanceId: "",
      });

      setAdd(false);
      setAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//saveEntitySectorAllowance

export const saveEntitySectorAllowance = (
  formData,
  setFormData,
  setAdd,
  setAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/entity-sector-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_ENTITY_SECTOR_ALLOWANCE,
        data,
      });

      setFormData({
        entitySectorId: formData.entitySectorId,
        allowanceId: "",
      });

      setAdd(false);
      setAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const savePositionAllowance = (
  formData,
  setFormData,
  setAdd,
  setAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/position-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_POSITION_ALLOWANCE,
        data,
      });

      setFormData({
        positionId: formData.positionId,
        allowanceId: "",
        sourceOfFundId: null,
        paidWithSalary: true,
      });

      setAdd(false);
      setAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveSharedPositionAllowance = (
  formData,
  setFormData,
  setAdd,
  setAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/shared-position-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_SHARED_POSITION_ALLOWANCE,
        data,
      });

      setFormData({
        sharedPositionId: formData.sharedPositionId,
        allowanceId: "",
      });

      setAdd(false);
      setAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployeeAllowance = (
  formData,
  setFormData,
  setAdd,
  setAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee-allowances",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_EMPLOYEE_ALLOWANCE,
        data,
      });

      setFormData({
        employeePositionId: formData.employeePositionId,
        allowanceId: "",
        sourceOfFundId: null,
        paidWithSalary: true,
        startDate: new Date(),
      });

      setAdd(false);
      setAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployeeAllowanceProRata = (
  formData,
  setFormData,
  setShowEmployeeAllowanceProRata
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee-allowances-pro-rata",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      showSuccess("Saved successfully");

      dispatch({
        type: types.SET_EMPLOYEE_ALLOWANCES,
        data,
      });

      setFormData({
        employeePositionId: "",
        allowanceId: "",
        amount: 0,
      });

      setShowEmployeeAllowanceProRata(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removeEmployeeAllowanceProRata = (
  { employeePositionId, allowanceId },
  handleCloseMenu
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/employee-allowances-pro-rata/" +
          employeePositionId +
          "/" +
          allowanceId
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      showSuccess("Pro-rata removed successfully");

      dispatch({
        type: types.SET_EMPLOYEE_ALLOWANCES,
        data,
      });

      handleCloseMenu(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveUnitCreditor = (
  formData,
  setFormData,
  setAdd,
  setCreditor,
  canEditCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = !canEditCreditor
        ? await axios.post("/api/structure/unit-withholds", formData)
        : await axios.put(
            "/api/structure/unit-withholds/" + formData.id,
            formData
          );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess(
        canEditCreditor ? "Updated successfully" : "Saved successfully"
      );

      dispatch({
        type: canEditCreditor
          ? types.UPDATE_UNIT_WITHHOLD
          : types.ADD_UNIT_WITHHOLD,
        data: data,
      });

      setFormData({
        unitId: formData.unitId,
        creditorId: "",
        amount: 0,
        totalAmount: 0,
        startDate: null,
        id: 0,
      });

      setAdd(false);
      setCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployeeWithhold = (
  formData,
  setFormData,
  setAdd,
  setCreditor,
  setDisableTotalAmount
  // canEditCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee-withholds",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      dispatch({
        type: !!formData.id
          ? types.UPDATE_EMPLOYEE_WITHHOLD
          : types.ADD_EMPLOYEE_WITHHOLD,
        data: data,
      });

      setFormData({
        employeeId: formData.employeeId,
        employeePositionId: formData.employeePositionId,
        creditorId: "",
        amount: 0,
        rate: 0,
        totalAmount: 0,
        endDate: null,
        id: null,
        payrollTypeId: 1,
      });

      setDisableTotalAmount(true);

      setAdd(false);
      setCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const rejectEmployeeWithhold = (
  employeeWithholdForm,
  setEmployeeWithholdForm,
  setApproveEmployeeWithhold,
  setAskToReject
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/reject-employee-withhold-request",
        employeeWithholdForm
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Rejected Successfully!");

      dispatch({
        type: types.UPDATE_EMPLOYEE_WITHHOLD,
        data: data,
      });

      setEmployeeWithholdForm(false);
      setAskToReject(false);
      setApproveEmployeeWithhold(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveEmployeeWithhold = (payload, handleCloseMenu) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/approve-employee-withhold-request",
        payload
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Approved Successfully!");

      dispatch({
        type: types.UPDATE_EMPLOYEE_WITHHOLD,
        data: data,
      });
      handleCloseMenu();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const saveSalaryStructure = (
  formData,
  setFormData,
  setShowAddNewForm,
  setJobClassification
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/salary-structures",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_SALARY_STRUCTURE,
        data,
      });

      setFormData({
        hiringModeId: "",
        employeeGroupId: "",
        scaleId: "",
        indexValue: 0,
        numberOfPosts: 0,
        name: "",
        levelId: "",
        jobClassificationId: "",
        jobFieldId: "",
        entitySectorId: 0,
      });
      setJobClassification(null);
      setShowAddNewForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//updateSalaryStructure

export const updateSalaryStructure = (
  formData,
  setFormData,
  setJobClassification,
  setShowSalaryStructure,
  id
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/salary-structures/" + id,
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      dispatch({
        type: types.UPDATE_SALARY_STRUCTURE,
        data,
      });

      setFormData({
        hiringModeId: "",
        employeeGroupId: "",
        scaleId: "",
        indexValue: 0,
        numberOfPosts: 0,
        name: "",
        levelId: "",
        jobClassificationId: "",
        jobFieldId: "",
        entitySectorId: 0,
      });
      setJobClassification(null);

      setShowSalaryStructure(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteSalaryStructure = (
  selectedSalaryStructure,
  setSelectedSalaryStructure,
  setConfirmRemoveSalaryStructure
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/salary-structures/" + selectedSalaryStructure.id
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_SALARY_STRUCTURE,
        data: selectedSalaryStructure,
      });

      setConfirmRemoveSalaryStructure(false);
      setSelectedSalaryStructure(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteUnitAllowance = (
  selectedUnitAllowance,
  setConfirmRemoveUnitAllowance,
  setSelectedUnitAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/unit-allowances/" + selectedUnitAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_UNIT_ALLOWANCE,
        data: selectedUnitAllowance,
      });

      setConfirmRemoveUnitAllowance(false);
      setSelectedUnitAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//deleteEntitySectorAllowance

export const deleteEntitySectorAllowance = (
  selectedEntitySectorAllowance,
  setConfirmRemoveEntitySectorAllowance,
  setSelectedEntitySectorAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/entity-sector-allowances/" +
          selectedEntitySectorAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_ENTITY_SECTOR_ALLOWANCE,
        data: selectedEntitySectorAllowance,
      });

      setConfirmRemoveEntitySectorAllowance(false);
      setSelectedEntitySectorAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteEmployeeAllowance = (
  selectedEmployeeAllowance,
  setConfirmRemoveEmployeeAllowance,
  setSelectedEmployeeAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/employee-allowances/" + selectedEmployeeAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_EMPLOYEE_ALLOWANCE,
        data: selectedEmployeeAllowance,
      });

      setConfirmRemoveEmployeeAllowance(false);
      setSelectedEmployeeAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deletePositionAllowance = (
  selectedPositionAllowance,
  setConfirmRemovePositionAllowance,
  setSelectedPositionAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/position-allowances/" + selectedPositionAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_POSITION_ALLOWANCE,
        data: selectedPositionAllowance,
      });

      setConfirmRemovePositionAllowance(false);
      setSelectedPositionAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteSharedPositionAllowance = (
  selectedSharedPositionAllowance,
  setConfirmRemoveSharedPositionAllowance,
  setSelectedSharedPositionAllowance
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/shared-position-allowances/" +
          selectedSharedPositionAllowance.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_SHARED_POSITION_ALLOWANCE,
        data: selectedSharedPositionAllowance,
      });

      setConfirmRemoveSharedPositionAllowance(false);
      setSelectedSharedPositionAllowance(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteUnitWithHolds = (
  selectedUnitCreditor,
  setConfirmRemoveUnitCreditor,
  setSelectedUnitCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/unit-withholds/" + selectedUnitCreditor.id
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_UNIT_WITHHOLD,
        data: selectedUnitCreditor,
      });

      setConfirmRemoveUnitCreditor(false);
      setSelectedUnitCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteEmployeeWithHold = (
  id,
  formData,
  setConfirmRemoveEmployeeCreditor,
  setSelectedEmployeeCreditor
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/structure/delete-employee-withholds", formData);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_EMPLOYEE_WITHHOLD,
        id: id,
      });

      setConfirmRemoveEmployeeCreditor(false);
      setSelectedEmployeeCreditor(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//unit-withholds
export const getUnitWithholds = (unitId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_UNIT_WITHHOLDS,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/unit-withholds/" + unitId
      );

      dispatch({
        type: types.SET_UNIT_WITHHOLDS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeWithholds = (employeeId, employeePositionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_WITHHOLDS,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/employee-withholds/" +
          employeeId +
          "/" +
          employeePositionId
      );

      dispatch({
        type: types.SET_EMPLOYEE_WITHHOLDS,
        data: arrangingWithholds(data),
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPaymentStatuses = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/payment-statuses");

      dispatch({
        type: types.SET_PAYMENT_STATUSES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getJobFields = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_JOB_FIELDS,
        data: [],
      });
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/structure/job-fields");

      dispatch({
        type: types.SET_JOB_FIELDS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addNewField = (jobField, setShowAdd, setJobField) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/structure/job-fields", {
        id: jobField.id,
        name: jobField.name,
      });

      setShowAdd(false);
      setJobField({ id: null, name: "" });

      if (!!jobField.id)
        dispatch({
          type: types.UPDATE_JOB_FIELD,
          data,
        });
      else
        dispatch({
          type: types.ADD_JOB_FIELD,
          data,
        });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteJobField = (id, setConfirmRemoveJobField, setJobField) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/structure/job-fields/" + id);

      setConfirmRemoveJobField(false);
      setJobField({ id: null, name: "" });

      dispatch({
        type: types.DELETE_JOB_FIELD,
        id,
      });

      dispatch({ type: types.END_LOADING });

      showSuccess("Deleted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntitySectors = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_ENTITY_SECTORS,
        data: [],
      });

      const { data } = await axios.get("/api/structure/entity-sectors");

      dispatch({
        type: types.SET_ENTITY_SECTORS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntitiesOversights = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/structure/entities-oversights");

      dispatch({
        type: types.SET_ENTITIES_OVERSIGHTS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getJobClassificationEmployees = (jobClassificationId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_JOB_CLASSIFICATION_EMPOYEES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/job-classification-employees/" + jobClassificationId
      );

      dispatch({
        type: types.SET_JOB_CLASSIFICATION_EMPOYEES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const lockEntitySector = (selectedEntitySector, setIsLocked) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      await axios.put(
        "/api/structure/lock-entity-sector/" + selectedEntitySector.id,
        { isLocked: selectedEntitySector.isLocked }
      );
      dispatch({ type: types.END_LOADING });
      setIsLocked(selectedEntitySector.isLocked);
      dispatch({
        type: types.UPDATE_ENTITY_SECTOR,
        data: selectedEntitySector,
      });

      showSuccess(
        `${selectedEntitySector.isLocked ? "Locked" : "Unlocked"} successfully`
      );
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPositionCertificates = (positionId) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_POSITION_CERTIFICATES,
        data: [],
      });

      const { data } = await axios.get(
        "/api/structure/position-certificates/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_CERTIFICATES,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getNonAddedPositionCertificates = (positionId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      dispatch({
        type: types.SET_NON_ADDED_POSITION_CERTIFICATES,
        data: [],
      });

      const { data } = await axios.get(
        "/api/structure/non-added-position-certificates/" + positionId
      );

      dispatch({
        type: types.SET_NON_ADDED_POSITION_CERTIFICATES,
        data,
      });

      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//non-added-position-certificates
export const getNonAddedPositionCompetencies = (positionId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      dispatch({
        type: types.SET_NON_ADDED_POSITION_COMPETENCIES,
        data: [],
      });

      const { data } = await axios.get(
        "/api/structure/non-added-position-competencies/" + positionId
      );

      dispatch({
        type: types.SET_NON_ADDED_POSITION_COMPETENCIES,
        data,
      });

      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
// export const savePositionCertificate = (formData) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: types.START_WAIT });
//       dispatch({ type: types.START_LOADING });

//       const { data } = await axios.post(
//         "/api/structure/position-certificates",
//         formData
//       );

//       dispatch({ type: types.END_LOADING });
//       dispatch({ type: types.END_WAIT });
//       showSuccess("Saved successfully");

//       dispatch({
//         type: types.UPDATE_POSITION_CERTIFICATE,
//         data: data,
//       });
//     } catch (error) {
//       dispatch({ type: types.END_LOADING });
//       dispatch({ type: types.END_WAIT });
//       if (isSessionExpired(error)) {
//         dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
//       }
//       showError(error);
//     }
//   };
// };

export const getPositionQualifications = (positionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_POSITION_QUALIFICATIONS,
        data: [],
      });
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/position-qualifications/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_QUALIFICATIONS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeQualifications = (employeeId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_QUALIFICATIONS,
        data: [],
      });
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/employee-qualifications/" + employeeId
      );

      dispatch({
        type: types.SET_EMPLOYEE_QUALIFICATIONS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const updateUserQualificationsStatus = (id, payload, setConfirm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/employee-qualifications/" + id,
        payload
      );

      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      setConfirm(false);

      dispatch({
        type: types.UPDATE_EMPLOYEE_QUALIFICATION,
        data,
      });
      showSuccess(
        payload.statusId === "Reject"
          ? "Rejected successful"
          : "Approved successful"
      );
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployeeQualification = (formData, onClose, canEdit) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        canEdit
          ? "/api/structure/update-employee-qualifications"
          : "/api/structure/employee-qualifications",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess(canEdit ? "Updated successfully" : "Saved successfully");

      dispatch({
        type: canEdit
          ? types.UPDATE_EMPLOYEE_QUALIFICATION
          : types.ADD_EMPLOYEE_QUALIFICATION,
        data: data,
      });
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteEmployeeQualification = (
  qualificationId,
  setConfirmRemoveQualification,
  setSelectedQualification
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/employee-qualifications/" + qualificationId
      );

      showSuccess(data.message);

      dispatch({
        type: types.REMOVE_EMPLOYEE_QUALIFICATION,
        id: qualificationId,
      });

      setConfirmRemoveQualification(false);
      setSelectedQualification(null);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeCertificates = (employeeId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_CERTIFICATES,
        data: [],
      });
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/employee-certificates/" + employeeId
      );

      dispatch({
        type: types.SET_EMPLOYEE_CERTIFICATES,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployeeCertificate = (formData, onClose, canEdit) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        canEdit
          ? "/api/structure/update-employee-certificates"
          : "/api/structure/employee-certificates",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess(canEdit ? "Updated successfully" : "Saved successfully");

      dispatch({
        type: canEdit
          ? types.UPDATE_EMPLOYEE_CERTIFICATE
          : types.ADD_EMPLOYEE_CERTIFICATE,
        data: data,
      });
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteEmployeeCertificate = (
  certificateId,
  setConfirmRemoveCertificate,
  setSelectedCertificate
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/employee-certificates/" + certificateId
      );

      showSuccess(data.message);

      dispatch({
        type: types.REMOVE_EMPLOYEE_CERTIFICATE,
        id: certificateId,
      });

      setConfirmRemoveCertificate(false);
      setSelectedCertificate(null);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPositionCompetencies = (positionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_POSITION_COMPETENCIES,
        data: [],
      });

      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/position-competencies/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_COMPETENCIES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const savePositionCompetency = (formData, competency, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/position-competencies",
        formData
      );

      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      competency.positionCompetencyId = data;
      dispatch({
        type: types.ADD_POSITION_COMPETENCY,
        data: competency,
      });

      onClose();
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doVerifyNIDNumber = (employee, setIsNIDVerifying) => {
  return async (dispatch) => {
    try {
      setIsNIDVerifying(true);
      const { data } = await axios.post("/api/structure/verify-nid-number", {
        employeeId: employee.id,
        idNumber: employee.idNumber,
      });

      setIsNIDVerifying(false);

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data: { ...employee, nidVerified: data.verified },
      });
    } catch (error) {
      setIsNIDVerifying(false);

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doVerifyRSSBNumber = (employee, setIsRSSBVerifying) => {
  return async (dispatch) => {
    try {
      setIsRSSBVerifying(true);
      const { data } = await axios.post("/api/structure/verify-rssb-number", {
        employeeId: employee.id,
        rssbNumber: employee.rssbNumber,
      });

      setIsRSSBVerifying(false);

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data: { ...employee, rssbVerified: data.verified },
      });
    } catch (error) {
      setIsRSSBVerifying(false);

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addOrUpdateMedical = (
  medical,
  employee,
  setShowAddMedical,
  setMedical
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/structure/employee-medical-insurance",
        {
          medicalInsuranceTypeId: medical.medicalInsuranceTypeId,
          rssbNumber: medical.rssbNumber,
          medicalInsuranceNo: medical.medicalInsuranceNo,
          employeeId: employee.id,
          employeePositionId: employee.employeePositionId,
        }
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      setShowAddMedical(false);
      setMedical({
        medicalInsuranceTypeId: null,
        rssbNumber: "",
        medicalInsuranceNo: "",
      });

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data: { ...employee, ...medical, rssbVerified: data.verified },
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const savePaymentStatus = (payload, setShowPaymentStatusDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/structure/employee-payment-statuses",
        payload
      );

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      setShowPaymentStatusDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doPreviewEmployeePaySlip = (employeeId, periodId) => {
  return async (dispatch) => {
    dispatch({ type: types.START_LOADING });
    try {
      dispatch({
        type: types.PREVIEW_EMPLOYEE_PAYSLIP,
        data: null,
      });

      const { data } = await axios.get(
        "/api/payroll/employee-payslip?employeeId=" +
          employeeId +
          "&periodId=" +
          periodId
      );

      const bytes = window.atob(data);

      let length = bytes.length;
      let byteArray = new Uint8Array(length);

      while (length--) {
        byteArray[length] = bytes.charCodeAt(length);
      }

      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      dispatch({
        type: types.PREVIEW_EMPLOYEE_PAYSLIP,
        data: url,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//saveRequisition
export const saveRequisition = (
  requisitionForm,
  setRequisitionForm,
  setShowAddRequisitionForm,
  history,
  isFromWaitingList
) => {
  return async (dispatch) => {
    try {
      if (!isFromWaitingList) {
        dispatch({ type: types.START_LOADING });

        const { data } = await axios.post(
          "/api/recruitment/requisitions",
          requisitionForm
        );

        showSuccess("Request submitted successfully");

        setShowAddRequisitionForm(false);
        dispatch({
          type: types.UPDATE_POSITION,
          data: data,
        });

        setRequisitionForm({
          positionId: "",
          numberOfPosts: "",
          isInternal: false,
          reason: "",
          willHavePsychometricTest: true,
          willHaveWrittenExam: true,
          willHaveOralExam: true,
        });
        dispatch({ type: types.END_LOADING });

        history.push("/recruitment/requisitions");
      } else {
        dispatch({ type: types.START_LOADING });
        const { data } = await axios.post(
          "/api/recruitment/waiting-list-requisitions",
          requisitionForm
        );

        showSuccess("Request submitted successfully");

        setShowAddRequisitionForm(false);
        dispatch({
          type: types.UPDATE_POSITION,
          data: data.position,
        });

        setRequisitionForm({
          positionId: "",
          positionName: "",
          levelId: "",
          scaleId: "",
          number: "",
          requestComment: "",
        });
        dispatch({ type: types.END_LOADING });

        history.push("/recruitment/waiting-list/requisitions");
      }
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const savePositionActing = (payroad, setShowPositionSetActingDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/structure/actings", payroad);

      showSuccess("Saved successfully");

      dispatch({
        type: types.UPDATE_POSITION,
        data: data,
      });

      setShowPositionSetActingDialog(false);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeBankAccounts = (
  employeeId,
  setShowSalarySettingsDialog,
  handleCloseMenu
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_BANK_ACCOUNTS,
        data: [],
      });

      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/employee-bank-accounts/" + employeeId
      );

      dispatch({
        type: types.SET_EMPLOYEE_BANK_ACCOUNTS,
        data,
      });

      setShowSalarySettingsDialog(true);

      handleCloseMenu();

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addOrUpdateEmployeeBankAccount = (
  formData,
  setShowAddBankAccount,
  setBankAccount
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee-bank-accounts",
        formData
      );

      setShowAddBankAccount(false);
      setBankAccount({
        id: null,
        bankId: "",
        currencyId: "RWF",
        accountNumber: "",
        isSecondAccount: false,
        amount: 0,
      });

      dispatch({
        type: types.SET_EMPLOYEE_BANK_ACCOUNTS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteEmployeeBankAccount = (id, setConfirmRemoveJobField) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/structure/employee-bank-accounts/" + id);

      setConfirmRemoveJobField(false);

      dispatch({
        type: types.DELETE_EMPLOYEE_BANK_ACCOUNT,
        id,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      showSuccess("Deleted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployeeArrear = (
  formData,
  setFormData,
  setAdd,
  setPeriod,
  setArrearType
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee-position-arrears",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_EMPLOYEE_ARREAR,
        data: data,
      });

      setFormData({
        periodId: "",
        arrearTypeId: "",
        noOfDays: 0,
        basePay: 0,
        housingAllowance: 0,
        transportAllowance: 0,
        taxableAllowance: 0,
        exemptedAllowance: 0,
        lumpsumAllowance: 0,
        reason: "",
      });

      setAdd(false);
      setPeriod(null);
      setArrearType(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteEmployeeArrear = (id, setConfirmRemoveEmployeeArrear) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/structure/employee-position-arrears/" + id);

      setConfirmRemoveEmployeeArrear(false);

      dispatch({
        type: types.DELETE_EMPLOYEE_ARREAR,
        id,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      showSuccess("Deleted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPeriods = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/periods");

      dispatch({
        type: types.SET_PERIODS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeArrears = (employeePositionId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/structure/employee-position-arrears/" + employeePositionId
      );

      dispatch({
        type: types.SET_EMPLOYEE_ARREARS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeProfile = (
  { employeeId, idNumber },
  setShowEmployeeProfileDialog,
  handleCloseMenu
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_PROFILE,
        data: {},
      });

      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/employee-profile/" + employeeId + "/" + idNumber
      );

      dispatch({
        type: types.SET_EMPLOYEE_PROFILE,
        data,
      });

      handleCloseMenu();

      setShowEmployeeProfileDialog(true);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployeeExperiences = (
  payload,
  setFormData,
  onClose,
  setEnableSelecting,
  canEdit
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        canEdit
          ? "/api/structure/update-employee-experiences"
          : "/api/structure/employee-experiences",
        payload
      );

      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      onClose();
      setFormData({
        id: null,
        employeePositionId: payload.employeePositionId,
        employeeId: payload.employeeId,
        jobFieldId: "",
        positionId: "",
        positionName: "",
        institutionId: "",
        institutionName: "",
        institutionEmail: "",
        institutionPhone: "",
        fromDate: null,
        toDate: null,
        responsibilities: "",
      });

      setEnableSelecting(false);
      showSuccess(canEdit ? "Updated successfully" : "Saved successfully");
      dispatch({
        type: canEdit
          ? types.UPDATE_EMPLOYEE_EMPLOYMENT
          : types.ADD_EMPLOYEE_EMPLOYMENT,
        data,
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteEmployeeExperience = (id, setConfirmRemoveEmployment) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/structure/employee-experiences/" + id
      );

      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      setConfirmRemoveEmployment(false);

      dispatch({
        type: types.REMOVE_EMPLOYEE_EMPLOYMENT,
        id,
      });
      showSuccess("Deleted successful");
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateUserExperienceStatus = (id, payload, setConfirm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/employee-experiences/" + id,
        payload
      );

      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      setConfirm(false);

      dispatch({
        type: types.UPDATE_EMPLOYEE_EMPLOYMENT,
        data,
      });
      showSuccess(
        payload.statusId === "Reject"
          ? "Rejected successful"
          : "Approved successful"
      );
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateUserCertificateStatus = (id, payload, setConfirm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/employee-certificates/" + id,
        payload
      );

      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      setConfirm(false);

      dispatch({
        type: types.UPDATE_EMPLOYEE_CERTIFICATE,
        data,
      });
      showSuccess(
        payload.statusId === "Reject"
          ? "Rejected successful"
          : "Approved successful"
      );
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateUserFile = (
  document,
  actionType,
  docName,
  setDocument,
  setShowDocument,
  setSelectedRecord
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/api/structure/update-user-file", {
        oldDocReferenceId: document.oldDocReferenceId,
        id: document.id,
        actionType: actionType,
        docName: docName,
        userId: document.userId,
      });

      document.docReferenceId = data;

      dispatch({
        type: types[actionType],
        data: document,
      });
      setDocument(document);
      setSelectedRecord(null);
      setShowDocument(true);
    } catch (error) {
      setSelectedRecord(null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRelationships = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/relationships");

      dispatch({
        type: types.SET_RELATIONSHIPS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCountries = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/countries");

      dispatch({
        type: types.SET_COUNTRIES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSchools = (countryId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      dispatch({
        type: types.SET_SCHOOLS,
        data: [],
      });

      const { data } = await axios.get("/api/structure/schools/" + countryId);

      dispatch({
        type: types.SET_SCHOOLS,
        data,
      });

      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// export const getEmployeeNextOfKins = (
//   employeeId,
//   setShowEmployeeProfileDialog,
//   handleCloseMenu
// ) => {
//   return async (dispatch) => {
//     try {

//       dispatch({
//         type: types.SET_EMPLOYEE_NEXT_OF_KIN,
//         data:{},
//       });

//       dispatch({ type: types.START_LOADING });

//       const { data } = await axios.get(
//         "/api/structure/employee-next-of-kins/" + employeeId
//       );

//       dispatch({
//         type: types.SET_EMPLOYEE_NEXT_OF_KIN,
//         data,
//       });

//       setShowEmployeeProfileDialog(true);

//       handleCloseMenu();

//       dispatch({ type: types.END_LOADING });
//     } catch (error) {
//       dispatch({ type: types.END_LOADING });
// showError(error);
//     }
//   };
// };

export const addOrUpdateNextOfKin = (
  formData,
  setShowNextOfKinDialog,
  setNextOfKin
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee-next-of-kins",
        formData
      );

      setShowNextOfKinDialog(false);
      setNextOfKin({
        employeeId: "",
        idNumber: "",
        relationshipId: "",
        foreName: "",
        surnames: "",
        bankId: "",
        bankAccountNumber: "",
        phoneNumber: "",
        email: "",
      });

      dispatch({
        type: types.SET_EMPLOYEE_NEXT_OF_KIN,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteNextOfKin = (id, setConfirmRemoveJobField) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/structure/employee-bank-accounts/" + id);

      setConfirmRemoveJobField(false);

      dispatch({
        type: types.DELETE_EMPLOYEE_BANK_ACCOUNT,
        id,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      showSuccess("Deleted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const stopEntity = (formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const res = await axios.put(`/api/admin/entity-status/${formData.id}`, {
        action: formData.action,
        reason: formData.reason,
      });
      dispatch({ type: types.CHANGE_ENTITY_STATUS_OVERSIGHT, data: formData });
      dispatch({ type: types.END_LOADING });
      showSuccess(res.data);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * addNewEntity
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const addNewEntity = (formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(`/api/admin/entity`, formData);
      const res = await axios.get("/api/structure/entities-oversights");

      dispatch({
        type: types.SET_ENTITIES_OVERSIGHTS,
        data: res.data,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess(data);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

/**
 * updateEntity
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const updateEntity = (entityId, formData, callback) => {
  callback(false);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        `/api/admin/entity/${entityId}`,
        formData
      );

      dispatch({
        type: types.UPDATE_ENTITIES_OVERSIGHTS,
        data: data.data,
      });
      dispatch({ type: types.END_LOADING });
      showSuccess(data.message);
      callback(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      callback(false);
    }
  };
};

export const setAsRSSBIntegrated = ({ entityId, status }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(`/api/admin/set-as-rssb-integrated`, {
        entityId,
        status,
      });

      dispatch({
        type: types.UPDATE_ENTITIES_OVERSIGHTS,
        data: data.data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntities = (loadAll = 0) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/structure/entities?loadAll=" + loadAll
      );

      dispatch({
        type: types.SET_ENTITIES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeesPullRequests = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/structure/pull-requests");

      dispatch({
        type: types.SET_EMPLOYEES_PULL_REQUESTS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const pullEmployee = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/structure/employees", payload);

      dispatch({
        type: types.REMOVE_EMPLOYEE_PULL_REQUEST,
        id: payload.employeeId,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//employments
export const getEmployments = (employeeId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/structure/employments/" + employeeId
      );

      dispatch({
        type: types.SET_EMPLOYEE_EMPLOYMENTS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//saveReferee
export const saveReferee = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/structure/referees", formData);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess(
        formData?.refereeId > 0 ? "Update successfully" : "Saved successfully"
      );

      dispatch({
        type:
          formData?.refereeId > 0
            ? types.UPDATE_REFERENCE
            : types.ADD_REFERENCE,
        data: data,
      });
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getEmployeeReferences = (employeeId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_REFERENCES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/employee-references/" + employeeId
      );

      dispatch({
        type: types.SET_REFERENCES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeLanguages = (employeeId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_LANGUAGES,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/structure/employee-languages/" + employeeId
      );

      dispatch({
        type: types.SET_EMPLOYEE_LANGUAGES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getLanguages = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_LANGUAGES,
        data: [],
      });
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/structure/languages");

      dispatch({
        type: types.SET_LANGUAGES,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//deleteReferee

export const deleteReferee = (
  selectedReferee,
  setConfirmRemoveReferee,
  setSelectedReferee
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/employee-references/" + selectedReferee.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.REMOVE_REFERENCE,
        data: selectedReferee,
      });

      setConfirmRemoveReferee(false);
      setSelectedReferee(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteLanguage = (
  selectedLanguage,
  setConfirmRemoveLanguage,
  setSelectedLanguage
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/employee-languages/" + selectedLanguage.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.REMOVE_EMPLOYEE_LANGUAGE,
        data: selectedLanguage,
      });

      setConfirmRemoveLanguage(false);
      setSelectedLanguage(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveLanguage = (formData, setFormData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee-languages",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess(
        formData.id > 0 ? "Update successfully" : "Saved successfully"
      );

      dispatch({
        type:
          formData.id > 0
            ? types.UPDATE_EMPLOYEE_LANGUAGE
            : types.ADD_EMPLOYEE_LANGUAGE,
        data: data,
      });

      setFormData({
        id: 0,
        languageId: 0,
        reading: "",
        writting: "",
        speaking: "",
      });
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getDisabilities = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_DISABILITY,
        data: [],
      });
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/structure/disabilities");

      dispatch({
        type: types.SET_DISABILITY,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getDisabilityLevels = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_DISABILITY_LEVELS,
        data: [],
      });
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/structure/disability-levels");

      dispatch({
        type: types.SET_DISABILITY_LEVELS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteEmployeeDisability = (
  selectedDisability,
  setConfirmRemoveDisability,
  setSelectedDisability
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/structure/employee-disabilities/" + selectedDisability.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.REMOVE_EMPLOYEE_DISABILITY,
        data: selectedDisability,
      });

      setConfirmRemoveDisability(false);
      setSelectedDisability(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployeeDisability = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/employee-disabilities",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess(
        formData.id > 0 ? "Updated successfully" : "Saved successfully"
      );

      onClose();

      dispatch({
        type:
          formData.id > 0
            ? types.UPDATE_EMPLOYEE_DISABILITY
            : types.ADD_EMPLOYEE_DISABILITY,
        data: data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeDisabilities = (employeeId) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_EMPLOYEE_DISABILITIES,
        data: [],
      });

      const { data } = await axios.get(
        "/api/structure/employee-disabilities/" + employeeId
      );

      dispatch({
        type: types.SET_EMPLOYEE_DISABILITIES,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadResume = (employeeId, names, setShowDownloadCv) => {
  return async (dispatch) => {
    // dispatch({ type: types.START_LOADING });
    setShowDownloadCv(true);
    try {
      const { data } = await axios.get(
        "/api/structure/download-resume/" + employeeId,
        { responseType: "blob" }
      );

      download(new Blob([data]), `${names}-resume.pdf`, ".pdf");

      // dispatch({ type: types.END_LOADING });
      setShowDownloadCv(false);
      // onClose();
    } catch (error) {
      setShowDownloadCv(false);
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};
export const getUserProfile = (userId, setShowUserDetails, setUserProfile) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });
      setShowUserDetails(true);
      const { data } = await axios.get("/api/structure/user-profile/" + userId);
      setUserProfile(data);
      // dispatch({
      //   type: types.SET_USER_PROFILE,
      //   data,
      // });

      setShowUserDetails(false);
    } catch (error) {
      setShowUserDetails(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeNextKin = (employeeId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_NEXT_KEN,
        data: null,
      });

      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/structure/next-kin/" + employeeId);

      dispatch({
        type: types.SET_EMPLOYEE_NEXT_KEN,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//editWorkCertificateFile
export const editWorkCertificateFile = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/update-employee-work-certificate",
        payload
      );
      showSuccess("Work certificate updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_EMPLOYEE_EMPLOYMENT,
        data,
      });

      onClose();
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const editDiplomaFile = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/update-diploma-certificate",
        payload
      );
      showSuccess("Diploma updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_EMPLOYEE_QUALIFICATION,
        data,
      });

      onClose();
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//editCertificateFile
export const editCertificateFile = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/update-award-certificate",
        payload
      );
      showSuccess("Award certificate updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_EMPLOYEE_CERTIFICATE,
        data,
      });

      onClose();
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadEmployees = (unitId, unitName, setShowProgressBar) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowProgressBar(true);
      const { data } = await axios.get(
        "/api/structure/download-employees/" + unitId,
        { responseType: "blob" }
      );

      download(new Blob([data]), `LIST_OF_EMPLOYEES-${unitName}.xlsx`, ".xlsx");

      dispatch({ type: types.END_LOADING });

      setShowProgressBar(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowProgressBar(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const downloadPositions = (unitId, unitName, setShowProgressBar) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowProgressBar(true);
      const { data } = await axios.get(
        "/api/structure/download-positions/" + unitId,
        { responseType: "blob" }
      );
      download(new Blob([data]), `LIST_OF_POSITIONS-${unitName}.xlsx`, ".xlsx");

      dispatch({ type: types.END_LOADING });

      setShowProgressBar(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowProgressBar(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getHeadOfInstitution = (entitySectorId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/structure/head-of-institution/" + entitySectorId
      );

      dispatch({ type: types.SET_HEAD_OF_INSTITUTION, data });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getHeadOfUnit = (unitId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/head-of-unit/" + unitId);

      dispatch({ type: types.SET_HEAD_OF_UNIT, data });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getRRAGrades = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/structure/rra-grades");

      dispatch({
        type: types.SET_RRA_GRADES,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getRRADepartments = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/structure/rra-departments");

      dispatch({
        type: types.SET_RRA_DEPARTMENTS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const addOrUpdateRRADepartment = (formDate, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/rra-departments",
        formDate
      );

      onClose();

      dispatch({
        type: types.ADD_OR_UPDATE_RRA_DEPARTMENT,
        data,
      });

      showSuccess(`${!!formDate.id ? "Updated" : "Saved"} successfully`);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteRRADepartment = (id, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/structure/rra-departments/" + id);

      onClose();

      dispatch({
        type: types.DELETE_RRA_DEPARTMENT,
        id,
      });

      showSuccess(`Deleted successfully`);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const countEmployeeRequests = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_NO_OF_EMPLOYEE_REQUESTS,
        data: 0,
      });
      const { data } = await axios.get(
        "/api/structure/count-employee-requests"
      );
      dispatch({
        type: types.SET_NO_OF_EMPLOYEE_REQUESTS,
        data: data.number,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getEmployeeRequests = (setIsLoader) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EMPLOYEE_REQUESTS,
        data: [],
      });
      setIsLoader(true);
      const { data } = await axios.get("/api/structure/employee-requests");
      dispatch({
        type: types.SET_EMPLOYEE_REQUESTS,
        data,
      });
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveEmployeeRequest = (approveRequest, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/approve-employee-requests",
        approveRequest
      );

      dispatch({
        type: types.SET_NO_OF_EMPLOYEE_REQUESTS,
        data: data.number,
      });
      dispatch({
        type: types.UPDATE_EMPLOYEE_REQUEST,
        data: data.response,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Approved Successfully");

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const rejectEmployeeRequest = (rejectExitForm, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/reject-employee-requests",
        rejectExitForm
      );

      dispatch({
        type: types.SET_NO_OF_EMPLOYEE_REQUESTS,
        data: data.number,
      });
      dispatch({
        type: types.UPDATE_EMPLOYEE_REQUEST,
        data: data.response,
      });

      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Rejected successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const unlockTrainingRequestFor30Days = (
  payload,
  handleCloseMenu,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);

      const { data } = await axios.put(
        "/api/structure/unlock-training-request-for-30-days/" + payload.id,
        payload
      );

      setIsLoading(false);
      showSuccess("Updated Successfully");
      handleCloseMenu();
      dispatch({
        type: types.SET_SEARCHED_USER_DETAILS,
        data: [],
      });
      dispatch({
        type: types.SET_SEARCHED_USER_DETAILS,
        data: data,
      });
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getAllEmployeeEmployments = (employeeId, cb) => {
  return async (dispatch) => {
    try {
      cb(true, null);
      const { data } = await axios.get(
        "/api/structure/all-employee-employment/" + employeeId
      );

      cb(false, data);
    } catch (error) {
      cb(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSupervisorEmployees = (cb) => {
  return async (dispatch) => {
    try {
      cb(true, null);
      const { data } = await axios.get("/api/structure/supervisor-employees");

      cb(false, data);
    } catch (error) {
      cb(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCasualSalaries = (employeePositionId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/structure/casual-salaries/" + employeePositionId
      );

      dispatch({
        type: types.SET_CASUAL_SALARIES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveCasualSalary = (formData, setFormData, setAdd) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/structure/casual-salaries",
        formData
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Saved successfully");

      dispatch({
        type: types.ADD_CASUAL_SALARY,
        data: data,
      });

      setFormData({
        type: "D",
        multiplier: 0,
        periodId: "",
        basePay: 0,
        housingAllowance: 0,
        transportAllowance: 0,
        taxableAllowance: 0,
        lumpsumAllowance: 0,
      });

      setAdd(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteCasualSalary = (id, setConfirm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/structure/casual-salaries/" + id);

      setConfirm(false);

      dispatch({
        type: types.DELETE_CASUAL_SALARY,
        id,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      showSuccess("Deleted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const setSecondEmployment = (
  { employeePositionId, isSecondEmployment },
  cb
) => {
  return async (dispatch) => {
    try {
      cb(true, false);
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/structure/change-second-employment",
        { employeePositionId, isSecondEmployment }
      );

      dispatch({
        type: types.SET_SEARCHED_USER_SECOND_EMPLOYMENT,
        data: { employeePositionId, isSecondEmployment },
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      cb(false, true);

      showSuccess(data.message);
    } catch (error) {
      cb(false, false);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getVerticalRanks = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/structure/vertical-ranks");
      dispatch({
        type: types.SET_VERTICAL_RANKS,
        data: data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveEmployeeVerticalRank = (payload, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/structure/employee-vertical-ranks",
        payload
      );

      dispatch({
        type: types.UPDATE_EMPLOYEE,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      setShowDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntitySectorPlanningEntities = (cb) => {
  return async (dispatch) => {
    dispatch({ type: types.START_LOADING });
    cb(true);
    try {
      const { data } = await axios.get(
        "/api/structure/entity-sector-planning-entities"
      );
      cb(false);
      dispatch({
        type: types.SET_ENTITY_SECTOR_PLANNING_ENTITY,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const assignOrCreatePlanningEntity = (formData, cb) => {
  return async (dispatch) => {
    cb(true, false);
    try {
      const { data } = await axios.post(
        "/api/structure/assign-or-create-planning-entity",
        formData
      );
      showSuccess(data.message);
      if (!!data.planningEntities) {
        dispatch({
          type: types.SET_ENTITY_SECTOR_PLANNING_ENTITY,
          data: data.planningEntities,
        });
      }
      cb(false, true);
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

/*
 * @Get supervisors
 * GET /api/structure/supervisors
 */

export const getExternalSupervisors = ({employeeId,entitySectorId = ""},cb) => {
  return async (dispatch) => {
    try {
      cb(true,null)

      const { data } = await axios.get(
        "/api/structure/external-supervisor-positions" +
          `/${employeeId}${!!entitySectorId ? "/" + entitySectorId : ""}`
      );

      cb(false, data);
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      cb(false,null)
      showError(error);
    }
  };
};