import React, { useState, useEffect } from "react";

import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import Home from "./Home";
import ToastifyMessage from "./common/components/ToastifyMessage";
import OfflineMessage from "./common/components/OfflineMessage";
import TopProgressBar from "./common/components/TopProgressBar";
import Header from "./common/components/Header";

import { getEnv, getUser, getModules } from "../store/common/actions";
import Footer from "./common/components/Footer";
import loadingGif from "./assets/loading.gif";

import StructureLayout from "./structure/Layout";
import EmployeesLayout from "./employees/Layout";
import RecruitmentLayout from "./recruitment/Layout";
import PayrollLayout from "./payroll/Layout";
import RBMLayout from "./rbm/Layout";
import AdminLayout from "./admin/Layout";
import TrainingLayout from "./training/Layout";
import ExitLayout from "./exit/Layout";
import Support from "./support/Layout";
import EappealLayout from "./e-appeal/Layout";
import UserLayout from "./common/Layout";
import LeaveLayout from "./leave/Layout";
import DisciplineLayout from "./discipline/Layout";
import ContractualLayout from "./contracts/Layout";
import SkillDashoardLayout from "./skillsDashboard/Layout";

// import DefaultDrawer from "./common/components/Drawer";
import StructureDrawer from "./structure/components/Drawer";
import EmployeesDrawer from "./employees/components/Drawer";
import RecruitmentDrawer from "./recruitment/components/Drawer";
import PayrollDrawer from "./payroll/components/Drawer";
import RBMDrawer from "./rbm/components/Drawer";
import ExitDrawer from "./exit/components/Drawer";
import TrainingDrawer from "./training/components/Drawer";

import SupportDrawer from "./support/components/Drawer";
import AdminDrawer from "./admin/components/Drawer";
import EappealDrawer from "./e-appeal/components/Drawer";

import ModulesMenu from "./common/components/ModulesMenu";
import { isEmpty } from "lodash";
import EnvMessage from "./common/components/EnvMessage";
import ResetPassword from "./ResetPassword";

import Unauthorized from "./Unauthorized";
import PrivateRoute from "./common/guard/PrivateRoute";
import LoginModal from "./common/components/LoginModal";
import HRMessage from "./common/components/HRMessage";

import LeaveDrawer from "./leave/components/Drawer";
import DisplineDrawer from "./discipline/components/Drawer";
import PSRP_Redirect from "./common/PSRP_Redirect";
import E_FilesLayout from "./e-files/Layout";

function App(props) {
  const [drawerState, setDrawerState] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const { env, user, getUser, isFetchingUser, selectedModule, getEnv } = props;

  useEffect(() => {
    getEnv();
    getUser(history, location);
    //  getModules();
  }, []);

  useEffect(() => {
    if (
      isEmpty(user) &&
      !isFetchingUser &&
      !location.pathname.includes("/employees/find-employee-details")
    )
      return history.push("/");
  }, []);

  useEffect(() => {
    if (!isEmpty(user)) return history.push("/");
  }, [user]);
  const [recruitmentUrl, setRecruitmentUrl] = useState(
    "https://recruitment.mifotra.gov.rw"
  );
  const [selfServiceUrl, setSelfServiceUrl] = useState(
    "https://selfservice.ippis.rw"
  );

  useEffect(() => {
    if (env === "dev") {
      setRecruitmentUrl("https://dev.recruitment.mifotra.gov.rw");
      setSelfServiceUrl("https://dev.selfservice.ippis.rw");
    } else if (env === "qa") {
      setRecruitmentUrl("https://qa.recruitment.mifotra.gov.rw");
      setSelfServiceUrl("https://qa.selfservice.ippis.rw");
    } else if (env === "uat") {
      setRecruitmentUrl("https://uat.recruitment.mifotra.gov.rw");
      setSelfServiceUrl("https://uat.selfservice.ippis.rw");
    }
  }, [env]);
  // console.log(user);
  return (
    <>
      {isFetchingUser && (
        <div className="loader ">
          <img src={loadingGif} height="128" alt="loading" />
        </div>
      )}
      {!isFetchingUser && (
        <div id="main" style={{ overflowX: "visible" }}>
          <TopProgressBar />

          <ToastifyMessage />
          {env !== "prod" && <EnvMessage env={env} />}
          <OfflineMessage />
          <Header
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            recruitmentUrl={recruitmentUrl}
            selfServiceUrl={selfServiceUrl}
          />

          <HRMessage />

          {(!isEmpty(selectedModule) ||
            location.pathname.startsWith("/documents") ||
            location.pathname.startsWith("/user")) && (
            <div className="">
              <ModulesMenu />
            </div>
          )}

          {!isEmpty(user) && (
            <>
              {location.pathname.startsWith("/structure") && (
                <StructureDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )}

              {location.pathname.startsWith("/employees") && (
                <EmployeesDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )}

              {location.pathname.startsWith("/recruitment") &&
                !!user?.canAccessRecruitment && (
                  <RecruitmentDrawer
                    drawerState={drawerState}
                    setDrawerState={setDrawerState}
                  />
                )}

              {location.pathname.startsWith("/payroll") && (
                <PayrollDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )}

              {location.pathname.startsWith("/rbm") && (
                <RBMDrawer
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                />
              )}
            </>
          )}

          {location.pathname.startsWith("/exit") && (
            <ExitDrawer
              drawerState={drawerState}
              setDrawerState={setDrawerState}
            />
          )}
          {location.pathname.startsWith("/training") &&
            !!user.selectedEntitySector.canUseTrainingModule && (
              <TrainingDrawer
                drawerState={drawerState}
                setDrawerState={setDrawerState}
              />
            )}

          {location.pathname.startsWith("/support") && (
            <SupportDrawer
              drawerState={drawerState}
              setDrawerState={setDrawerState}
            />
          )}

          {location.pathname.startsWith("/admin") && (
            <AdminDrawer
              drawerState={drawerState}
              setDrawerState={setDrawerState}
            />
          )}

          {location.pathname.includes("/e-appeal") && (
            <EappealDrawer
              drawerState={drawerState}
              setDrawerState={setDrawerState}
            />
          )}

          {location.pathname.startsWith("/leave") && (
            <LeaveDrawer
              drawerState={drawerState}
              setDrawerState={setDrawerState}
            />
          )}
          {location.pathname.startsWith("/discipline") && (
            <DisplineDrawer
              drawerState={drawerState}
              setDrawerState={setDrawerState}
            />
          )}

          <Switch>
            <PrivateRoute
              children={<RecruitmentLayout />}
              permissions={["CAN_ACCESS_RECRUITMENT_MODULE"]}
              path="/recruitment"
            />
            <PrivateRoute
              children={<ExitLayout />}
              permissions={["CAN_ACCESS_EXIT_MODULE"]}
              path="/exit"
            />
            <PrivateRoute
              children={<TrainingLayout />}
              permissions={["CAN_ACCESS_TRAING_MODULE"]}
              path="/training"
            />
            <PrivateRoute
              children={<StructureLayout />}
              permissions={["CAN_ACCESS_STRUCTURE_MODULE"]}
              path="/structure"
            />

            <PrivateRoute
              children={<EmployeesLayout />}
              permissions={["CAN_ACCESS_EMPLOYEES_MODULE"]}
              path="/employees"
            />
            <PrivateRoute
              children={<PayrollLayout />}
              permissions={["CAN_ACCESS_PAYROLL_MODULE"]}
              path="/payroll"
            />

            <PrivateRoute
              children={<RBMLayout />}
              permissions={["CAN_ACCESS_RBM_MODULE"]}
              path="/rbm"
            />

            <PrivateRoute
              children={<AdminLayout />}
              permissions={["IS_ADMIN", "IS_NPSC", "IS_RECRUITMENT_ADMIN"]}
              path="/admin"
            />
            <PrivateRoute
              children={<Support />}
              permissions={["IS_ADMIN", "IS_SUPPORTER"]}
              path="/support"
            />
            <PrivateRoute
              children={<EappealLayout />}
              permissions={["CAN_ACCESS_GRIEVANCE_MODULE"]}
              path="/e-appeal"
            />

            <PrivateRoute
              children={<LeaveLayout />}
              permissions={["CAN_ACCESS_LEAVE_MODULE"]}
              path="/leave"
            />
            <PrivateRoute
              children={<DisciplineLayout />}
              permissions={["CAN_ACCESS_DISCIPLINE_MODULE"]}
              path="/discipline"
            />

            <PrivateRoute
              children={<ContractualLayout />}
              permissions={["CAN_ACCESS_CONRACT_MODULE"]}
              path="/contractuals"
            />

            <PrivateRoute
              children={<SkillDashoardLayout />}
              permissions={["CAN_ACCESS_SKILLS_DASHBOARD"]}
              path="/skills-dashboard"
            />

            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/user" component={UserLayout} />
            <Route path="/psrp-portal" component={PSRP_Redirect} />
            <Route path="/documents" component={E_FilesLayout} />

            <Route path="/unauthorized" component={Unauthorized} />
            <Route path="/" component={Home} />
          </Switch>

          <Footer
            recruitmentUrl={recruitmentUrl}
            selfServiceUrl={selfServiceUrl}
          />

          <LoginModal />
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({
  env,
  user,
  loading,
  isFetchingUser,
  selectedModule,
}) => {
  return {
    env,
    user,
    loading,
    isFetchingUser,
    selectedModule,
  };
};
export default connect(mapStateToProps, {
  getEnv,
  getUser,
  getModules,
})(App);
