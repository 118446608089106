import structure from "./action-types";
import rbm from "../rbm/action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";

import commonTypes from "../common/action-types";
import download from "downloadjs";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...structure, ...rbm };

export const countEmployeeDeductions = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_NO_OF_EMPLOYEE_WITHHOLD_REQUESTS,
        data: 0,
      });
      const { data } = await axios.get("/api/payroll/count-deduction-requests");
      dispatch({
        type: types.SET_NO_OF_EMPLOYEE_WITHHOLD_REQUESTS,
        data: data.number,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const confirmEmployeeWithHoldRequest = (
  payroad,
  setConfirmApproveEmployeeWithhold
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/payroll/confirm-deduction-request",
        payroad
      );

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_NO_OF_EMPLOYEE_WITHHOLD_REQUESTS,
        data: data.number,
      });

      dispatch({
        type: types.UPDATE_EMPLOYEE_WITHHOLD_REQUEST,
        data: data.request,
      });

      setConfirmApproveEmployeeWithhold(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeWithholdRequests = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_EMPLOYEE_WITHHOLD_REQUESTS,
        data: [],
      });
      const { data } = await axios.get(
        "/api/payroll/employee-withhold-requests"
      );
      dispatch({
        type: types.SET_EMPLOYEE_WITHHOLD_REQUESTS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doRejectDeductionRequest = (payload, onClose) => {
  return async (dispatch) => {
    dispatch({ type: types.START_WAIT });
    dispatch({ type: types.START_LOADING });
    try {
      const { data } = await axios.put(
        "/api/payroll/reject/employee-withhold-request/" + payload.requestId,
        payload
      );

      dispatch({
        type: types.UPDATE_EMPLOYEE_WITHHOLD_REQUEST,
        data,
      });

      showSuccess("Rejected successfully");
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doApproveDeductionRequest = (
  requestId,
  setConfirmApproveEmployeeWithhold
) => {
  return async (dispatch) => {
    dispatch({ type: types.START_WAIT });
    dispatch({ type: types.START_LOADING });
    try {
      const { data } = await axios.put(
        "/api/payroll/approve/employee-withhold-request/" + requestId,
        {}
      );

      dispatch({
        type: types.UPDATE_EMPLOYEE_WITHHOLD_REQUEST,
        data,
      });

      showSuccess("Approved successfully");
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      setConfirmApproveEmployeeWithhold(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getTempPayrolls = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_TEMP_PAYROLLS,
        data: [],
      });
      const { data } = await axios.get("/api/payroll/temps");
      dispatch({
        type: types.SET_TEMP_PAYROLLS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPayrollDetails = (payrollId, type) => {
  return async (dispatch) => {
    dispatch({ type: types.START_LOADING });
    try {
      dispatch({
        type: types.SET_PAYROLL_DETAILS,
        data: {},
      });

      const { data } = await axios.get(
        "/api/payroll/preview/" + type + "/" + payrollId
      );

      dispatch({
        type: types.SET_PAYROLL_DETAILS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const generatePayroll = (
  payload,
  setShowGeneratePayrollForm,
  history
) => {
  return async (dispatch) => {
    dispatch({ type: types.START_WAIT });
    try {
      const { data } = await axios.post("/api/payroll/generate", payload);

      dispatch({
        type: types.GENERATE_PAYROLL,
        data,
      });

      showSuccess("Payroll generated successfully");

      setShowGeneratePayrollForm(false);
      dispatch({ type: types.END_WAIT });

      history.push("/payroll/temp-payrolls");
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doCancelPayroll = (payload, setShowCancelPayrollForm) => {
  return async (dispatch) => {
    dispatch({ type: types.START_LOADING });
    try {
      const { data } = await axios.post("/api/payroll/canceling", payload);

      dispatch({
        type: types.DELETE_TEMP_PAYROLL,
        id: payload.payrollNumber,
      });

      showSuccess("Canceled successfully");
      dispatch({ type: types.END_LOADING });

      setShowCancelPayrollForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doSignPayroll = (payload, setShowSignPayrollDialog) => {
  return async (dispatch) => {
    dispatch({ type: types.START_WAIT });
    dispatch({ type: types.START_LOADING });
    try {
      const { data } = await axios.post("/api/payroll/sign", payload);

      setShowSignPayrollDialog(false);

      dispatch({
        type: types.UPDATE_PAYROLL_APPROVERS,
        data: { approvals: data.approvals, payrollId: data.payroll.id },
      });

      dispatch({
        type: types.UPDATE_TEMP_PAYROLL,
        data: data.payroll,
      });

      showSuccess("Signed successfully");
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doRejectPayroll = (payload, setShowRejectPayrollDialog) => {
  return async (dispatch) => {
    dispatch({ type: types.START_WAIT });
    dispatch({ type: types.START_LOADING });
    try {
      const { data } = await axios.post("/api/payroll/reject", payload);

      dispatch({
        type: types.UPDATE_TEMP_PAYROLL,
        data,
      });

      showSuccess("Rejected successfully");
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      setShowRejectPayrollDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doPayrollArchive = (payload, setShowDoPayrollArchiveForm) => {
  return async (dispatch) => {
    dispatch({ type: types.START_LOADING });
    try {
      const { data } = await axios.post("/api/payroll/archives", payload);

      dispatch({
        type: types.DELETE_TEMP_PAYROLL,
        id: payload.payrollNumber,
      });

      showSuccess("Archived successfully");
      dispatch({ type: types.END_LOADING });

      setShowDoPayrollArchiveForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doPayrollUnArchive = (payload, setShowUnArchivePayrollDialog) => {
  return async (dispatch) => {
    dispatch({ type: types.START_LOADING });
    try {
      const { data } = await axios.post("/api/payroll/un-archives", payload);

      dispatch({
        type: types.DELETE_ARCHIVED_PAYROLL,
        id: payload.payrollNumber,
      });

      showSuccess("Payroll unarchived successfully");
      dispatch({ type: types.END_LOADING });

      setShowUnArchivePayrollDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doSendToEFMIS = (payrollId, setShowConfirmSendToIFMIS) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/payroll/send-to-ifmis", {
        payrollId: payrollId,
      });

      dispatch({
        type: types.UPDATE_TEMP_PAYROLL,
        data,
      });

      dispatch({ type: types.END_LOADING });

      showSuccess("Payroll sent successfully");
      setShowConfirmSendToIFMIS(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const markPayrollAsPaid = (payrollId, setShowMarkAsPaidDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/payroll/mark-as-paid", {
        payrollId: payrollId,
      });

      dispatch({
        type: types.UPDATE_TEMP_PAYROLL,
        data,
      });

      dispatch({ type: types.END_LOADING });

      setShowMarkAsPaidDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getArchivedPayrolls = (query, setArchivedPayrollLoader) => {
  return async (dispatch) => {
    try {
      setArchivedPayrollLoader(true);

      const { data } = await axios.get(
        "/api/payroll/archives?" + new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_ARCHIVED_PAYROLLS,
        data: data.records,
      });

      dispatch({
        type: types.SET_TOTAL_ARCHIVED_PAYROLLS,
        data: data.totalRecords,
      });

      setArchivedPayrollLoader(false);
    } catch (error) {
      setArchivedPayrollLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadPaySlip = (payrollId, employeeId, onClose) => {
  return async (dispatch) => {
    dispatch({ type: types.START_LOADING });
    try {
      const { data } = await axios.get(
        "/api/payroll-reports/employee-payslip?payrollId=" +
          payrollId +
          "&employeeId=" +
          employeeId,
        { responseType: "blob" }
      );

      download(new Blob([data]), `Payslip-${payrollId}.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
      onClose();
    } catch (error) {
      onClose();
      dispatch({ type: types.END_LOADING });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const attachDocIds = (payrollId, getPayrollDocIds) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/payroll/docids/" + payrollId);

      await getPayrollDocIds();

      dispatch({
        type: types.UPDATE_TEMP_PAYROLL,
        data,
      });

      dispatch({
        type: types.UPDATE_ARCHIVED_PAYROLL,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getActiveCostCenters = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/active-cost-centers");
      dispatch({
        type: types.SET_COST_CENTERS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getActiveEmployeeGroups = (costCenterId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      dispatch({
        type: types.SET_EMPLOYEE_GROUPS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/payroll/active-employee-groups/" + costCenterId
      );

      dispatch({
        type: types.SET_EMPLOYEE_GROUPS,
        data,
      });

      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getActivePayrollGroups = (costCenterId, employeeGroupId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      dispatch({
        type: types.SET_PAYROLL_GROUPS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/payroll/active-payroll-groups/" +
          costCenterId +
          "/" +
          employeeGroupId
      );

      dispatch({
        type: types.SET_PAYROLL_GROUPS,
        data,
      });

      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getActiveSourceOfFunds = (
  costCenterId,
  employeeGroupId,
  payrollGroupId
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      dispatch({
        type: types.SET_SOURCE_FOUNDS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/payroll/active-source-of-funds/" +
          costCenterId +
          "/" +
          employeeGroupId +
          "/" +
          payrollGroupId
      );

      dispatch({
        type: types.SET_SOURCE_FOUNDS,
        data,
      });

      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPayrollTypes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/payroll-types");

      dispatch({
        type: types.SET_PAYROLL_TYPES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntitySectorPayrollTypes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/payroll/entity-sector-payroll-types"
      );

      dispatch({
        type: types.SET_PAYROLL_TYPES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const sendPayrollDeclarations = (
  payrollId,
  type,
  setShowConfirmSendToRSSB
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_RSSB_DECLARATION_ERROR,
        data: "",
      });

      const { data } = await axios.post(
        "/api/payroll/declarations/" + type + "/" + payrollId
      );

      dispatch({
        type: types.UPDATE_TEMP_PAYROLL,
        data,
      });

      dispatch({
        type: types.UPDATE_ARCHIVED_PAYROLL,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      showSuccess("Successfully declared");

      setShowConfirmSendToRSSB(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      showError(error);
    }
  };
};

export const getPayrollDeclarations = (payrollId, type) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_PAYROLL_DECLARATIONS,
        data: {},
      });

      const { data } = await axios.get(
        "/api/payroll/declarations/" + type + "/" + payrollId
      );
      dispatch({
        type: types.SET_PAYROLL_DECLARATIONS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//******************************************************************************//
//******************************************************************************//

export const getApprovalPayrolls = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/payroll/approvals");
      dispatch({
        type: types.SET_APPROVAL_PAYROLLS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// export const getEmployeeGroups = () => {
//   return async (dispatch) => {
//     try {
//       const { data } = await axios.get("/api/lookup/employee-groups");
//       dispatch({
//         type: types.SET_EMPLOYEE_GROUPS,
//         data,
//       });
//     } catch (error) {
// showError(error);
//     }
//   };
// };

// export const getArrearTypes = () => {
//   return async (dispatch) => {
//     try {
//       const { data } = await axios.get("/api/lookup/arrear-types");
//       dispatch({
//         type: types.SET_ARREAR_TYPES,
//         data,
//       });
//     } catch (error) {
// showError(error);
//     }
//   };
// };

export const downloadPDFPayroll = (payrollId, type) => {
  return async (dispatch) => {
    dispatch({ type: types.START_WAIT });
    dispatch({ type: types.START_LOADING });
    try {
      dispatch({
        type: types.PREVIEW_PAYROLL,
        data: null,
      });

      const { data } = await axios.get(
        "/api/payroll-reports/payroll/" + type + "/" + payrollId,
        { responseType: "blob" }
      );

      download(new Blob([data]), `Payroll-${payrollId}.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const downloadPDFBankTransfer = (payrollId, type, handleCloseMenu) => {
  return async (dispatch) => {
    dispatch({ type: types.START_WAIT });
    dispatch({ type: types.START_LOADING });
    try {
      dispatch({
        type: types.PREVIEW_PAYROLL,
        data: null,
      });

      const { data } = await axios.get(
        "/api/payroll-reports/bank-transfer/" + type + "/" + payrollId,
        { responseType: "blob" }
      );

      download(new Blob([data]), `Bank-Transfers-${payrollId}.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      handleCloseMenu();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const downloadPDFDeductions = (payrollId, type, handleCloseMenu) => {
  return async (dispatch) => {
    dispatch({ type: types.START_WAIT });
    dispatch({ type: types.START_LOADING });
    try {
      dispatch({
        type: types.PREVIEW_PAYROLL,
        data: null,
      });

      const { data } = await axios.get(
        "/api/payroll-reports/deductions/" + type + "/" + payrollId,
        { responseType: "blob" }
      );

      download(new Blob([data]), `Deductions-${payrollId}.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      handleCloseMenu();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const downloadEmployeeDeductionsReport = (
  employeeId,
  handleCloseMenu
) => {
  return async (dispatch) => {
    dispatch({ type: types.START_WAIT });
    dispatch({ type: types.START_LOADING });
    try {
      const { data } = await axios.get(
        "/api/payroll-reports/employee-deductions/" + employeeId,
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `Employee-Deductions-${employeeId}.pdf`,
        ".pdf"
      );

      handleCloseMenu();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const getAllowances = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/allowances");
      dispatch({
        type: types.SET_ALLOWANCES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSystemAllowances = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/system-allowances");
      dispatch({
        type: types.SET_SYSTEM_ALLOWANCES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCreditors = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/creditors");
      dispatch({
        type: types.SET_CREDITORS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSourceOfFunds = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SOURCE_FOUNDS,
        data: [],
      });

      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/payroll/source-of-funds");

      // dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_SOURCE_FOUNDS,
        data,
      });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPayrollGroups = (entitySectorId = "") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_PAYROLL_GROUPS,
        data: [],
      });
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/payroll/payroll-groups/" + entitySectorId
      );

      dispatch({
        type: types.SET_PAYROLL_GROUPS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCalculationMethods = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/payroll/calculation-methods");

      dispatch({
        type: types.SET_CALCULATION_METHODES,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getBasePayrollComponents = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/payroll/base-payroll-components");

      dispatch({
        type: types.SET_BASE_PAYROLL_COMPONENTS,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCostCenters = (entitySectorId = "") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_COST_CENTERS,
        data: [],
      });
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/payroll/cost-centers/" + entitySectorId
      );

      dispatch({
        type: types.SET_COST_CENTERS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPayHistories = (employeeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_PAY_HISTORIES,
        data: [],
      });
      const { data } = await axios.get(
        "/api/payroll/pay-histories/" + employeeId
      );
      dispatch({
        type: types.SET_PAY_HISTORIES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadPayHistory = (
  employeeId,
  setShowDownloadPayHistory,
  query = ""
) => {
  return async (dispatch) => {
    try {
      // console.log(query);
      dispatch({ type: types.START_LOADING });
      setShowDownloadPayHistory(true);
      const { data } = await axios.get(
        "/api/payroll-reports/employee-pay-history/" + employeeId + query,
        { responseType: "blob" }
      );

      download(new Blob([data]), `Pay-History-${employeeId}.pdf`, ".pdf");

      dispatch({ type: types.END_LOADING });
      setShowDownloadPayHistory(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowDownloadPayHistory(false);
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const getActiveCurrencies = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/currencies");
      dispatch({
        type: types.SET_CURRENCIES,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPayrollDashboardData = (year, isForOversight = false) => {
  return async (dispatch) => {
    dispatch({ type: types.START_LOADING });
    try {
      dispatch({
        type: types.SET_PAYROLL_DASHBOARD,
        data: {},
      });

      const { data } = await axios.get(
        `/api/payroll/dashboard-data/${year}/${isForOversight}`
      );

      dispatch({
        type: types.SET_PAYROLL_DASHBOARD,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSupportingDocumentTypes = () => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/payroll/supporting-document-types"
      );
      dispatch({
        type: types.SET_SUPPORTING_DOCUMENT_TYPES,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPayrollSupportingDocuments = (
  payrollId,
  type,
  handleCloseMenu,
  setShowSupportingDocsDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_PAYROLL_SUPPORTING_DOCUMENTS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/payroll/payroll-supporting-documents/" + type + "/" + payrollId
      );
      dispatch({
        type: types.SET_PAYROLL_SUPPORTING_DOCUMENTS,
        data,
      });

      dispatch({ type: types.END_LOADING });

      handleCloseMenu();
      setShowSupportingDocsDialog(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const savePayrollSupportingDocument = (
  document,
  setShowAddPayrollSupportingDocsDialog,
  setSupportingDocumentType,
  setDescription,
  setFile
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/payroll/payroll-supporting-documents",
        document
      );
      dispatch({
        type: types.ADD_NEW_PAYROLL_SUPPORTING_DOCUMENT,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      setShowAddPayrollSupportingDocsDialog(false);
      setSupportingDocumentType(null);
      setDescription("");
      setFile(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removePayrollSupportingDocument = (
  id,
  setConfirmRemoveSupportingDocument
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/payroll/payroll-supporting-documents/" + id);
      dispatch({
        type: types.REMOVE_PAYROLL_SUPPORTING_DOCUMENT,
        id,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      setConfirmRemoveSupportingDocument(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPerformanceBonusScores = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_PERFORMANCE_BONUS_SCORES,
        data: [],
      });

      const { data } = await axios.get("/api/payroll/performance-bonus-scores");

      dispatch({
        type: types.SET_PERFORMANCE_BONUS_SCORES,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const loadRBMScores = (entityId, fiscalYearId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_PERFORMANCE_BONUS_SCORES,
        data: [],
      });

      if (fiscalYearId === "2223") {
        await axios.post(
          "https://ippis.mifotra.gov.rw/api/rbm/insert-rbm-scores/" +
            entityId +
            "/" +
            fiscalYearId
        );
      } else {
        await axios.post("/api/rbm/insert-rbm-score");
      }

      const { data } = await axios.get("/api/payroll/performance-bonus-scores");

      dispatch({
        type: types.SET_PERFORMANCE_BONUS_SCORES,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeAnnualSalaries = (
  employeeId,
  oldEmployeeId,
  setShowAddToBonusDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_EMPLOYEE_ANNUAL_SALARIES,
        data: [],
      });

      const { data } = await axios.get(
        "/api/payroll/employee-annual-salaries/" +
          employeeId +
          "/" +
          oldEmployeeId || 0
      );

      dispatch({
        type: types.SET_EMPLOYEE_ANNUAL_SALARIES,
        data: data,
      });

      setShowAddToBonusDialog(true);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addEmployeeToBonus = (
  payload,
  oldEmployeeId,
  ignoreSixMonths,
  setShowDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/payroll/add-employee-to-bonus", {
        payload,
        oldEmployeeId,
        ignoreSixMonths,
      });

      dispatch({
        type: types.UPDATE_PERFORMANCE_BONUS_SCORE,
        data: data,
      });

      setShowDialog(false);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const reomeEmployeeFromBonus = (payload, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/payroll/remove-employee-from-bonus",
        payload
      );

      dispatch({
        type: types.SET_PERFORMANCE_BONUS_SCORES,
        data: data,
      });

      setShowDialog(false);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadRBMScoreTemplate = (entitySectorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/payroll/download-rbn-score-template/${entitySectorId}`,
        {
          responseType: "blob",
        }
      );

      download(new Blob([data]), `RBM Score Template.xlsx`, ".xlsx");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const getRBMScoreTemplate = (cb, entitySectorId) => {
  return async (dispatch) => {
    cb(true, []);
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/payroll/get-rbn-score-template/${entitySectorId}`
      );

      cb(false, data);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      cb(false, []);
      showError(error);
    }
  };
};

export const addRBMTemplateScores = (data, cb) => {
  return async (dispatch) => {
    cb(true, false, null);
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/payroll/add-rbm-template-score", { scores: data });

      cb(false, true, null);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      cb(false, false, error.response.data);
      showError(error);
    }
  };
};

export const getPBFIndexGroup = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/payroll/pbf-index-groups");

      dispatch({
        type: types.SET_PBF_INDEX_GROUP,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeePBFSettings = (employeePositionId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/payroll/employee-pbf/" + employeePositionId
      );

      dispatch({
        type: types.SET_EMPLOYEE_PBF_SETTINGS,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addEmployeePBFSettings = (formData, cb) => {
  return async (dispatch) => {
    cb(true, false);
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/payroll/employee-pbf", formData);

      dispatch({
        type: types.ADD_EMPLOYEE_PBF_SETTING,
        data: data.employeePbf,
      });

      cb(false, true);
      showSuccess(data.message);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      cb(false, false);

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateEmployeePBFSettings = (formData, cb) => {
  return async (dispatch) => {
    cb(true, false);
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        `/api/payroll/employee-pbf/${formData.oldPeriodId}`,
        { ...formData, oldPeriodId: undefined }
      );

      dispatch({
        type: types.UPDATE_EMPLOYEE_PBF_SETTING,
        data: {
          employeePbf: data.employeePbf,
          oldPeriodId: formData.oldPeriodId,
        },
      });

      cb(false, true);
      showSuccess(data.message);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      cb(false, false);

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removeEmployeePBFSettings = (periodId, employeeId, cb) => {
  return async (dispatch) => {
    try {
      cb(true, false);
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/payroll/employee-pbf/" + periodId + "/" + employeeId
      );

      dispatch({
        type: types.REMOVE_EMPLOYEE_PBF_SETTING,
        data: { periodId, employeeId },
      });

      showSuccess(data.message);

      cb(false, true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      cb(false, false);
      showError(error);
    }
  };
};

export const downloadBarometerData = ({
  sectorId,
  periodId,
  employeeGroupId,
}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/payroll/download-barometer-data/${sectorId}/${employeeGroupId}/${periodId}`,
        {
          responseType: "blob",
        }
      );

      download(
        new Blob([data]),
        `BAROMETER DATA ${sectorId}-${employeeGroupId}-${periodId}.xlsx`,
        ".xlsx"
      );

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const getPayrollPeriods = (cb) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/payroll/periods");

      cb(data);

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getChangeReasons = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/payroll/change-reasons");

      dispatch({
        type: types.SET_CHANGE_REASONS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getIndividualSupportingDocuments = (type, payrollChangeListId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_INDIVIDUAL_SUPPORTING_DOCUMENTS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/payroll/individual-supporting-documents/" +
          type +
          "/" +
          payrollChangeListId
      );

      dispatch({
        type: types.SET_INDIVIDUAL_SUPPORTING_DOCUMENTS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveIndividualSupportingDocument = (document, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/payroll/individual-supporting-documents",
        document
      );

      !!cb && cb();

      dispatch({
        type: types.ADD_NEW_INDIVIDUAL_SUPPORTING_DOCUMENT,
        data: data.supportingDocument,
      });

      dispatch({
        type: types.UPDATE_PAYROLL_CHANGE_LIST,
        data: data.changeList,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removeIndividualSupportingDocument = (id, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/payroll/individual-supporting-documents/" + id
      );

      setShowDialog(false);

      dispatch({
        type: types.REMOVE_INDIVIDUAL_SUPPORTING_DOCUMENT,
        id,
      });

      dispatch({
        type: types.UPDATE_PAYROLL_CHANGE_LIST,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateCurrencyRate = (formData, cb) => {
  return async (dispatch) => {
    cb(true, false);
    try {
      const { data } = await axios.put("/api/payroll/currency-rate", formData);
      dispatch({
        type: types.UPDATE_CURRENCY,
        data,
      });
      cb(false, true);
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      cb(false, false);
      showError(error);
    }
  };
};

export const resendToBRD = (payrollNumber, closeDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });
      await axios.post("/api/payroll/resend-to-brd", { payrollNumber });
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      closeDialog();

      showSuccess("BRD payements successfully sent");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateTinNumber = ({ query, payload }, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        `/api/payroll/tin-number${
          payload.type === "Temp"
            ? ""
            : "?" + new URLSearchParams(query).toString()
        }`,
        payload
      );

      if (payload.type === "Temp")
        dispatch({
          type: types.SET_TEMP_PAYROLLS,
          data: data,
        });
      else {
        dispatch({
          type: types.SET_ARCHIVED_PAYROLLS,
          data: data.records,
        });

        dispatch({
          type: types.SET_TOTAL_ARCHIVED_PAYROLLS,
          data: data.totalRecords,
        });
      }

      dispatch({ type: types.END_LOADING });
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};


export const getEmployeeRBMScore = (employeeId, cb) => {
  return async (dispatch) => {
    cb(true, null);
    try {
      console.log({ employeeId });
      const { data } = await axios.get(
        `/api/payroll/employee-rbm-score/${employeeId}`
      );
      cb(false, data);
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      cb(false, null);
    }
  };
};

export const removeEmployeeRBMScoreDuplicate = (formData, cb) => {
  return async (dispatch) => {
    try {
      cb(true, false);
      const { data } = await axios.delete(
        `/api/payroll/remove-duplicate-rbm-score/${formData.employeeId}/${formData.rbmScoreId}`
      );
      showSuccess(data);
      cb(false, true);
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      cb(false, false);
    }
  };
};