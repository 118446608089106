import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Typography,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Tooltip,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";
import ReportingCard from "./ContractReportingCard";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  downloadEmployeeContractReport,
  getCompetencies,
  getContractCompetencyEvaluations,
  getContractItems,
  publishContractCompetencies,
  removeContractPendingAssignment,
  requestCompetencyReEvaluation,
  requestContractCancel,
  submitContractCompetency,
} from "../../../store/rbm/actions";
import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import ContractQuarterCard from "./ContractQuarterCard";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { generateProbationData } from "../../common/components/Utils";

const ContractDetailsDialog = ({
  selectedSubordinate,
  open,
  onClose,
  loading,
  contractItems,
  getContractItems,
  isForMonitoring,
  downloadEmployeeContractReport,
  rbmCompetencies,
  getCompetencies,
  contractCompetencyEvaluations,
  getContractCompetencyEvaluations,
  submitContractCompetency,
  publishContractCompetencies,
  removeContractPendingAssignment,
  requestCompetencyReEvaluation,
  requestContractCancel,
}) => {
  useEffect(() => {
    if (!!open) {
      getContractItems(selectedSubordinate.contractId);
    }
  }, [open]);

  const [value, setValue] = useState(0);
  const [selectedCompetency, setSelectedCompetency] = useState(null);
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  const [canPublishEvaluation, setCanPublishEvaluation] = useState(false);
  const [errors, setErrors] = useState({
    rankHasError: false,
    rankErrorMessage: "",
  });
  const [loadingCompetencies, setLoadingCompetencies] = useState(false);
  const [
    loadingContractCompetencyEvaluations,
    setLoadingContractCompetencyEvaluations,
  ] = useState(false);
  const [
    selectedContractCompetencyEvaluations,
    setSelectedContractCompetencyEvaluations,
  ] = useState([]);
  const [submittingContractCompetency, setSubmitting] = useState(false);
  const [requestingReEvaluation, setRequestingReEvaluation] = useState(false);
  const [confirmingContractCompetency, setConfirmingContractCompetency] =
    useState(false);
  const [requestReEvaluation, setRequestReEvaluation] = useState(false);
  const [
    confirmingRemovePendingAssignments,
    setConfirmingRemovePendingAssignments,
  ] = useState(false);
  const [confirmingCancelContract, setConfirmingCancelContract] =
    useState(false);
  const [finalContractItems, setFinalContractItems] = useState([]);

  useEffect(() => {
    if (!!open && !!contractItems[0]) {
      if ([3, 4].includes(selectedSubordinate.contractTypeId)) {
        setFinalContractItems(generateProbationData(contractItems));
      } else {
        setFinalContractItems(contractItems);
      }
    }
  }, [open, contractItems]);

  useEffect(() => {
    if (!rbmCompetencies?.length && value === 1) {
      getCompetencies(setLoadingCompetencies);
      if (
        !contractCompetencyEvaluations?.filter(
          (item) => item.contractId === selectedSubordinate.contractId
        ).length
      ) {
        getContractCompetencyEvaluations(
          selectedSubordinate.contractId,
          setLoadingContractCompetencyEvaluations
        );
      }
    }
  }, [value]);

  useEffect(() => {
    setSelectedCompetencies(
      rbmCompetencies?.filter((item) =>
        selectedSubordinate.isHeadOfUnit
          ? item.isForSupervisor
          : item.isForSupervisee
      )
    );
  }, [rbmCompetencies]);

  useEffect(() => {
    setSelectedContractCompetencyEvaluations(
      contractCompetencyEvaluations?.filter(
        (item) => item.contractId === selectedSubordinate.contractId
      )
    );
  }, [contractCompetencyEvaluations]);

  useEffect(() => {
    if (
      !!selectedContractCompetencyEvaluations?.length &&
      !!selectedCompetencies?.length
    ) {
      const totalDescriptors = selectedCompetencies.reduce((prev, current) => {
        return prev + current.descriptors.length;
      }, 0);
      const totalEvaluations = selectedContractCompetencyEvaluations.length;
      setCanPublishEvaluation(
        totalDescriptors === totalEvaluations &&
          !selectedSubordinate.competencyScore
      );
    }
  }, [selectedContractCompetencyEvaluations, selectedCompetencies]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmitCompetency = () => {
    if (!selectedCompetency.quality) {
      setErrors({
        rankHasError: true,
        rankErrorMessage: "Please select ranking quality",
      });
      return;
    }

    submitContractCompetency(
      {
        contractId: selectedSubordinate.contractId,
        descriptorId: selectedCompetency.id,
        weight: selectedCompetency.score,
        score: selectedCompetency.quality,
      },
      (status, res) => {
        setSubmitting(status);
        if (!status && res) {
          setSelectedCompetency(null);
        }
      }
    );
  };

  const handlePublishingCompetency = () => {
    publishContractCompetencies(
      selectedSubordinate.contractId,
      (status, res) => {
        setSubmitting(status);
        if (!status && res) {
          setSelectedCompetency(null);
          setConfirmingContractCompetency(false);
          setRequestingReEvaluation(false);
          setCanPublishEvaluation(false);
        }
      }
    );
  };

  const handleRequestReEvaluation = () => {
    requestCompetencyReEvaluation(
      selectedSubordinate.contractId,
      (status, res) => {
        setRequestingReEvaluation(status);
        if (!status && res) {
          setRequestReEvaluation(false);
          setConfirmingContractCompetency(false);
          setCanPublishEvaluation(true);
        }
      }
    );
  };

  const handleRemoveAssignments = () => {
    removeContractPendingAssignment(
      selectedSubordinate.contractId,
      (status, res) => {
        setConfirmingRemovePendingAssignments(status);
        if (!status && res) {
          setConfirmingRemovePendingAssignments(false);
        }
      }
    );
  };

  const handleCancelContract = () => {
    requestContractCancel(selectedSubordinate.contractId, (status, res) => {
      setConfirmingRemovePendingAssignments(status);
      if (!status && res) {
        setConfirmingCancelContract(false);
      }
    });
  };

  const getCompetencyEvaluationSummary = (competencyId) => {
    const result = { score: 0 };
    const competency = selectedCompetencies.find(
      (item) => item.id === competencyId
    );
    const descriptorIds = competency.descriptors.map((item) => item.id);

    const evaluations = selectedContractCompetencyEvaluations.filter((item) =>
      descriptorIds.includes(item.competencyDescriptorId)
    );

    const totalEvaluationScore = evaluations.reduce(
      (prev, current) => {
        return {
          score: prev.score + current.score * current.weight,
          weight: prev.weight + current.weight,
        };
      },
      { score: 0, weight: 0 }
    );

    result.score =
      ((totalEvaluationScore.score / totalEvaluationScore.weight || 0) *
        evaluations.length) /
      descriptorIds.length;
    return result;
  };

  const totalActivities = () =>
    !!finalContractItems[0]
      ? (finalContractItems[0]?.activities || []).length +
        (finalContractItems[1]?.activities || []).length +
        (finalContractItems[2]?.activities || []).length +
        (finalContractItems[3]?.activities || []).length
      : 0;

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography
            component="div"
            className="d-flex align-items-center justify-content-start pl-2 pr-3 text-uppercase"
          >
            <span className="text-dark mr-1">Performance contract </span>
            {selectedSubordinate.isActive !== undefined &&
              !selectedSubordinate.isActive && (
                <span className="badge badge-warning">Canceled</span>
              )}{" "}
            <ArrowRightIcon className="text-primary" />
            {selectedSubordinate.names}
            {/* <RBMPageHeader
              labels={[]}
              progress={{
                title: (
                  <>
                    Total score |{" "}
                    <span className="text-primary ml-1">
                      {selectedSubordinate.names}
                    </span>{" "}
                  </>
                ),
                value: selectedSubordinate.score || 0,
              }}
              buttons={[]}
              loading={loading}
              noShadow={true}
            /> */}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="row">
            <div className="col-sm-12 mb-3 d-flex justify-content-center">
              <Tabs scrollButtons="auto" value={value} onChange={handleChange}>
                <Tab
                  // icon={<span className="material-icons mr-1">checklist</span>}
                  label={
                    <>
                      Employee Assignments (70%)
                      <span
                        className={`badge px-2 badge-${
                          (selectedSubordinate?.activityScore || 0) < 50
                            ? "danger"
                            : (selectedSubordinate?.activityScore || 0) < 70
                            ? "warning"
                            : (selectedSubordinate?.activityScore || 0) < 100
                            ? "info"
                            : "success"
                        }`}
                      >
                        {(selectedSubordinate?.activityScore || 0).toFixed(2)}
                        /70
                      </span>
                    </>
                  }
                  {...a11yProps(0)}
                />

                <Tab
                  disabled={
                    isForMonitoring ||
                    !selectedSubordinate?.contractId ||
                    !selectedSubordinate?.numOfActivities
                  }
                  // icon={<span className="material-icons mr-1">menu</span>}
                  label={
                    <>
                      Employee Competencies (30%){" "}
                      {!!(selectedSubordinate?.competencyTotalScore || 0) && (
                        <span
                          className={`badge px-2 badge-${
                            (selectedSubordinate?.competencyTotalScore || 0) <
                            50
                              ? "danger"
                              : (selectedSubordinate?.competencyTotalScore ||
                                  0) < 70
                              ? "warning"
                              : (selectedSubordinate?.competencyTotalScore ||
                                  0) < 80
                              ? "info"
                              : "success"
                          }`}
                        >
                          {(selectedSubordinate?.competencyScore || 0).toFixed(
                            2
                          )}
                          /30
                        </span>
                      )}
                      {!(selectedSubordinate?.competencyScore || 0) && (
                        <span className="badge px-2 badge-secondary">
                          Not Evaluated
                        </span>
                      )}
                    </>
                  }
                  {...a11yProps(2)}
                />
              </Tabs>
            </div>
          </div>
        </DialogTitle>

        <DialogContent className="mx-4 px-2">
          <div
            className="px-3"
            style={{
              minHeight: "60vh",
              // border: "1px solid #17a2b8",
            }}
          >
            <TabPanel value={value} index={0}>
              <ReportingCard
                title={`${selectedSubordinate.numOfActivities || 0} Items`}
                items={selectedSubordinate.numOfActivities}
                overdue={
                  selectedSubordinate.dueActivities ||
                  selectedSubordinate.overdueActivities ||
                  0
                }
                rejected={selectedSubordinate.rejectedActivities}
                reported={selectedSubordinate.reportedActivities}
                scored={selectedSubordinate.scoredActivities}
                pending={selectedSubordinate.ontrackActivities}
                progress={selectedSubordinate.totalScore}
                onDownload={() =>
                  downloadEmployeeContractReport(selectedSubordinate.contractId)
                }
                onDelete={() => setConfirmingRemovePendingAssignments(true)}
                onCancel={() => setConfirmingCancelContract(true)}
                canCancel={
                  selectedSubordinate.isActive &&
                  selectedSubordinate.competencyTotalScore === 0
                }
                isActive={selectedSubordinate.isActive}
                isForMonitoring={isForMonitoring}
              >
                {finalContractItems.map((quarter) => (
                  <ContractQuarterCard
                    totalWeight={0}
                    quarter={quarter}
                    key={quarter.quarterId}
                    selectedSubordinate={selectedSubordinate}
                    isForMonitoring={isForMonitoring}
                    contractTypeId={selectedSubordinate.contractTypeId}
                    contractTypeName={selectedSubordinate.contractTypeName}
                  />
                ))}
                {loading && totalActivities() === 0 && (
                  <>
                    <Skeleton
                      variant="rectangular"
                      className="mb-2 mt-3"
                      height={96}
                    />
                    <Skeleton
                      variant="rectangular"
                      className="mb-2"
                      height={96}
                    />
                    <Skeleton
                      variant="rectangular"
                      className="mb-2"
                      height={96}
                    />
                  </>
                )}

                {!loading && totalActivities() === 0 && (
                  <div className="jumbotron jumbotron-fluid text-center">
                    <p className="lead">Empty contract</p>
                  </div>
                )}
              </ReportingCard>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <React.Fragment>
                {!!selectedCompetencies.length &&
                  selectedSubordinate?.numOfActivities !==
                    selectedSubordinate?.scoredActivities && (
                    <Alert
                      severity="warning"
                      variant="outlined"
                      className="mb-2"
                    >
                      Competencies can only be assessed once all activities have
                      been evaluated.
                    </Alert>
                  )}
                <div className="card">
                  <div className="card-body px-3 py-4 bg-light">
                    <div className="row">
                      {selectedCompetencies.map((competency) => {
                        const competencyEvaluation =
                          getCompetencyEvaluationSummary(competency.id);
                        return (
                          <div
                            className="col-12 col-md-3 text-left"
                            key={competency.id}
                          >
                            <div className="card" style={{ minHeight: "100%" }}>
                              <div className="card-header text-primary font-weight-bold d-flex align-items-center">
                                <Tooltip title="Quarter Progress">
                                  <Box
                                    sx={{
                                      position: "relative",
                                      display: "inline-flex",
                                      cursor: "default",
                                    }}
                                  >
                                    <CircularProgress
                                      className={`text-${
                                        competencyEvaluation.score < 50
                                          ? "danger"
                                          : competencyEvaluation.score < 70
                                          ? "warning"
                                          : competencyEvaluation.score < 100
                                          ? "info"
                                          : "success"
                                      }`}
                                      variant="determinate"
                                      value={competencyEvaluation.score}
                                    />
                                    <Box
                                      sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography
                                        variant="caption"
                                        component="div"
                                        style={{ fontSize: "7px" }}
                                        color="text.secondary"
                                        className={`text-${
                                          competencyEvaluation.score < 50
                                            ? "danger"
                                            : competencyEvaluation.score < 70
                                            ? "warning"
                                            : competencyEvaluation.score < 100
                                            ? "info"
                                            : "success"
                                        }`}
                                      >
                                        {`${competencyEvaluation.score.toFixed(
                                          2
                                        )}%`}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Tooltip>

                                <span className="ml-2">{competency.name}</span>
                              </div>
                              <div className="card-body">
                                {competency.descriptors.map((item) => {
                                  const isEvaluated =
                                    selectedContractCompetencyEvaluations.find(
                                      (evaluationItem) =>
                                        evaluationItem.competencyDescriptorId ===
                                        item.id
                                    );
                                  return (
                                    <div
                                      className="d-flex align-items-center mb-2"
                                      key={item.id}
                                    >
                                      <span
                                        className={`material-icons-round mr-2 ${
                                          selectedSubordinate?.numOfActivities !==
                                          selectedSubordinate?.scoredActivities
                                            ? "text-light"
                                            : !!isEvaluated
                                            ? "text-primary"
                                            : "text-secondary"
                                        } ${
                                          selectedSubordinate?.competencyScore >
                                            0 ||
                                          selectedSubordinate?.numOfActivities !==
                                            selectedSubordinate?.scoredActivities
                                            ? ""
                                            : "cursor-pointer"
                                        }`}
                                        style={{ minWidth: "35px" }}
                                        onClick={() => {
                                          if (
                                            selectedSubordinate?.competencyScore >
                                              0 ||
                                            selectedSubordinate?.numOfActivities !==
                                              selectedSubordinate?.scoredActivities
                                          ) {
                                            return;
                                          }
                                          setSelectedCompetency({
                                            ...item,
                                            competencyId: competency.id,
                                            competencyName: competency.name,
                                            quality: !!isEvaluated
                                              ? isEvaluated.score / 100
                                              : null,
                                          });
                                        }}
                                      >
                                        {!!isEvaluated
                                          ? "check_box"
                                          : "check_box_outline_blank"}
                                      </span>
                                      <span className="text-left text-sm">
                                        {item.name}{" "}
                                        {!!isEvaluated && (
                                          <small
                                            className={`badge badge-${
                                              isEvaluated.score === 95
                                                ? "success"
                                                : isEvaluated.score === 85
                                                ? "primary"
                                                : isEvaluated.score === 75
                                                ? "info"
                                                : isEvaluated.score === 60
                                                ? "warning"
                                                : "danger"
                                            }`}
                                            style={{ width: "40px" }}
                                          >
                                            x
                                            {(isEvaluated.score / 100).toFixed(
                                              2
                                            )}
                                          </small>
                                        )}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {(loadingCompetencies ||
                      loadingContractCompetencyEvaluations) && (
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <Skeleton variant="rectangular" height={450} />
                        </div>
                        <div className="col-12 col-md-3">
                          <Skeleton variant="rectangular" height={450} />
                        </div>
                        <div className="col-12 col-md-3">
                          <Skeleton variant="rectangular" height={450} />
                        </div>
                        <div className="col-12 col-md-3">
                          <Skeleton variant="rectangular" height={450} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            </TabPanel>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <TabPanel value={value} index={1}>
            <Button
              disabled={loading || !canPublishEvaluation}
              color="primary"
              variant="contained"
              onClick={() => setConfirmingContractCompetency(true)}
              className="ml-2 px-5"
            >
              {loading ? "Wait..." : "Confirm competency evaluation"}
            </Button>
            {!canPublishEvaluation && (
              <Button
                disabled={loading || requestingReEvaluation}
                color="warning"
                variant="contained"
                onClick={() => setRequestReEvaluation(true)}
                className="ml-2 px-5"
              >
                {loading || requestingReEvaluation
                  ? "Wait..."
                  : "Request for re-evaluation"}
              </Button>
            )}
          </TabPanel>
        </DialogActions>
      </Dialog>

      {!!selectedCompetency && (
        <Dialog
          onClose={() => setSelectedCompetency(null)}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            <Typography className="text-uppercase">
              <small>
                Scoring{" "}
                <span className="text-primary">{selectedCompetency.name}</span>{" "}
              </small>
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => setSelectedCompetency(null)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="py-3 px-3">
              <FormControl fullWidth size="small" className="mb-3">
                <InputLabel id="demo-simple-select-label">
                  Rank the quality of competency
                </InputLabel>
                <Select
                  error={errors.rankHasError}
                  size="small"
                  value={selectedCompetency.quality || ""}
                  label="Rank the quality of reported work"
                  onChange={(e) => {
                    setErrors({
                      rankHasError: false,
                      rankErrorMessage: "",
                    });

                    setSelectedCompetency({
                      ...selectedCompetency,
                      quality: e.target.value,
                    });
                  }}
                  disabled={loading || submittingContractCompetency}
                >
                  <MenuItem value={0.95}>Excellent(x0.95)</MenuItem>
                  <MenuItem value={0.85}>Very Good(x0.85)</MenuItem>
                  <MenuItem value={0.75}>Good(x0.75)</MenuItem>
                  <MenuItem value={0.6}>Fair(x0.60)</MenuItem>
                  <MenuItem value={0.4}>Poor(x0.40)</MenuItem>
                </Select>
                <small
                  className="text-danger ml-2"
                  style={{ fontSize: "12px" }}
                >
                  {errors.rankErrorMessage}
                </small>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions className="d-flex justify-content-center pb-4">
            <Button
              disabled={loading || submittingContractCompetency}
              color="primary"
              variant="contained"
              onClick={handleSubmitCompetency}
              className="ml-2 px-5"
            >
              {loading ? "Wait..." : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {(confirmingContractCompetency || requestReEvaluation) && (
        <ConfirmationDialog
          confirmationDialog={
            confirmingContractCompetency || requestReEvaluation
          }
          message={
            requestReEvaluation
              ? `Are you sure you want to request for competency re-evaluation?`
              : `Are you sure you want to confirm competency? Once is approved, it can't be changed.`
          }
          setConfirmationDialog={() => {
            setConfirmingContractCompetency(false);
            setRequestReEvaluation(false);
          }}
          onYes={() => {
            if (requestReEvaluation) {
              handleRequestReEvaluation();
            } else {
              handlePublishingCompetency();
            }
          }}
          disabled={loading || submittingContractCompetency}
        />
      )}

      {confirmingRemovePendingAssignments && (
        <ConfirmationDialog
          confirmationDialog={confirmingRemovePendingAssignments}
          message={`Are you sure you want to remove all pending assignments?`}
          setConfirmationDialog={setConfirmingRemovePendingAssignments}
          onYes={handleRemoveAssignments}
          disabled={loading || submittingContractCompetency}
        />
      )}

      {confirmingCancelContract && (
        <ConfirmationDialog
          confirmationDialog={confirmingCancelContract}
          message={`Are you sure you want to cancel this contract?`}
          setConfirmationDialog={setConfirmingCancelContract}
          onYes={handleCancelContract}
          disabled={loading || submittingContractCompetency}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  contractItems,
  rbmCompetencies,
  contractCompetencyEvaluations,
}) => {
  return {
    loading,
    contractItems,
    rbmCompetencies,
    contractCompetencyEvaluations,
  };
};
export default connect(mapStateToProps, {
  getContractItems,
  downloadEmployeeContractReport,
  getCompetencies,
  getContractCompetencyEvaluations,
  submitContractCompetency,
  publishContractCompetencies,
  removeContractPendingAssignment,
  requestCompetencyReEvaluation,
  requestContractCancel,
})(ContractDetailsDialog);
