import recruitment from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";

import commonTypes from "../common/action-types";
import { isSessionExpired } from "../common/actions";
import download from "downloadjs";

const types = { ...commonTypes, ...recruitment };

export const getAnalytics = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/recruitment/analytics");
      dispatch({
        type: types.SET_ANALYTICS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getJobPublications = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/recruitment/job-publications");
      dispatch({
        type: types.SET_JOB_PUBLICATIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantJobPublications = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/applicant/job-publications"
      );
      dispatch({
        type: types.SET_APPLICANT_JOB_PUBLICATIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getListApplicantsByJobPublications = (jobPublicationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/list-applicants/" + jobPublicationId
      );

      dispatch({
        type: types.SET_APPLICANTS,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantExamMarking = (jobPublicationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/applicant-exam-marking/" + jobPublicationId
      );

      dispatch({
        type: types.SET_APPLICANT_EXAM_MARKING,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantOralExamMarking = (jobPublicationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/applicant-oral-exam-marking/" + jobPublicationId
      );

      dispatch({
        type: types.SET_APPLICANT_ORAL_EXAM_MARKING,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantOverallResults = (
  advertisementId,
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      // dispatch({
      //   type: types.SET_APPLICANT_OVERALL_RESULTS,
      //   data: [],
      // });

      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/recruitment/applicant-overall-results/" +
          advertisementId +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_APPLICANT_OVERALL_RESULTS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);
      setLoading(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const requestJobOffer = (params, setConfirmToRequestJobOffers) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/recruitment/request-job-offers",
        params
      );

      dispatch({
        type: types.UPDATE_APPLICANT_OVERALL_RESULT,
        data: data,
      });

      showSuccess("Job offer requested successfully");

      dispatch({ type: types.END_LOADING });
      setConfirmToRequestJobOffers(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const skipCandidate = (
  params,
  setReason1,
  setConfirmToSkipCandidate
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/recruitment/skip-candidate-to-job-offer",
        params
      );

      dispatch({
        type: types.UPDATE_APPLICANT_OVERALL_RESULT,
        data: data,
      });

      showSuccess("Candidate skipped successfully");
      setReason1("");
      dispatch({ type: types.END_LOADING });
      setConfirmToSkipCandidate(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//cancelJobOffer

export const cancelJobOffer = (
  jobOfferId,
  reason,
  setReason,
  setConfirmToCancelJobOffer
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        "/api/recruitment/cancel-job-offers/" + jobOfferId,
        {
          reason: reason,
        }
      );

      dispatch({
        type: types.UPDATE_APPLICANT_OVERALL_RESULT,
        data: data,
      });

      showSuccess("Job offer revoked successfully");

      dispatch({ type: types.END_LOADING });
      setConfirmToCancelJobOffer(false);
      setReason("");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const employCandidate = (
  params,
  isFromWaitingList,
  setConfirmToEmploy
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/recruitment/employ-candidate",
        params
      );
      if (!!isFromWaitingList) {
        dispatch({
          type: types.UPDATE_WAITINGLIST_JOB_OFFER,
          data: data,
        });
      } else {
        dispatch({
          type: types.UPDATE_APPLICANT_OVERALL_RESULT,
          data: data,
        });
      }

      showSuccess("Candidate employed successfully");

      dispatch({ type: types.END_LOADING });
      setConfirmToEmploy(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getAppeals = (jobPublicationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/appeals/" + jobPublicationId
      );

      dispatch({
        type: types.SET_APPEALS,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//question-types

export const getQuestionTypes = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/recruitment/question-types");

      dispatch({
        type: types.SET_QUESTION_TYPES,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const setQuestionType = ({ id, isActive }, onCloseDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/recruitment/question-types", {
        id,
        isActive,
      });

      dispatch({
        type: types.UPDATE_QUESTION_TYPE,
        data: data,
      });
      dispatch({ type: types.END_LOADING });

      onCloseDialog();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getQuestions = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/recruitment/questions");

      dispatch({
        type: types.SET_QUESTIONS,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getTests = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/recruitment/tests");

      dispatch({
        type: types.SET_TESTS,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getTempQuestions = (examId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_TEMP_QUESTIONS,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/temp-questions/" + examId
      );

      dispatch({
        type: types.SET_TEMP_QUESTIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getExamQuestions = (examId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_EXAM_QUESTIONS,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/exam-questions/" + examId
      );

      dispatch({
        type: types.SET_EXAM_QUESTIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteAllExamQuestions = (
  exam,
  setConfirmRemoveAll,
  setShowUploadWrittenExamQuestionsForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      await axios.delete("/api/recruitment/exam-questions/" + exam.id);

      dispatch({
        type: types.SET_EXAM_QUESTIONS,
        data: [],
      });
      exam.hasExamQuestion = false;

      dispatch({
        type: types.UPDATE_WRITTEN_EXAM,
        data: exam,
      });
      showSuccess("Exam Questions deleted successfully");
      dispatch({ type: types.END_LOADING });

      setConfirmRemoveAll(false);
      setShowUploadWrittenExamQuestionsForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//publishWrittenExam

export const publishWrittenExamMarks = (
  examId,
  setConfirmToPublishMarks,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        "/api/recruitment/publish-written-exam-marks/" + examId,
        {}
      );
      const { writtenExam, advertisement } = data;
      dispatch({
        type: types.UPDATE_WRITTEN_EXAM,
        data: writtenExam,
      });
      dispatch({
        type: types.UPDATE_ADVERTISEMENT,
        data: advertisement,
      });
      showSuccess("Written exam marks published successfully");
      dispatch({ type: types.END_LOADING });
      setConfirmToPublishMarks(false);
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

///getApplicantExamSessions
export const getApplicantExamSessions = (
  examId,
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/applicant-exam-sessions/" +
          examId +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_APPLICANT_EXAM_SESSIONS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantWrittenExamResults = (
  examId,
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/applicant-written-exam-results/" +
          examId +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_APPLICANT_WRITTEN_EXAM_RESULTS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//getWaitingListSummary
export const getPositionsOnWaitingList = (
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/positions-on-waiting-list/" +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_WAITING_LIST_POSITIONS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getWaitingListRequisitions = (
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/waiting-list-requisitions/" +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_WAITINGLIST_REQUISITIONS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getWaitingListCandidates = (
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/all-applicants-waiting-lists" +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_WAITINGLIST_CANDIDATES,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//getWaitingListOffers
export const getWaitingListOffers = (query, setTotalRequests, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/all-waiting-list-offers" +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_WAITINGLIST_OFFERS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getWaitingListJobOffers = (
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/waiting-list-job-offers/" +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_WAITINGLIST_JOB_OFFERS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getWaitingListPositionByEntitySector = (setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/waiting-list-positions-by-entity-sector"
      );

      dispatch({
        type: types.SET_ENTITY_SECTORS_WAITINGLIST_POSITIONS,
        data: data,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//getApplicantOralExamResults

export const getApplicantOralExamResults = (examId, setIsLoading) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/applicant-oral-exam-results/" + examId
      );

      dispatch({
        type: types.SET_APPLICANT_ORAL_EXAM_RESULTS,
        data,
      });
      setIsLoading(false);
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      setIsLoading(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantWrittenExamAnswers = (
  applicantExamSessionId,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/applicant-written-exam-answers/" +
          applicantExamSessionId
      );

      dispatch({
        type: types.SET_APPLICANT_WRITTEN_EXAM_ANSWERS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//getApplicantOralExamAnswers
export const getApplicantOralExamAnswers = (
  applicantExamSessionId,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/applicant-oral-exam-answers/" + applicantExamSessionId
      );

      dispatch({
        type: types.SET_APPLICANT_ORAL_EXAM_ANSWERS,
        data,
      });
      setIsLoading(false);
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      setIsLoading(false);
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const onUnlockApplicantSession = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_SESSION_UNLOCKED_OVER_3_TIMES,
        data: false,
      });
      const { data } = await axios.put(
        "/api/recruitment/unlock-sessions/" + formData.sessionId,
        formData
      );

      dispatch({
        type: types.UPDATE_APPLICANT_EXAM_SESSIONS,
        data: data,
      });

      onClose();

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      console.log(error);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const onMarkApplicantAsCheated = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_SESSION_UNLOCKED_OVER_3_TIMES,
        data: false,
      });
      const { data } = await axios.put(
        "/api/recruitment/mark-applicant-as-cheated/" + formData.sessionId,
        formData
      );

      dispatch({
        type: types.UPDATE_APPLICANT_EXAM_SESSIONS,
        data: data,
      });

      onClose();

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      console.log(error);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const onChangeApplicantSession = (formData, setShowChangePCSession) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/change-pc-session/" + formData.sessionId,
        formData
      );

      dispatch({
        type: types.UPDATE_APPLICANT_EXAM_SESSIONS,
        data: data,
      });

      setShowChangePCSession();

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      console.log(error);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//SET_APPLICANT_EXAM_SESSION_LOGS
export const getApplicantSessionLogs = (sessinId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_SESSION_UNLOCKED_OVER_3_TIMES,
        data: false,
      });
      const { data } = await axios.get(
        "/api/recruitment/applicant-exam-session-logs/" + sessinId
      );

      dispatch({
        type: types.SET_APPLICANT_EXAM_SESSION_LOGS,
        data,
      });

      const filterUnlockedSessions = data.filter(
        ({ action }) => action === "EXAM_UNLOCKED"
      );

      if (!!filterUnlockedSessions.length && filterUnlockedSessions.length >= 3)
        dispatch({
          type: types.SET_SESSION_UNLOCKED_OVER_3_TIMES,
          data: true,
        });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      console.log(error);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doUploadExam = (formData, onClose, setIsUploading) => {
  return async (dispatch) => {
    try {
      setIsUploading(true);
      const { data } = await axios.post(
        "/api/recruitment/upload-exams",
        formData
      );

      dispatch({
        type: types.SET_TEMP_QUESTIONS,
        data: [],
      });

      dispatch({
        type: types.SET_TEMP_QUESTIONS,
        data,
      });

      onClose();

      setIsUploading(false);
    } catch (error) {
      console.log(error);
      setIsUploading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateUploadedExam = (formData, onClose, setIsUpdating) => {
  return async (dispatch) => {
    try {
      setIsUpdating(true);
      const { data } = await axios.put(
        "/api/recruitment/upload-exams/" + formData.id,
        formData
      );

      dispatch({
        type: types.UPDATE_TEMP_QUESTION,
        data: data,
      });

      onClose();

      setIsUpdating(false);
    } catch (error) {
      console.log(error);
      setIsUpdating(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//updateExamQuestion

export const updateExamQuestion = (formData, onClose, setIsUpdating) => {
  return async (dispatch) => {
    try {
      setIsUpdating(true);
      const { data } = await axios.put(
        "/api/recruitment/exam-questions/" + formData.id,
        formData
      );

      dispatch({
        type: types.UPDATE_EXAM_QUESTION,
        data: data,
      });

      onClose();

      setIsUpdating(false);
    } catch (error) {
      console.log(error);
      setIsUpdating(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//cancelExamQUestion
export const markCanceledExamQUestion = (
  examQuestionId,
  setShowCancelQuestion
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        "/api/recruitment/mark-canceled-exam-question/" + examQuestionId,
        { markedAsCanceled: 1 }
      );

      dispatch({
        type: types.UPDATE_EXAM_QUESTION,
        data: data,
      });

      setShowCancelQuestion(false);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//unmarkedCanceledExamQUestion
export const unmarkedCanceledExamQUestion = (
  examQuestionId,
  setShowUnCancelledQuestion
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        "/api/recruitment/mark-canceled-exam-question/" + examQuestionId,
        { markedAsCanceled: 0 }
      );

      dispatch({
        type: types.UPDATE_EXAM_QUESTION,
        data: data,
      });

      setShowUnCancelledQuestion(false);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const canceledExamQuestion = (examId, setConfirmToCancelQuestions) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.delete(
        "/api/recruitment/cancel-exam-questions/" + examId
      );

      dispatch({
        type: types.SET_EXAM_QUESTIONS,
        data: data,
      });

      setConfirmToCancelQuestions(false);
      showSuccess("Exam question(s) canceled successfully");
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const confirmUploadedExam = (
  examId,
  setIsConfirming,
  setShowUploadWrittenExamQuestionsForm
) => {
  return async (dispatch) => {
    try {
      setIsConfirming(true);
      const { data } = await axios.put(
        "/api/recruitment/confirm-uploaded-exams/" + examId,
        {}
      );
      dispatch({
        type: types.SET_TEMP_QUESTIONS,
        data: [],
      });

      dispatch({
        type: types.UPDATE_WRITTEN_EXAM,
        data: data,
      });

      showSuccess("Saved successfully");
      setShowUploadWrittenExamQuestionsForm(false);
      setIsConfirming(false);
    } catch (error) {
      setIsConfirming(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const checkIFHasExamQuestion = (examId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/recruitment/has-exam-questions/" + examId
      );
      console.log(data);
      dispatch({
        type: types.HAS_EXAM_QUESTIONS,
        data: data.hasExamQuestion,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//updateUploadedExam

export const updateRequisition = (
  requisitionForm,
  setRequisitionForm,
  setShowAddRequisitionForm,
  setSelectedRequisition
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/requisitions/" + requisitionForm.id,
        requisitionForm
      );

      showSuccess("Updated successfully");

      setShowAddRequisitionForm(false);
      setSelectedRequisition(null);

      dispatch({
        type: types.UPDATE_REQUISITION,
        data: data,
      });

      setRequisitionForm({
        id: "",
        positionId: "",
        numberOfPosts: "",
        isInternal: false,
        reason: "",
        willHaveWrittenExam: true,
        willHaveOralExam: true,
        statusComments: "",
        statusId: "",
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRequisitions = (query, setTotalRequests, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/requisitions" +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_REQUISITONS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//getAdvertisements

export const getAdvertisements = (query, setTotalRequests, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/advertisements" +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_ADVERTISEMENTS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getAppealAdvertisements = (setShowLoader) => {
  return async (dispatch) => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(
        "/api/recruitment/appeal/advertisements"
      );

      dispatch({
        type: types.SET_APPEAL_ADVERTISEMENTS,
        data: data,
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRecruitmentMemberAdvertisements = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/member/advertisements"
      );

      dispatch({
        type: types.SET_ADVERTISEMENTS,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//advertisement/applications/:advertisementId

export const getAdvertisementApplications = (
  advertisementId,
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/advertisement/applications/" +
          advertisementId +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_ADVERTISEMENT_APPLICATIONS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getWrittenExams = (advertisementId, setIsLoading) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_WRITTEN_EXAMS,
        data: [],
      });
      setIsLoading(true);
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/written-exams/" + advertisementId
      );

      dispatch({
        type: types.SET_WRITTEN_EXAMS,
        data: data,
      });
      setIsLoading(false);
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      setIsLoading(false);
      // dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getOralExams = (advertisementId, setIsLoading) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_ORAL_EXAMS,
        data: [],
      });
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/oral-exams/" + advertisementId
      );

      dispatch({
        type: types.SET_ORAL_EXAMS,
        data: data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRequisitionStatus = () => {
  return async (dispatch) => {
    try {
      //  dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/recruitment/requisition-status");

      dispatch({
        type: types.SET_REQUISITON_STATUS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      //   dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getAdvertisementStatus = () => {
  return async (dispatch) => {
    try {
      //  dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/recruitment/advertisement-status");

      dispatch({
        type: types.SET_ADVERTISEMENT_STATUS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      //   dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getApplicationStatus = (advertisementId) => {
  return async (dispatch) => {
    try {
      //  dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/applications-status/" + advertisementId
      );

      dispatch({
        type: types.SET_APPLICATION_STATUS,
        data,
      });
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      //   dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteRequisition = (
  selectedRequisition,
  setConfirmRemoveRequisition
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/recruitment/requisitions/" + selectedRequisition.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted Successfully");

      dispatch({
        type: types.DELETE_REQUISITION,
        data: selectedRequisition,
      });

      setConfirmRemoveRequisition(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRequisition = (
  requisitionId,
  setShowRequisitionDetails,
  setSelectedRequisition
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/recruitment/requisitions/" + requisitionId
      );

      dispatch({ type: types.END_LOADING });

      setSelectedRequisition(data);

      setShowRequisitionDetails(true);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const cancelAdvertisement = (
  { advertisementId, reason },
  setConfirmRemoveAdvertisement
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/cancel-advertisements",
        { advertisementId, reason },
        { timeout: 600000 }
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Canceled Successfully");

      dispatch({
        type: types.UPDATE_ADVERTISEMENT,
        data: data,
      });

      setConfirmRemoveAdvertisement(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const onAcceptApplication = (
  applicationId,
  setShowApplicationCv,
  setShowAcceptApplicationForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/accept-application/" + applicationId,
        { statusId: 1 }
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Accepted Successfully!");

      dispatch({
        type: types.UPDATE_ADVERTISEMENT_APPLICATION,
        data: data,
      });

      setShowAcceptApplicationForm(false);
      setShowApplicationCv(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveRequisition = (
  requisitionFormId,
  setShowRequisitionDetails,
  history
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      const { data } = await axios.put(
        "/api/recruitment/approve-requisition/" + requisitionFormId,
        { statusId: 1 }
      );

      dispatch({ type: types.END_WAIT });
      showSuccess("Position advertised successfully!");

      dispatch({
        type: types.UPDATE_REQUISITION,
        data: data,
      });

      setShowRequisitionDetails(false);
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const rejectRequisition = (payload, setShowRequisitionDetails) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });

      const { data } = await axios.put(
        "/api/recruitment/reject-requisition/" + payload.requisitionId,
        { statusComments: payload.reason }
      );

      dispatch({ type: types.END_WAIT });
      showSuccess("Rejected successfully!");

      dispatch({
        type: types.UPDATE_REQUISITION,
        data: data,
      });

      setShowRequisitionDetails(false);
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//rejectApplication

export const onRejectApplication = (
  applicationForm,
  setShowApplicationCv,
  setShowRejectApplicationForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/reject-application/" + applicationForm.applicationId,
        { reason: applicationForm.reason }
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Rejected Successfully!");

      dispatch({
        type: types.UPDATE_ADVERTISEMENT_APPLICATION,
        data: data,
      });

      setShowApplicationCv(false);
      setShowRejectApplicationForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//accept-advertisement

export const publishedAdvertisementShotListed = (
  advertisementId,
  setShowApplication,
  setIsShotlisting
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setIsShotlisting(true);
      const { data } = await axios.put(
        "/api/recruitment/published-application-shotlisted/" + advertisementId,
        {}
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Shortlisting published successfully");

      dispatch({
        type: types.UPDATE_ADVERTISEMENT,
        data: data,
      });
      setIsShotlisting(false);
      setShowApplication(false);
    } catch (error) {
      setIsShotlisting(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRequisitionCertificates = (requisitionId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        "/api/recruitment/requisition-certificates/" + requisitionId
      );

      dispatch({
        type: types.SET_REQUISITION_CERTIFICATES,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRequisitionCompetencies = (requisitionId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/requisition-competencies/" + requisitionId
      );

      dispatch({
        type: types.SET_REQUISITION_COMPETENCIES,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRequisitionQualifications = (requisitionId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        "/api/recruitment/requisition-qualifications/" + requisitionId
      );

      dispatch({
        type: types.SET_REQUISITION_QUALIFICATIONS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getUserProfile = (userId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/recruitment/user-profile/" + userId
      );

      dispatch({
        type: types.SET_USER_PROFILE,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getConvertedFileToBase64 = ({ attachmentId, attachmentType }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/recruitment/attached-files/" + attachmentId + "/" + attachmentType
      );

      let url = "";
      if (data.isUrl) url = data.attachment;
      else {
        const bytes = window.atob(data.attachment);

        let length = bytes.length;
        let byteArray = new Uint8Array(length);

        while (length--) {
          byteArray[length] = bytes.charCodeAt(length);
        }

        const blob = new Blob([byteArray], { type: "application/pdf" });

        url = URL.createObjectURL(blob);
      }

      dispatch({
        type: types.SET_CONVERTED_FILE_TO_BASE64,
        data: url,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveWrittenExam = (
  formData,
  setFormData,
  setShowScheduleExamForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/written-exams",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Exam saved successfully!");

      dispatch({
        type: types.ADD_WRITTEN_EXAM,
        data: data,
      });

      setFormData({
        advertisementId: formData ? formData.advertisementId : 0,
        durationMin: 0,
        totalMarks: 0,
        seatingDate: null,
        startTime: null,
        hasPDF: false,
        endTime: "",
        startTime: "",
        notice: "",
        examCenters: [],
      });

      setShowScheduleExamForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateWrittenExam = (
  formData,
  setFormData,
  setShowUpdateScheduledExamForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/update-written-exams",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Exam updated successfully!");

      dispatch({
        type: types.UPDATE_WRITTEN_EXAM,
        data: data,
      });

      setFormData({
        advertisementId: formData ? formData.advertisementId : 0,
        durationMin: 0,
        totalMarks: 0,
        seatingDate: null,
        startTime: null,
        hasPDF: false,
        endTime: "",
        startTime: "",
      });

      setShowUpdateScheduledExamForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const publishWrittenExam = (writtenExam, handleCloseMenu) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      let payload = {
        id: writtenExam.id,
        isActive: writtenExam.isActive ? false : true,
      };
      const { data } = await axios.post(
        "/api/recruitment/publish-written-exams",
        payload
      );

      dispatch({ type: types.END_LOADING });

      showSuccess(
        `Exam ${payload.isActive ? "" : "un"}published successfully!`
      );

      writtenExam.isActive = !writtenExam.isActive;

      dispatch({
        type: types.UPDATE_WRITTEN_EXAM,
        data: writtenExam,
      });

      handleCloseMenu(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveOralExam = (
  formData,
  setFormData,
  setShowScheduleExamForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/oral-exams",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Exam saved successfully!");

      dispatch({
        type: types.ADD_ORAL_EXAM,
        data: data,
      });

      setFormData({
        advertisementId: formData ? formData.advertisementId : 0,
        durationMin: 0,
        totalMarks: 0,
        seatingDate: null,
        startTime: null,
        hasPDF: false,
        endTime: "",
        startTime: "",
      });

      setShowScheduleExamForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateOralExam = (
  formData,
  setFormData,
  setShowUpdateScheduledExamForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/oral-exams/" + formData.examId,
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Exam updated successfully!");

      dispatch({
        type: types.UPDATE_ORAL_EXAM,
        data: data,
      });

      setFormData({
        advertisementId: formData ? formData.advertisementId : 0,
        durationMin: 0,
        totalMarks: 0,
        seatingDate: null,
        startTime: null,
        hasPDF: false,
        endTime: "",
        startTime: "",
      });

      setShowUpdateScheduledExamForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//deleteWrittenExam

export const cancelWrittenExam = (
  examForm,
  setWrittenExamForm,
  setShowCancelWrittenExamForm,
  setAskToCancel
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/cancel-written-exam/" + examForm.examId,
        examForm
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Exam Canceled successfully");

      dispatch({
        type: types.UPDATE_WRITTEN_EXAM,
        data: data,
      });
      setWrittenExamForm({
        examId: "",
        cancelationReason: "",
      });

      setShowCancelWrittenExamForm(false);
      setAskToCancel(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const cancelOralExam = (
  examForm,
  setOralExamForm,
  setShowCancelOralExamForm,
  setAskToCancel
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/cancel-oral-exam/" + examForm.examId,
        examForm
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Exam Canceled successfully");

      dispatch({
        type: types.UPDATE_ORAL_EXAM,
        data: data,
      });
      setOralExamForm({
        examId: "",
        cancelationReason: "",
      });

      setShowCancelOralExamForm(false);
      setAskToCancel(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//deleteOralExam

export const deleteOralExam = (selectedOralExam, setConfirmRemoveOralExam) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/recruitment/oral-exams/" + selectedOralExam.examId
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_ORAL_EXAM,
        data: selectedOralExam,
      });

      setConfirmRemoveOralExam(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteAllTempQuestions = (examId, setConfirmRemoveAll) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/recruitment/all/temp-questions/" + examId);

      dispatch({ type: types.END_LOADING });
      showSuccess("All Questions deleted Successfully");

      dispatch({
        type: types.SET_TEMP_QUESTIONS,
        data: [],
      });
      setConfirmRemoveAll(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//deleteTempQuestion

export const deleteTempQuestion = (selectedQuestion, confirmRemoveQuestion) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete(
        "/api/recruitment/temp-questions/" + selectedQuestion.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Question deleted Successfully");

      dispatch({
        type: types.DELETE_TEMP_QUESTION,
        data: selectedQuestion,
      });
      confirmRemoveQuestion(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantNotAttended = (
  examId,
  examTypeId,
  idNumber,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `/api/recruitment/find-applicant-not-attended/${examId}/${examTypeId}/${idNumber}`
      );

      dispatch({
        type: types.SET_APPLICANTS_NOT_ATTENDED,
        data: data,
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantsAttended = (examId, examTypeId, examRoomId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_APPLICANTS_ATTENDED,
        data: [],
      });
      const { data } = await axios.get(
        `/api/recruitment/applicant-attended/${examId}/${examTypeId}/${examRoomId}`
      );

      dispatch({
        type: types.SET_APPLICANTS_ATTENDED,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
// addApplicantAttend

export const addApplicantAttend = (applicant, setApplicantId, setIdNumber) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/recruitment/attandance", {
        applicationId: applicant.id,
        examTypeId: applicant.examTypeId,
        examId: applicant.examId,
        userId: applicant.userId,
        idNumber: applicant.idNumber,
        userId: applicant.userId,
        examRoomId:
          +applicant.examTypeId === 1
            ? applicant?.examCenterId || ""
            : applicant?.panelId || "",
      });

      // dispatch({ type: types.END_LOADING });
      showSuccess(applicant.firstName + " attended successfully!");
      dispatch({
        type: types.SET_APPLICANTS_NOT_ATTENDED,
        data: [],
      });
      dispatch({
        type: types.ADD_APPLICANT_ATTENDED,
        data,
      });
      setApplicantId("0");
      setIdNumber("");
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      setApplicantId("0");
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//removeApplicantAttend

export const removeApplicantAttend = (
  applicant,
  examTypeId,
  reason,
  setApplicantId,
  setConfirmRemoveApplicant,
  setSelectedApplicant,
  setReason
) => {
  return async (dispatch) => {
    try {
      setApplicantId(applicant.id);

      dispatch({ type: types.START_LOADING });

      await axios.put("/api/recruitment/attandance/" + applicant.id, {
        examTypeId: examTypeId,
        reason: reason,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess(
        applicant.firstName + " removed on attandance list successfully!"
      );

      dispatch({
        type: types.DELETE_APPLICANT_ATTENDED,
        data: applicant,
      });
      setApplicantId("0");
      setConfirmRemoveApplicant(false);
      setSelectedApplicant(null);
      setReason("");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setApplicantId("0");
      setConfirmRemoveApplicant(false);
      setSelectedApplicant(null);

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSessionAdditionalMinutes = (sessinId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/recruitment/applicant-exam-session-additional-minutes/${sessinId}`
      );

      dispatch({
        type: types.SET_APPLICANT_SESSION_ADDITIONALMINUTES,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveAdditionalMinutes = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post(
        "/api/recruitment/applicant-exam-session-additional-minutes",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Minutes added successfully!");
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const saveExamAdditionalMinutes = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/written-exam-additional-minutes",
        formData
      );

      dispatch({
        type: types.UPDATE_WRITTEN_EXAM,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Minutes added successfully!");
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const saveWrittenTopupScore = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/written-exam-topup-score",
        formData
      );
      dispatch({
        type: types.UPDATE_APPLICANT_WRITTEN_EXAM_SESSION,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      //TOPUP_SCORE
      showSuccess("Mark updated successfully!");
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveExaminerOralTopupScore = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/examiner-oral-exam-topup-score",
        formData
      );
      dispatch({
        type: types.UPDATE_EXAMINER_ORAL_EXAM_RESULT,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      //TOPUP_SCORE
      showSuccess("Top-up Mark updated successfully!");
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveOralTopupScore = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/oral-exam-topup-score",
        formData
      );
      
      dispatch({
        type: types.UPDATE_APPLICANT_ORAL_EXAM_RESULT,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      //TOPUP_SCORE
      showSuccess("Top-up Mark updated successfully!");
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const publishOralTopupScore = (formData, setConfirmTopupMark) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/publish-oral-exam-topup-score",
        formData
      );
      dispatch({
        type: types.UPDATE_APPLICANT_ORAL_EXAM_RESULT,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      //TOPUP_SCORE
      showSuccess("Top-up mark published successfully!");
      setConfirmTopupMark(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//saveOpenQuestionScore

export const saveOpenQuestionScore = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/open-question-score",
        formData
      );
      dispatch({
        type: types.UPDATE_APPLICANT_WRITTEN_EXAM_ANSWER,
        data: data.applicantWrittenExamAnswer,
      });

      dispatch({
        type: types.UPDATE_APPLICANT_WRITTEN_EXAM_SESSION,
        data: data.applicantWrittenExamResult,
      });

      dispatch({
        type: types.SET_APPLICANT_WRITTEN_EXAM_RESULT,
        data: data.applicantWrittenExamResult,
      });

      dispatch({ type: types.END_LOADING });
      //TOPUP_SCORE
      showSuccess("Saved successfully!");
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveOralMarks = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/oral-marks",
        formData
      );
      dispatch({
        type: types.UPDATE_EXAMINER_ORAL_EXAM_RESULT,
        data,
      });

      dispatch({ type: types.END_LOADING });
      //TOPUP_SCORE
      showSuccess("Saved successfully!");
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveOralSchema = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/applicant-oral-exam-schema",
        formData
      );
      dispatch({
        type: types.SET_APPLICANT_ORAL_EXAM_SCHEMA,
        data: data,
      });

      dispatch({
        type: types.SET_SHOW_ADD_ORAL_MARK_DIALOG,
        data: true,
      });
      dispatch({
        type: types.SET_SHOW_ADD_ORAL_SCHEMA_DIALOG,
        data: false,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully!");
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//deleteOralMarkAnswer

export const deleteOralMarkAnswer = (
  selectedOralExamMarkAnswer,
  setShowConfirmRemoveOralMark
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/recruitment/oral-marks/" + selectedOralExamMarkAnswer.id
      );
      dispatch({
        type: types.SET_APPLICANT_ORAL_EXAM_ANSWERS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      //TOPUP_SCORE
      showSuccess("Deleted successfully!");

      setShowConfirmRemoveOralMark(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//

export const publishOralExamMarks = (examId, panelId, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.put(
        "/api/recruitment/publish-oral-exam-marks/" + examId + "/" + panelId,
        []
      );
      dispatch({
        type: types.UPDATE_PANEL_ORAL_EXAM,
        data,
      });

      showSuccess("Oral marks published successfully!");
      onClose();
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSearchedPanelist = (params, setIsSearching) => {
  return async (dispatch) => {
    try {
      setIsSearching(true);
      const { data } = await axios.post(
        "/api/recruitment/search-panelist",
        params
      );
      if (!data) showSuccess("No user found");
      dispatch({
        type: types.SET_SEARCHED_PANELISTS,
        data,
      });

      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSearchedInvigilator = (params, setIsSearching) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SEARCHED_INVIGILATORS,
        data: null,
      });

      setIsSearching(true);
      const { data } = await axios.post(
        "/api/recruitment/search-invigilator",
        params
      );
      if (!data) showSuccess("No user found");
      dispatch({
        type: types.SET_SEARCHED_INVIGILATORS,
        data,
      });

      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSearchedRecruitmentMember = (params, setIsSearching) => {
  return async (dispatch) => {
    try {
      setIsSearching(true);
      const { data } = await axios.post(
        "/api/recruitment/search-member",
        params
      );
      if (!data) showSuccess("No user found");
      dispatch({
        type: types.SET_SEARCHED_RECRUITMENT_MEMBER,
        data,
      });

      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addPanelist = (params, setIsAddingPanelist, setShowPanelist) => {
  return async (dispatch) => {
    try {
      setIsAddingPanelist(true);
      const { data } = await axios.post("/api/recruitment/panelists", params);
      dispatch({
        type: types.ADD_PANELIST,
        data,
      });
      dispatch({
        type: types.SET_SEARCHED_PANELISTS,
        data: null,
      });
      showSuccess("Panelist added successfully!");

      setIsAddingPanelist(false);
      setShowPanelist(false);
    } catch (error) {
      setIsAddingPanelist(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addInvigilator = (
  params,
  setIsAddingInvigilator,
  setShowInvigilator,
  setSearchTeam,
  setExamCenter
) => {
  return async (dispatch) => {
    try {
      setIsAddingInvigilator(true);
      const { data } = await axios.post(
        "/api/recruitment/invigilators",
        params
      );
      dispatch({
        type: types.ADD_INVIGILATOR,
        data,
      });
      dispatch({
        type: types.SET_SEARCHED_INVIGILATORS,
        data: null,
      });
      showSuccess("Invigilator added successfully!");
      setSearchTeam("");
      setExamCenter(null);
      setIsAddingInvigilator(false);
      // setShowInvigilator(false);
    } catch (error) {
      setIsAddingInvigilator(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addExamCenter = (params, setExamCenterId, center) => {
  return async (dispatch) => {
    try {
      setExamCenterId(center.id);
      const { data } = await axios.post(
        "/api/recruitment/exam-centers",
        params
      );
      dispatch({
        type: types.ADD_EXAM_CENTER,
        data,
      });

      dispatch({
        type: types.DELETE_EXAM_CENTER_NONE_SELECTED,
        data: center,
      });

      showSuccess("Center added successfully!");

      setExamCenterId("0");
    } catch (error) {
      setExamCenterId("0");
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const savePanel = (formData, setIsLoading, onClose) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post("/api/recruitment/panels", formData);
      dispatch({
        type: types.ADD_PANEL,
        data,
      });

      showSuccess("Panel added successfully!");
      setIsLoading(false);
      onClose();
    } catch (error) {
      // onClose();
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const updatePanel = (formData, setIsLoading, onClose) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.put(
        "/api/recruitment/panels/" + formData.id,
        formData
      );
      dispatch({
        type: types.UPDATE_PANEL,
        data,
      });

      showSuccess("Panel added successfully!");
      setIsLoading(false);
      onClose();
    } catch (error) {
      // onClose();
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addRecruitmentMember = (
  params,
  setIsAddingRecruitmentMember,
  setShowRecruitmentMember
) => {
  return async (dispatch) => {
    try {
      setIsAddingRecruitmentMember(true);
      const { data } = await axios.post("/api/recruitment/members", params);
      dispatch({
        type: types.ADD_RECRUITMENT_MEMBER,
        data,
      });
      dispatch({
        type: types.SET_SEARCHED_RECRUITMENT_MEMBER,
        data: null,
      });
      showSuccess("Recruitment member added successfully!");

      setIsAddingRecruitmentMember(false);
      setShowRecruitmentMember(false);
    } catch (error) {
      setIsAddingRecruitmentMember(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removePanelist = (
  selectedPanelist,
  setPanelistId,
  setConfirmRemovePanelist,
  setSelectedPanelist
) => {
  return async (dispatch) => {
    try {
      setPanelistId(selectedPanelist.id);
      setConfirmRemovePanelist(false);
      setSelectedPanelist(null);

      await axios.delete("/api/recruitment/panelists/" + selectedPanelist.id);

      dispatch({ type: types.END_LOADING });
      showSuccess(
        selectedPanelist.user.firstName +
          " was removed from panelists successfully!"
      );

      dispatch({
        type: types.DELETE_PANELIST,
        data: selectedPanelist,
      });
      setPanelistId("0");
    } catch (error) {
      setPanelistId("0");
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const removePanel = (
  selectedPanel,
  setPanelId,
  setConfirmRemovePanel,
  setSelectedPanel
) => {
  return async (dispatch) => {
    try {
      setPanelId(selectedPanel.id);
      setConfirmRemovePanel(false);
      setSelectedPanel(null);

      await axios.delete("/api/recruitment/panels/" + selectedPanel.id);

      dispatch({ type: types.END_LOADING });
      showSuccess(
        selectedPanel.panel + " was removed from panels successfully!"
      );

      dispatch({
        type: types.DELETE_PANEL,
        data: selectedPanel,
      });
      setPanelId("0");
    } catch (error) {
      setPanelId("0");
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removeInvigilator = (
  selectedInvigilator,
  setInvigilatorId,
  setConfirmRemoveInvigilator,
  setSelectedInvigilator
) => {
  return async (dispatch) => {
    try {
      setInvigilatorId(selectedInvigilator.id);
      setConfirmRemoveInvigilator(false);
      setSelectedInvigilator(null);

      await axios.delete(
        "/api/recruitment/invigilators/" + selectedInvigilator.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(
        selectedInvigilator.user.firstName +
          " was removed from invigilators successfully"
      );

      dispatch({
        type: types.DELETE_INVIGILATOR,
        data: selectedInvigilator,
      });
      setInvigilatorId("0");
    } catch (error) {
      setInvigilatorId("0");
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//removeExamCenter
export const removeExamCenter = (
  selectedExamCenter,
  setExamCenterId,
  setConfirmRemoveExamCenter,
  setSelectedExamCenter
) => {
  return async (dispatch) => {
    try {
      setExamCenterId(selectedExamCenter.id);
      setConfirmRemoveExamCenter(false);
      setSelectedExamCenter(null);

      await axios.delete(
        "/api/recruitment/exam-centers/" + selectedExamCenter.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(
        selectedExamCenter.center.name +
          " was removed from exam centers successfully"
      );

      dispatch({
        type: types.DELETE_EXAM_CENTER,
        data: selectedExamCenter,
      });
      setExamCenterId("0");
    } catch (error) {
      setExamCenterId("0");
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const removeRecruitmentMember = (
  selectedRecruitmentMember,
  setRecruitmentMemberId,
  setConfirmRemoveRecruitmentMember,
  setSelectedRecruitmentMember
) => {
  return async (dispatch) => {
    try {
      setRecruitmentMemberId(selectedRecruitmentMember.id);
      setConfirmRemoveRecruitmentMember(false);
      setSelectedRecruitmentMember(null);

      await axios.delete(
        "/api/recruitment/members/" + selectedRecruitmentMember.id
      );

      dispatch({ type: types.END_LOADING });
      showSuccess(
        selectedRecruitmentMember.user.firstName +
          " was removed from recruitment members successfully"
      );

      dispatch({
        type: types.DELETE_RECRUITMENT_MEMBER,
        data: selectedRecruitmentMember,
      });
      setRecruitmentMemberId("0");
    } catch (error) {
      setRecruitmentMemberId("0");
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPanelists = (examId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/recruitment/panelists/" + examId);
      dispatch({
        type: types.SET_PANELISTS,
        data,
      });
      dispatch({
        type: types.SET_SEARCHED_PANELISTS,
        data: null,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getPanels = (examId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/recruitment/panels/" + examId);
      dispatch({
        type: types.SET_PANELS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getInvigilators = (examId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/invigilators/" + examId
      );
      dispatch({
        type: types.SET_INVIGILATORS,
        data,
      });
      dispatch({
        type: types.SET_SEARCHED_INVIGILATORS,
        data: null,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getExamCenters = (examId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/exam-centers/" + examId
      );

      dispatch({
        type: types.SET_EXAM_CENTERS,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRecruitmentMembers = (advertisementId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/members/" + advertisementId
      );
      dispatch({
        type: types.SET_RECRUITMENT_MEMBERS,
        data,
      });
      dispatch({
        type: types.SET_SEARCHED_RECRUITMENT_MEMBER,
        data: null,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPanelOralExams = (setIsLoading) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });
      setIsLoading(true);
      const { data } = await axios.get("/api/recruitment/panel/oral-exams");
      dispatch({
        type: types.SET_PANEL_ORAL_EXAMS,
        data,
      });
      setIsLoading(false);
      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      setIsLoading(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getInvigilatorWrittenExams = (setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/invigilator/written-exams"
      );
      dispatch({
        type: types.SET_INVIGILATOR_WRITTEN_EXAMS,
        data,
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const publishOpenExamQuestionMarks = (
  examId,
  setShowWrittenExamMarking,
  setIsPublishing
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setIsPublishing(true);
      const { data } = await axios.put(
        "/api/recruitment/publish-open-exam-question-marks/" + examId,
        {}
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Exam published successfully");

      dispatch({
        type: types.SET_INVIGILATOR_WRITTEN_EXAMS,
        data,
      });
      setIsPublishing(false);
      setShowWrittenExamMarking(false);
    } catch (error) {
      setIsPublishing(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getExaminerOralExamResults = (examId, panelId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/examiner-oral-exam-results/" + examId + "/" + panelId
      );

      dispatch({
        type: types.SET_EXAMINER_ORAL_EXAM_RESULTS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getExaminerOralExamAnswer = (examId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/examiner-oral-exam-answer/" + examId
      );

      dispatch({
        type: types.SET_APPLICANT_ORAL_EXAM_ANSWERS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getInProgressAppeals = (
  advertisementId,
  hrCanViewAppealedOnSecondLevel,
  setShowLoader
) => {
  return async (dispatch) => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(
        "/api/recruitment/in-progress-appeals/" +
          advertisementId +
          "?hrCanViewAppealedOnSecondLevel=" +
          (!!hrCanViewAppealedOnSecondLevel ? "Y" : "N")
      );

      dispatch({
        type: types.SET_APPEALS,
        data,
      });

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//getOverdueAppeals
export const getOverdueAppeals = (
  advertisementId,
  hrCanViewAppealedOnSecondLevel,
  setShowLoader
) => {
  return async (dispatch) => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(
        "/api/recruitment/overdue-appeals/" +
          advertisementId +
          "?hrCanViewAppealedOnSecondLevel=" +
          (!!hrCanViewAppealedOnSecondLevel ? "Y" : "N")
      );

      dispatch({
        type: types.SET_APPEALS,
        data,
      });

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getArchivedAppeals = (advertisementId, setShowLoader) => {
  return async (dispatch) => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(
        "/api/recruitment/archived-appeals/" + advertisementId
      );

      dispatch({
        type: types.SET_APPEALS,
        data,
      });

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getUnConfirmedAppeals = (advertisementId, level) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/unconfirmed-appeals/" + advertisementId + "/" + level
      );

      dispatch({
        type: types.SET_UNCONFIRMED_APPEALS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getAppealDetails = (applicationId, appealTypeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: [],
      });

      const { data } = await axios.get(
        "/api/recruitment/appeal-details/" + applicationId + "/" + appealTypeId
      );

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantWrittenResult = (
  applicationId,
  setShowApplicantWrittenExamAnswers
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/recruitment/applicant-written-exam-result/" + applicationId
      );

      dispatch({
        type: "SET_APPLICANT_WRITTEN_EXAM_RESULT",
        data: data,
      });
      setShowApplicantWrittenExamAnswers(true);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantOralResult = (
  applicationId,
  setShowApplicantOralExamAnswers
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/recruitment/applicant-oral-exam-result/" + applicationId
      );

      dispatch({
        type: types.SET_APPLICANT_ORAL_EXAM_RESULT,
        data: data,
      });
      setShowApplicantOralExamAnswers(true);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const submitFirstLevelAppealDecision = (
  formData,
  setFormData,
  setIsSubmitting,
  onClose
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);

      const { data } = await axios.post(
        "/api/recruitment/first-level-appeal-decision",
        formData
      );
      const { appealDetails, appeal } = data;
      showSuccess("Appeal Decision Submitted successfully");

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });

      setFormData({
        appealId: 0,
        message: "",
        isFirstLevelValid: false,
        firstLevelValid: "",
      });

      setIsSubmitting(false);
      onClose();
    } catch (error) {
      setIsSubmitting(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const submitSecondLevelAppealDecision = (
  formData,
  setFormData,
  setIsSubmitting,
  onClose
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);

      const { data } = await axios.post(
        "/api/recruitment/second-level-appeal-decision",
        formData
      );
      const { appealDetails, appeal } = data;
      showSuccess("Appeal Decision Submitted successfully");

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });

      setFormData({
        appealId: 0,
        message: "",
        isFirstLevelValid: false,
        secondLevelValid: "",
      });

      setIsSubmitting(false);
      onClose();
    } catch (error) {
      setIsSubmitting(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const submitResolveAppeal = (
  formData,
  setFormData,
  setIsSubmitting,
  onClose
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);

      const { data } = await axios.post(
        "/api/recruitment/resolve-appeal",
        formData
      );
      const { appealDetails, appeal } = data;
      showSuccess("Appeal Resolution Submitted successfully");

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });

      setFormData({
        appealId: 0,
        message: "",
      });

      setIsSubmitting(false);
      onClose();
    } catch (error) {
      setIsSubmitting(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//confirm-appeal-decision

export const confirmFLAppealDecision = (
  formData,
  onClose,
  setConfirmDecision
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/recruitment/confirm-level1-appeal-decision",
        formData
      );
      showSuccess("Decision Confirmed successfully");

      dispatch({
        type: types.SET_APPEALS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      setConfirmDecision(false);
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const confirmSLAppealDecision = (
  formData,
  onClose,
  setConfirmDecision
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/recruitment/confirm-level2-appeal-decision",
        formData
      );
      showSuccess("Decision Confirmed successfully");

      dispatch({
        type: types.SET_APPEALS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      setConfirmDecision(false);
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const confirmOverdueFLAppealDecision = (
  formData,
  onClose,
  setConfirmDecision
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/recruitment/confirm-overdue-level1-appeal-decision",
        formData
      );
      showSuccess("Decision Confirmed successfully");

      dispatch({
        type: types.SET_APPEALS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      setConfirmDecision(false);
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//onForwardBackAppeal
export const onForwardBackAppeal = (
  formData,
  onClose,
  setReason,
  setShowForwardBackAppeals
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/recruitment/forward-back-appeals",
        formData
      );
      showSuccess("Appeals Forwarded successfully");

      dispatch({
        type: types.SET_APPEALS,
        data,
      });

      dispatch({ type: types.END_LOADING });
      setReason("");
      setShowForwardBackAppeals(false);
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//closeAppeal

export const closeAppeal = (formData, setConfirmAppealResolved) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/recruitment/close-appeal",
        formData
      );
      showSuccess("Appeal Closed successfully");

      dispatch({
        type: types.SET_APPEALS,
        data,
      });
      setConfirmAppealResolved(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteFirstLevelAppealDecision = (
  appealId,
  setConfirmDeleteFirstLevelDecision
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.delete(
        "/api/recruitment/first-level-appeal-decision/" + appealId
      );
      const { appealDetails, appeal } = data;
      showSuccess("Appeal Decision Deleted successfully");

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });

      setConfirmDeleteFirstLevelDecision(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteSecondLevelAppealDecision = (
  appealId,
  setConfirmDeleteSecondLevelDecision
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.delete(
        "/api/recruitment/second-level-appeal-decision/" + appealId
      );
      const { appealDetails, appeal } = data;
      showSuccess("Appeal Decision Deleted successfully");

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });

      setConfirmDeleteSecondLevelDecision(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantOralSchema = (examId, testForWrittenSkills) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/recruitment/applicant-oral-exam-schema/" + examId
      );

      if (data) {
        data.hasWrittenSkillTest = testForWrittenSkills ? true : false;
      }
      dispatch({
        type: types.SET_APPLICANT_ORAL_EXAM_SCHEMA,
        data: data,
      });
      if (data && !!data.markOnBehaviour)
        dispatch({
          type: types.SET_SHOW_ADD_ORAL_MARK_DIALOG,
          data: true,
        });
      else
        dispatch({
          type: types.SET_SHOW_ADD_ORAL_SCHEMA_DIALOG,
          data: true,
        });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveOrRejectWaitingListRequisition = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/approve-waiting-list-requisition",
        formData
      );
      dispatch({
        type: types.UPDATE_WAITINGLIST_REQUISITION,
        data,
      });

      onClose();
      showSuccess(
        `${formData.statusId === 2 ? "Approved" : "Rejected"} Successfully`
      );
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const requestWaitingListRequisition = (
  waitingListRequestForm,
  onClose,
  history
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/recruitment/waiting-list-requisitions",
        { ...waitingListRequestForm, isFromWaitingListRequisition: true }
      );

      showSuccess("Request submitted successfully");

      onClose();
      dispatch({
        type: types.ADD_WAITINGLIST_REQUISITION,
        data,
      });

      dispatch({ type: types.END_LOADING });

      history.push("/recruitment/waiting-list/requisitions");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const deleteWaitingListRequisition = (id, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      await axios.delete("/api/recruitment/waiting-list-requisitions/" + id);

      dispatch({ type: types.END_LOADING });
      onClose();

      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_WAITINGLIST_REQUISITION,
        id,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//check-written-exam-validation
export const onCheckWrittenExamValidation = (
  advertisementId,
  setShowScheduleExamForm,
  setCurrentDate,
  setIsExamDateUnlocked,
  action
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/check-written-exam-validation/" +
          advertisementId +
          "/" +
          action
      );

      setCurrentDate(data.currentDate);

      setIsExamDateUnlocked(data.isExamDateUnlocked);
      setShowScheduleExamForm(true);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const onCheckOralExamValidation = (
  advertisementId,
  setShowScheduleExamForm,
  setCurrentDate,
  setIsExamDateUnlocked,
  action
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/check-oral-exam-validation/" +
          advertisementId +
          "/" +
          action
      );
      setCurrentDate(data.currentDate);

      setIsExamDateUnlocked(data.isExamDateUnlocked);

      setShowScheduleExamForm(true);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//view-oral-exam-questions
export const onViewOralExamQuestions = (
  examId,
  setDocumentQuestions,
  setShowDocument
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/view-oral-exam-questions/" + examId
      );
      setDocumentQuestions({
        url: data,
        name: "Oral exam prepared questions",
      });

      setShowDocument(true);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getAppealInprogress = (query, setTotalRequests, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/" +
          query.endPoint +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_INPROGRESS_APPEALS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getAppealArchived = (query, setTotalRequests, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/" +
          query.endPoint +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_ARCHIVED_APPEALS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getNpscEmployeeAppeals = (query, setTotalRequests, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/" +
          query.endPoint +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_NPSC_EMPLOYEE_APPEALS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getAllOverdueAppeals = (query, setTotalRequests, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/all-overdue-appeals?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_ALL_OVERDUE_APPEALS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//getExamKey
export const getExamKey = (examId, setExamKey) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/recruitment/exam-key/" + examId);

      setExamKey(data);
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//getExamKey
export const getExamCenterKey = (examCenterId, setExamKey) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/recruitment/exam-center-key/" + examCenterId
      );

      setExamKey(data);
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const reGenerateExamKey = (examId, setIsLoading, setExamKey) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/recruitment/re-generate-exam-key/" + examId
      );

      setExamKey(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getActiveCenters = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/recruitment/active-centers");
      dispatch({
        type: types.SET_ACTIVE_CENTERS,
        data: data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCentersNotAddedOnExam = (examId, setIsSearching) => {
  return async (dispatch) => {
    try {
      setIsSearching(true);
      const { data } = await axios.get(
        "/api/recruitment/center-not-added-on-exam/" + examId
      );
      dispatch({
        type: types.SET_EXAM_CENTER_NONE_SELECTED,
        data: data,
      });
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadActionExamQuestionTemplate = (questionType) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/recruitment/exam-question-template/" + questionType,
        {
          responseType: "blob",
        }
      );

      download(new Blob([data]), `${questionType}_Template.xlsx`, ".xlsx");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};

export const downloadApplications = (query, setShowProgressBar) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowProgressBar(true);
      const { data } = await axios.get(
        "/api/recruitment/download-applications" +
          "?" +
          new URLSearchParams(query).toString(),
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `LIST_OF_APPLICATIONS-${query.advertisementName}.xlsx`,
        ".xlsx"
      );

      dispatch({ type: types.END_LOADING });

      setShowProgressBar(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowProgressBar(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadOverallResults = (query, setShowProgressBar) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowProgressBar(true);
      const { data } = await axios.get(
        "/api/recruitment/download-overall-results" +
          "?" +
          new URLSearchParams(query).toString(),
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `OVERALL_RESULTS-${query.advertisementName}.xlsx`,
        ".xlsx"
      );

      dispatch({ type: types.END_LOADING });

      setShowProgressBar(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowProgressBar(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadWrittenExamResults = (query, setShowProgressBar) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowProgressBar(true);
      const { data } = await axios.get(
        "/api/recruitment/download-written-exam-results" +
          "?" +
          new URLSearchParams(query).toString(),
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `WRITTEN_EXAM_RESULTS-${query.examName}.xlsx`,
        ".xlsx"
      );

      dispatch({ type: types.END_LOADING });

      setShowProgressBar(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowProgressBar(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadOralExamResults = (query, setShowProgressBar) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowProgressBar(true);
      const { data } = await axios.get(
        "/api/recruitment/download-oral-exam-results" +
          "?" +
          new URLSearchParams(query).toString(),
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `ORAL_EXAM_RESULTS-${query.examName}.xlsx`,
        ".xlsx"
      );

      dispatch({ type: types.END_LOADING });

      setShowProgressBar(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowProgressBar(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRecruitmentRequestTypes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/recruitment/request-types");
      dispatch({
        type: types.SET_RECRUITMENT_REQUEST_TYPES,
        data: data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRecruitmentRequestAttachmentTypes = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/recruitment/request-attachment-types"
      );
      dispatch({
        type: types.SET_RECRUITMENT_REQUEST_ATTACHMENT_TYPES,
        data: data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//saveRequest
export const saveRecruitmentRequest = (formData, onClose, setActiveStep) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({ type: types.START_WAIT });
      const { data } = await axios.post(
        "/api/recruitment/document-requests",
        formData
      );

      dispatch({
        type: types.UPDATE_APPLICANT_OVERALL_RESULT,
        data: data,
      });

      onClose();

      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      showSuccess("Request is sent successfully");
    } catch (error) {
      setActiveStep(0);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const countRecruitmentRequests = (selectedEntitySectorId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_NO_OF_RECRUITMENT_REQUESTS,
        data: 0,
      });
      const { data } = await axios.get(
        "/api/recruitment/count-document-requests/" + selectedEntitySectorId
      );
      dispatch({
        type: types.SET_NO_OF_RECRUITMENT_REQUESTS,
        data: data.number,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRecruitmentRequests = (setIsLoader, selectedEntitySectorId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_RECRUITMENT_REQUESTS,
        data: [],
      });
      setIsLoader(true);
      const { data } = await axios.get(
        "/api/recruitment/document-requests/" + selectedEntitySectorId
      );
      dispatch({
        type: types.SET_RECRUITMENT_REQUESTS,
        data,
      });
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getRecruitmentRequest = (
  requestId,
  setApproveRequestForm,
  handleCloseMenu
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/recruitment/document-request/" + requestId
      );
      dispatch({
        type: types.SET_RECRUITMENT_REQUEST,
        data,
      });
      setApproveRequestForm(true);
      handleCloseMenu();
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const approveRecruitmentRequest = (approveRequest, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/approve-document-requests",
        approveRequest
      );

      dispatch({
        type: types.SET_NO_OF_RECRUITMENT_REQUESTS,
        data: data.number,
      });
      dispatch({
        type: types.UPDATE_RECRUITMENT_REQUEST,
        data: data.response,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Approved Successfully");

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const verifyRecruitmentRequest = (requestId, formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/verify-document-requests/" + requestId,
        formData
      );

      dispatch({
        type: types.UPDATE_RECRUITMENT_REQUEST,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
      showSuccess("Verified Successfully");

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const rejectRecruitmentRequest = (rejectExitForm, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/reject-document-requests",
        rejectExitForm
      );

      dispatch({
        type: types.SET_NO_OF_RECRUITMENT_REQUESTS,
        data: data.number,
      });
      dispatch({
        type: types.UPDATE_RECRUITMENT_REQUEST,
        data: data.response,
      });

      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Rejected successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const countNPSCReports = (selectedEntitySectorId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_NO_OF_NPSC_REPORTS,
        data: 0,
      });
      const { data } = await axios.get(
        "/api/recruitment/count-npsc-reports/" + selectedEntitySectorId
      );
      dispatch({
        type: types.SET_NO_OF_NPSC_REPORTS,
        data: data.number,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getNPSCReports = (setIsLoader, selectedEntitySectorId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_NPSC_REPORTS,
        data: [],
      });
      setIsLoader(true);
      const { data } = await axios.get(
        "/api/recruitment/npsc-reports/" + selectedEntitySectorId
      );
      dispatch({
        type: types.SET_NPSC_REPORTS,
        data,
      });
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getNPSCReport = (
  requestId,
  setApproveRequestForm,
  handleCloseMenu
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/recruitment/npsc-report/" + requestId
      );
      dispatch({
        type: types.SET_NPSC_REPORT,
        data,
      });
      setApproveRequestForm(true);
      handleCloseMenu();
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const submitNPSCReport = (formData, onClose, onCloseSubmitReport) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/npsc-reports",
        formData
      );

      dispatch({
        type: types.UPDATE_ADVERTISEMENT,
        data: data,
      });
      onClose();
      onCloseSubmitReport();
      dispatch({ type: types.END_LOADING });
      showSuccess("Request Submitted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const cancelNPSCReport = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/cancel-npsc-reports",
        formData
      );

      dispatch({
        type: types.UPDATE_ADVERTISEMENT,
        data: data,
      });
      dispatch({
        type: types.UPDATE_NPSC_REPORT,
        data: data.details,
      });

      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Request Cancelled successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const editNPSCReport = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/npsc-reports/" + formData.requestId,
        formData
      );

      dispatch({
        type: types.SET_NPSC_REPORT,
        data: data,
      });
      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Request updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const editNPSCReportRespondedLetter = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/modify-npsc-report-response/" + formData.requestId,
        formData
      );

      dispatch({
        type: types.SET_NPSC_REPORT,
        data: data,
      });
      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Request updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//rejectNPSCReport

export const rejectNPSCReport = (formData, onClose, onCloseReport) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/reject-npsc-report-level1/" + formData.requestId,
        formData
      );

      dispatch({
        type: types.SET_NPSC_REPORT,
        data: data,
      });
      dispatch({
        type: types.UPDATE_NPSC_REPORT,
        data: data.details,
      });
      onClose();
      onCloseReport();
      dispatch({ type: types.END_LOADING });
      showSuccess("Request rejected successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const verifyNPSCReport = (formData, onClose, onCloseReport) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/verify-npsc-report-level1/" + formData.requestId,
        formData
      );

      dispatch({
        type: types.SET_NPSC_REPORT,
        data: data,
      });
      dispatch({
        type: types.UPDATE_NPSC_REPORT,
        data: data.details,
      });
      onClose();
      onCloseReport();
      dispatch({ type: types.END_LOADING });
      showSuccess("Request verified successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveNPSCReport = (formData, onClose, onCloseReport) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/approve-npsc-report-level1/" + formData.requestId,
        formData
      );

      dispatch({
        type: types.SET_NPSC_REPORT,
        data: data,
      });
      dispatch({
        type: types.UPDATE_NPSC_REPORT,
        data: data.details,
      });
      onClose();
      onCloseReport();
      dispatch({ type: types.END_LOADING });
      showSuccess("Request approved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const approveResponseOfNPSCReport = (
  formData,
  onClose,
  onCloseReport
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/approve-npsc-report-level2/" + formData.requestId,
        formData
      );

      dispatch({
        type: types.SET_NPSC_REPORT,
        data: data,
      });
      dispatch({
        type: types.UPDATE_NPSC_REPORT,
        data: data.details,
      });
      onClose();
      onCloseReport();
      dispatch({ type: types.END_LOADING });
      showSuccess("Request approved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const verifyResponseOfNPSCReport = (
  formData,
  onClose,
  onCloseReport
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/verify-npsc-report-level2/" + formData.requestId,
        formData
      );

      dispatch({
        type: types.SET_NPSC_REPORT,
        data: data,
      });
      dispatch({
        type: types.UPDATE_NPSC_REPORT,
        data: data.details,
      });
      onClose();
      onCloseReport();
      dispatch({ type: types.END_LOADING });
      showSuccess("Request verified successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getAllCandidatesEmployed = (
  advertisementId,
  recruitmentReportId
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        `/api/recruitment/all-candidates-employed/${advertisementId}/${recruitmentReportId}`
      );
      dispatch({
        type: types.SET_CANDIDATES_EMPLOYED,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const doNPSCConfirmEmployedCandidate = (
  payload,
  setShowRejectEmployeeDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/npsc-confirm-employed-candidate",
        payload
      );

      dispatch({
        type: types.SET_CANDIDATES_EMPLOYED,
        data: data,
      });

      setShowRejectEmployeeDialog(false);

      dispatch({ type: types.END_LOADING });
      showSuccess(
        payload.isApproved === 1
          ? "Approved successfully"
          : "Rejected successfully"
      );
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateRecruitmentRequestAttachment = (
  payload,
  setFilteredAttachments,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/update-recruitment-request-attachment",
        payload
      );
      showSuccess("Attachment updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_RECRUITMENT_REQUEST_ATTACHMENTS,
        data,
      });

      setFilteredAttachments(data);

      onClose();
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const updateRecruitmentRequestLetter = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/recruitment/update-recruitment-request-letter/" +
          formData.requestId,
        formData
      );

      dispatch({
        type: types.SET_RECRUITMENT_REQUEST,
        data: data,
      });
      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Request updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getTMISVacants = (
  positionId,
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);

      // dispatch({
      //   type: types.SET_TMIS_VANCANTS,
      //   data: [],
      // });

      // setTotalRequests(0);

      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/teacher-vacant-posts/" +
          positionId +
          "?" +
          new URLSearchParams(query).toString()
      );
      dispatch({
        type: types.SET_TMIS_VANCANTS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);
      setLoading(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const loadTMISVacants = (positionId, setTotalRequests, onClose) => {
  return async (dispatch) => {
    try {
      // dispatch({
      //   type: types.SET_TMIS_VANCANTS,
      //   data: [],
      // });
      // setTotalRequests(0);

      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/external/load-tmis-vacants/" + positionId
      );
      dispatch({
        type: types.SET_TMIS_VANCANTS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);
      dispatch({ type: types.END_LOADING });
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadTeacherVacants = (
  selectedAdvertisement,
  query,
  setShowProgressBar
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowProgressBar(true);
      const { data } = await axios.get(
        "/api/recruitment/download-teacher-vacants/" +
          selectedAdvertisement.positionId +
          "?" +
          new URLSearchParams(query).toString(),
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `VACANT-POSTS-${selectedAdvertisement.positionName}.xlsx`,
        ".xlsx"
      );

      dispatch({ type: types.END_LOADING });

      setShowProgressBar(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowProgressBar(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getTeachersPlacements = (
  advertisementId,
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);

      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/recruitment/teachers-placements/" +
          advertisementId +
          "?" +
          new URLSearchParams(query).toString()
      );
      dispatch({
        type: types.SET_TEACHERS_PLACEMENTS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);
      setLoading(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const executePlacementAlgorithm = (advertisementId, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/execute-placement-algorithm",
        { advertisementId }
      );

      dispatch({ type: types.END_LOADING });

      onClose();

      dispatch({
        type: types.SET_TEACHERS_PLACEMENTS,
        data: data.records,
      });

      showSuccess("Placement algorithm executed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const publishTeacherPlacements = (advertisementId, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/publish-teacher-placements",
        { advertisementId }
      );

      dispatch({ type: types.END_LOADING });

      onClose();

      dispatch({
        type: types.SET_TEACHERS_PLACEMENTS,
        data: data.records,
      });

      showSuccess("Teachers placement published successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const downloadTeacherPlacements = (
  selectedAdvertisement,
  query,
  setShowProgressBar
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setShowProgressBar(true);
      const { data } = await axios.get(
        "/api/recruitment/download-teacher-placements/" +
          selectedAdvertisement.id +
          "?" +
          new URLSearchParams(query).toString(),
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `TEACHER PLACEMENT-${selectedAdvertisement.positionName}.xlsx`,
        ".xlsx"
      );

      dispatch({ type: types.END_LOADING });

      setShowProgressBar(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setShowProgressBar(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
