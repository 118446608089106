import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  Typography,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  getActivePayrollGroups,
  getActiveEmployeeGroups,
  getActiveSourceOfFunds,
  generatePayroll,
} from "../../../store/payroll/actions";

import { showError } from "../../../app/toastify";
import { useHistory } from "react-router-dom";

const GeneratePayrollDialog = (props) => {
  const {
    loading,
    onWait,
    costCenters,
    employeeGroups,
    payrollGroups,
    payrollTypes,
    sourceOfFunds,
    generatePayroll,
    showGeneratePayrollForm,
    setShowGeneratePayrollForm,
    getActiveEmployeeGroups,
    getActivePayrollGroups,
    getActiveSourceOfFunds,
    isBonus,
    payrollPeriods,
  } = props;

  // const [generatePayrollForm, setGeneratePayrollForm] = useState({
  //   payrollTypeId: "",
  //   arrearTypeId: "",
  //   employeeGroupId: "",
  //   sourceOfFund: "",
  // });

  const [payrollType, setPayrollType] = useState(null);
  const [arrearType, setArrearType] = useState(null);
  const [costCenter, setCostCenter] = useState(null);
  const [employeeGroup, setEmployeeGroup] = useState(null);
  const [payrollGroup, setPayrollGroup] = useState(null);
  const [sourceOfFund, setSourceOfFund] = useState(null);
  const [allEmbassies, setAllEmbassies] = useState(false);

  const [payrollPeriod, setPayrollPeriod] = useState(payrollPeriods[0] || null);

  const [errors, setErrors] = useState({
    payrollTypeHasError: false,
    arrearTypeHasError: false,
    costCenterHasError: false,
    employeeGroupHasError: false,
    payrollGroupHasError: false,
    sourceOfFundHasError: false,
    payrollPeriodHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      payrollTypeHasError: false,
      arrearTypeHasError: false,
      costCenterHasError: false,
      employeeGroupHasError: false,
      payrollGroupHasError: false,
      sourceOfFundHasError: false,
      payrollPeriodHasError: false,
      hasError: false,
    };

    if (!payrollType) {
      error.payrollTypeHasError = true;
      error.hasError = true;
    }

    if (!payrollPeriod) {
      error.payrollPeriodHasError = true;
      error.hasError = true;
    }

    if (!arrearType && payrollType?.id === 2) {
      error.arrearTypeHasError = true;
      error.hasError = true;
    }

    if (!costCenter) {
      error.costCenterHasError = true;
      error.hasError = true;
    }

    if (!employeeGroup) {
      error.employeeGroupHasError = true;
      error.hasError = true;
    }

    if (!costCenter) {
      error.costCenterHasError = true;
      error.hasError = true;
    }
    if (!payrollGroup) {
      error.payrollGroupHasError = true;
      error.hasError = true;
    }

    if (!sourceOfFund) {
      error.sourceOfFundHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const history = useHistory();

  const onGenerate = () => {
    if (!formValidator()) {
      generatePayroll(
        {
          payrollTypeId: payrollType.id,
          periodId: payrollPeriod.periodId,
          arrearTypeId: arrearType?.id || null,
          costCenterId: costCenter.id,
          employeeGroupId: employeeGroup.id,
          payrollGroupId: payrollGroup.id,
          sourceOfFundId: sourceOfFund.id,
          isEmbassy: costCenter.isEmbassy,
          allEmbassies: allEmbassies,
        },
        setShowGeneratePayrollForm,
        history
      );
    }
  };

  useEffect(() => {
    setPayrollType(null);
    setArrearType(null);
    setCostCenter(null);
    setEmployeeGroup(null);
    setPayrollGroup(null);
    setSourceOfFund(null);
  }, [showGeneratePayrollForm]);

  const onClose = () => {
    setShowGeneratePayrollForm(false);
  };

  const getArrearTypes = () => payrollTypes.filter(({ id }) => id !== 2);

  useEffect(() => {
    setCostCenter(null);
    setArrearType(null);
    setEmployeeGroup(null);
    setPayrollGroup(null);
    setSourceOfFund(null);

    if (!!payrollTypes && payrollTypes.length === 1)
      setPayrollType(payrollTypes[0]);

    if (isBonus && !payrollTypes.find(({ id }) => id === 17))
      setPayrollType(payrollTypes.find(({ id }) => id === 5));
  }, [payrollTypes]);

  useEffect(() => {
    setEmployeeGroup(null);
    setPayrollGroup(null);
    setSourceOfFund(null);

    if (!!payrollType && !!getArrearTypes() && getArrearTypes().length === 1)
      setArrearType(getArrearTypes()[0]);
    else getArrearTypes(null);
  }, [payrollType?.id, payrollTypes]);

  useEffect(() => {
    setEmployeeGroup(null);
    setPayrollGroup(null);
    setSourceOfFund(null);

    if (
      !!payrollType &&
      !!costCenters &&
      costCenters.length === 1 &&
      (payrollType.id !== 2 ||
        (payrollType.id === 2 && getArrearTypes().length <= 1))
    ) {
      setCostCenter(costCenters[0]);
      setErrors({ ...errors, costCenterHasError: false });
    } else setCostCenter(null);
  }, [payrollType?.id, costCenters]);

  useEffect(() => {
    setPayrollGroup(null);
    setSourceOfFund(null);

    if (!!costCenter && !!employeeGroups && employeeGroups.length === 1) {
      setEmployeeGroup(employeeGroups[0]);
      setErrors({ ...errors, employeeGroupHasError: false });
    } else setEmployeeGroup(null);
  }, [costCenter?.id, employeeGroups]);

  useEffect(() => {
    setSourceOfFund(null);
    if (!!employeeGroup && !!payrollGroups && payrollGroups.length === 1) {
      setPayrollGroup(payrollGroups[0]);
      setErrors({ ...errors, payrollGroupHasError: false });
    } else setPayrollGroup(null);
  }, [employeeGroup?.id, payrollGroups]);

  useEffect(() => {
    if (!!payrollGroup && !!sourceOfFunds && sourceOfFunds.length === 1) {
      setSourceOfFund(sourceOfFunds[0]);
      setErrors({ ...errors, sourceOfFundHasError: false });
    } else setSourceOfFund(null);
  }, [payrollGroup?.id, sourceOfFunds]);

  useEffect(() => {
    if (!!payrollType && !!costCenter) getActiveEmployeeGroups(costCenter.id);
  }, [costCenter?.id]);

  useEffect(() => {
    if (!!payrollType && !!costCenter && !!employeeGroup)
      getActivePayrollGroups(costCenter.id, employeeGroup.id);
  }, [employeeGroup?.id]);

  useEffect(() => {
    if (!!payrollType && !!costCenter && !!employeeGroup && !!payrollGroup)
      getActiveSourceOfFunds(costCenter.id, employeeGroup.id, payrollGroup.id);
  }, [payrollGroup?.id]);

  const filterPayrollTypes = () => {
    if (isBonus) {
      return payrollTypes.filter(({ id }) => [5, 17].includes(id));
    } else return payrollTypes.filter(({ id }) => ![5, 17].includes(id));
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showGeneratePayrollForm}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Generate Payroll
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        {onWait && <LinearProgress />}
        <DialogContent dividers>
          <div style={{ minHeight: "38vh" }}>
            <div className="row">
              <div className="col-6">
                <Autocomplete
                  disabled={!filterPayrollTypes().length}
                  size="small"
                  id="payrollType"
                  defaultValue={null}
                  value={payrollType}
                  options={filterPayrollTypes()}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, payrollType) => {
                    setPayrollType(payrollType || null);
                    setAllEmbassies(false);

                    setErrors({ ...errors, payrollTypeHasError: false });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, payrollType) => (
                    <Box component="li" {...props}>
                      {payrollType.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Payroll type"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.payrollTypeHasError && (
                  <small className="text-danger ">
                    Payroll type is required{" "}
                  </small>
                )}
              </div>
              {payrollType?.id !== 2 && (
                <div className="col-6">
                  <Autocomplete
                    size="small"
                    id="payrollPeriod"
                    defaultValue={null}
                    value={payrollPeriod}
                    options={payrollPeriods}
                    isOptionEqualToValue={(option, value) =>
                      option.periodId === value.periodId
                    }
                    onChange={(event, period) => {
                      setPayrollPeriod(period || null);

                      setErrors({ ...errors, payrollPeriodHasError: false });
                    }}
                    getOptionLabel={(option) => option.periodName}
                    renderOption={(props, period) => (
                      <Box component="li" {...props}>
                        {period.periodName}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Period"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  {errors.payrollPeriodHasError && (
                    <small className="text-danger ">Period is required</small>
                  )}
                </div>
              )}

              {!!payrollType && payrollType.id === 2 && (
                <div className="col-6">
                  <Autocomplete
                    size="small"
                    id="arrearType"
                    defaultValue={null}
                    value={arrearType}
                    options={getArrearTypes()}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, arrearType) => {
                      setArrearType(arrearType || null);
                      setAllEmbassies(false);

                      setErrors({ ...errors, arrearTypeHasError: false });
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, arrearType) => (
                      <Box component="li" {...props}>
                        {arrearType.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Arrear type"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  {errors.arrearTypeHasError && (
                    <small className="text-danger mb-1 ">
                      Arrear Type is required{" "}
                    </small>
                  )}
                </div>
              )}

              <div className="col-12 mt-3">
                <Autocomplete
                  disabled={!payrollType || !!allEmbassies}
                  size="small"
                  id="costCenter"
                  defaultValue={null}
                  value={costCenter}
                  options={costCenters}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, costCenter) => {
                    setCostCenter(costCenter || null);
                    setEmployeeGroup(null);
                    setPayrollGroup(null);
                    setSourceOfFund(null);
                    setAllEmbassies(false);

                    //setArrearType(null);

                    setErrors({ ...errors, costCenterHasError: false });
                  }}
                  getOptionLabel={(option) =>
                    !allEmbassies
                      ? option.id + "-" + option.name
                      : "All embassies"
                  }
                  renderOption={(props, costCenter) => (
                    <Box component="li" {...props}>
                      {costCenter.id + " - " + costCenter.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cost center"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.costCenterHasError && (
                  <small className="text-danger">Costcenter is required </small>
                )}
              </div>

              <div className="col-12 mt-3">
                <Autocomplete
                  disabled={!costCenter}
                  size="small"
                  id="employeeGroup"
                  defaultValue={null}
                  value={employeeGroup}
                  options={employeeGroups}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, employeeGroup) => {
                    setEmployeeGroup(employeeGroup || null);
                    setPayrollGroup(null);
                    setSourceOfFund(null);
                    setAllEmbassies(false);

                    setErrors({ ...errors, employeeGroupHasError: false });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, employeeGroup) => (
                    <Box component="li" {...props}>
                      {employeeGroup.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Employee group"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.employeeGroupHasError && (
                  <small className="text-danger">
                    Employee group is required{" "}
                  </small>
                )}
              </div>

              <div className="col-12 mt-3">
                <Autocomplete
                  disabled={!employeeGroup}
                  size="small"
                  id="payrollGroup"
                  defaultValue={null}
                  value={payrollGroup}
                  options={payrollGroups}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, payrollGroup) => {
                    setPayrollGroup(payrollGroup || null);
                    setSourceOfFund(null);
                    setAllEmbassies(false);

                    // const payrollGroupHasError = false;
                    setErrors({ ...errors, payrollGroupHasError: false });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, payrollGroup) => (
                    <Box component="li" {...props}>
                      {payrollGroup.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Payroll group"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.payrollGroupHasError && (
                  <small className="text-danger">
                    Employee group is required{" "}
                  </small>
                )}
              </div>

              <div className="col-12 mt-3">
                <Autocomplete
                  disabled={!payrollGroup}
                  size="small"
                  id="sourceOfFund"
                  defaultValue={null}
                  value={sourceOfFund}
                  options={sourceOfFunds}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, sourceOfFund) => {
                    setSourceOfFund(sourceOfFund || null);
                    setAllEmbassies(false);

                    setErrors({ ...errors, sourceOfFundHasError: false });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, sourceOfFund) => (
                    <Box component="li" {...props}>
                      {sourceOfFund.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Source of fund"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.sourceOfFundHasError && (
                  <small className="text-danger ">
                    Source of fund is required{" "}
                  </small>
                )}
              </div>

              {!!sourceOfFund && !!costCenter?.isEmbassy && (
                <div className="col-12 d-flex justify-content-end">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={allEmbassies}
                      />
                    }
                    label={<>All embassies</>}
                    onChange={(e) => {
                      setAllEmbassies(e.target.checked);
                    }}
                    disabled={loading}
                  />
                </div>
              )}
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center pb-4">
          <button
            onClick={onGenerate}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading || onWait}
          >
            {onWait ? "Generating..." : "Generate Payroll"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  costCenters,
  employeeGroups,
  payrollGroups,
  payrollTypes,
  sourceOfFunds,
}) => {
  return {
    loading,
    onWait,
    costCenters,
    employeeGroups,
    payrollGroups,
    payrollTypes,
    sourceOfFunds,
  };
};
export default connect(mapStateToProps, {
  getActivePayrollGroups,
  getActiveEmployeeGroups,
  getActiveSourceOfFunds,
  generatePayroll,
})(GeneratePayrollDialog);
