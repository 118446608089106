import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Activities from "./pages/Activities";
import Outputs from "./pages/Outputs";
import Indicators from "./pages/Indicators";
import UnitIndicators from "./pages/UnitIndicators";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import showLoading from "../assets/loading.gif";

import { getUser } from "../../store/common/actions";
import {
  getFiscalYears,
  getUserEntities,
  getSelectedFiscalYear,
} from "../../store/rbm/actions";

import MenuBar from "./components/MenuBar";
import Proflile from "../common/pages/Profile";
import MyAcount from "../common/pages/MyAcount";
import Monitor from "./pages/Monitor";
import UserGuide from "./pages/UserGuide";
import MySubordinates from "./pages/MySubordinates";
import MyContracts from "./pages/MyContracts";

function calculateTimeLeft(selectedFiscalYear) {
  const year = new Date().getFullYear();
  const difference = +new Date(`${selectedFiscalYear.deadLine}`) - +new Date();

  // console.log(new Date(`${year}-10-1`));
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

const Layout = (props) => {
  const history = useHistory();
  const location = useLocation();

  const {
    user,
    getFiscalYears,
    getSelectedFiscalYear,
    selectedFiscalYear,
    fiscalYears,
    loading,
    env,
  } = props;

  useEffect(() => {
    if (!fiscalYears.length) getFiscalYears();
    if (isEmpty(selectedFiscalYear)) getSelectedFiscalYear();
  }, []);

  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(selectedFiscalYear)
  );
  // useEffect(() => {
  //   const id = setTimeout(() => {
  //     setTimeLeft(calculateTimeLeft(selectedFiscalYear));
  //   }, 1000);

  //   return () => {
  //     clearTimeout(id);
  //   };
  // });

  const timerComponents = Object.keys(timeLeft).map((interval, index) => {
    if (!timeLeft[interval]) {
      <span key={index}>00 {interval} </span>;
    }

    return (
      <span key={index}>
        {timeLeft[interval].toString().padStart(2, "0")} {interval}{" "}
      </span>
    );
  });

  useEffect(() => {
    if (isEmpty(user)) return history.push("/");
  }, []);

  return (
    <React.Fragment>
      {!isEmpty(user) && (
        <>
          <MenuBar />

          {!isEmpty(selectedFiscalYear) &&
            selectedFiscalYear.showDeadLine &&
            !loading && (
              <div
                className="alert alert-warning  py-0 mb-0 weak-password-div  text-center text-uppercase"
                style={{
                  borderBottomColor: "#ff5722",
                  borderRadius: "0px",
                }}
              >
                {selectedFiscalYear.isMarqueeMessage && (
                  <marquee behavior="" direction="">
                    <strong style={{ color: "#79047a" }}>
                      {selectedFiscalYear.deadLineMessage}
                    </strong>
                  </marquee>
                )}

                {!selectedFiscalYear.isMarqueeMessage && (
                  <>
                    <span
                      className="mr-2 font-weight-bold"
                      style={{ color: "#078ece" }}
                    >
                      {selectedFiscalYear.deadLineMessage}:
                    </span>
                    <span
                      className="font-weight-bold "
                      style={{ color: "red" }}
                    >
                      {timerComponents.length ? (
                        timerComponents
                      ) : (
                        <span>00 DAYS 00 HOURS 00 MINUTES 00 SECONDS</span>
                      )}
                    </span>
                  </>
                )}
              </div>
            )}

          <div
            className={`container-fluid ${
              location.pathname === "/rbm/user-guide"
                ? "pt-0 px-0"
                : "pt-2 px-4"
            } `}
            id="main-container"
          >
            <Switch>
              <Route path="/rbm/dashboard" component={Dashboard} />
              <Route path="/rbm/profile" component={Proflile} />
              <Route path="/rbm/my-account" component={MyAcount} />
              <Route
                path="/rbm/my-institution/indicators/activities"
                component={Activities}
              />
              <Route
                path="/rbm/my-institution/indicators"
                component={Indicators}
              />

              <Route path="/rbm/my-institution" component={Outputs} />
              <Route path="/rbm/my-subordinates" component={MySubordinates} />
              <Route path="/rbm/my-contracts" component={MyContracts} />

              <Route path="/rbm/my-unit" component={UnitIndicators} />
              <Route path="/rbm/oversight" component={Dashboard} />
              <Route path="/rbm/monitor" component={Monitor} />
              <Route path="/rbm/user-guide" component={UserGuide} />

              <Route
                path="/rbm"
                component={() => <Redirect to="/rbm/my-institution" />}
              />
            </Switch>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user,
  selectedFiscalYear,
  fiscalYears,
  userEntities,
  loading,
  env,
}) => {
  return {
    user,
    selectedFiscalYear,
    fiscalYears,
    userEntities,
    loading,
    env,
  };
};
export default connect(mapStateToProps, {
  getUser,
  getFiscalYears,
  getUserEntities,
  getSelectedFiscalYear,
})(Layout);
