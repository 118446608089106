import {
  IconButton,
  Dialog,
  DialogTitle,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import {
  downloadPDFPayroll,
  getPayrollDetails,
} from "../../../store/payroll/actions";
import { isEmpty } from "lodash";
import axios from "axios";
import PayrollPreviewSalaryDialog from "./PayrollPreviewSalaryDialog";
import PayrollPreviewArrearDialog from "./PayrollPreviewArrearDialog";
import { CSVLink } from "react-csv";
import { showError } from "../../toastify";
import PayrollPreviewTerminalBenefitDialog from "./PayrollPreviewTerminalBenefitDialog";
import PayrollPreviewPerformanceBonusDialog from "./PayrollPreviewPerformanceBonusDialog";
import PayrollPreviewLumpsumDialog from "./PayrollPreviewLumpsumDialog";
import PayrollPreviewPBFDialog from "./PayrollPreviewPBFDialog";
import PayrollPreviewFuneralIndemnityDialog from "./PayrollPreviewFuneralIndemnityDialog";

const PayrollPreviewDialog = (props) => {
  const {
    loading,
    onWait,
    showPayroll,
    setShowPayroll,
    selectedPayroll,
    downloadPDFPayroll,
    getPayrollDetails,
    payrollDetails: inputPayrollDetails,
    type,
    viewType,
  } = props;

  const onClose = () => {
    setShowPayroll(false);
  };

  const dispatch = useDispatch();

  // const [payrollDetails, setPayrollDetails] = useState([]);

  // useEffect(() => {
  //   setPayrollDetails(inputPayrollDetails[selectedPayroll.id]);
  // }, [inputPayrollDetails]);

  useEffect(() => {
    const payrollDetails = inputPayrollDetails[selectedPayroll.id];

    if (
      showPayroll &&
      (isEmpty(payrollDetails) ||
        isEmpty(payrollDetails) ||
        (!isEmpty(payrollDetails) &&
          payrollDetails.payrollId !== selectedPayroll.id))
    )
      getPayrollDetails(selectedPayroll.id, type);
  }, [showPayroll]);

  //let payList = [];

  const [payList, setPayList] = useState([]);

  useEffect(() => {
    setPayList(inputPayrollDetails[selectedPayroll.id]?.payList || []);
  }, [inputPayrollDetails]);

  const donloadCSVPayroll = () => {
    if (!!payList?.length) document.getElementById("downloadBTN").click();
  };

  const [bnrData, setBNRData] = useState([]);

  const getBNRFile = async () => {
    try {
      dispatch({
        type: "START_WAIT",
      });
      dispatch({
        type: "START_LOADING",
      });

      const { data } = await axios.get(
        "/api/payroll/bnr-file/" + selectedPayroll.id
      );

      setBNRData(data);

      dispatch({
        type: "END_LOADING",
      });
      dispatch({
        type: "END_WAIT",
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: "END_LOADING",
      });
      dispatch({
        type: "END_WAIT",
      });
    }
  };

  useEffect(() => {
    if (!!bnrData.length) document.getElementById("downloadBNRFileBTN").click();
  }, [bnrData]);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showPayroll}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          Payroll preview
          <CSVLink
            data={payList}
            filename={`Payroll - ${selectedPayroll.id}.csv`}
            className="hidden"
            id="downloadBTN"
            target="_blank"
          />
          <CSVLink
            data={bnrData}
            filename={`BNR File - ${selectedPayroll.id}.csv`}
            className="hidden"
            id="downloadBNRFileBTN"
            target="_blank"
          />
          <span
            style={{
              backgroundColor: "#fff",
              // border: "1px solid #c8c6c6",
              position: "absolute",
              right: 110,
              top: 8,
              // width: "48px",
            }}
            className="p-0 text-center btn"
          >
            {type === "Temp" && selectedPayroll.payrollTypeId !== 9 && (
              <span className="p-0 text-center btn">
                <Button
                  variant="outlined"
                  size="small"
                  disabled={loading}
                  onClick={() => getBNRFile()}
                  color="primary"
                >
                  <span className="material-icons">receipt_long</span>
                  BNR File
                </Button>
              </span>
            )}

            {selectedPayroll.payrollTypeId !== 9 && (
              <Button
                variant="outlined"
                size="small"
                disabled={loading}
                onClick={() => donloadCSVPayroll()}
                color="primary"
                className="ml-2 mr-4"
              >
                <span className="material-icons">list_alt</span>
                csv payroll
              </Button>
            )}
          </span>
          <Tooltip title="Download PDF file">
            <span
              style={{
                backgroundColor: "#fff",
                // border: "1px solid #c8c6c6",
                position: "absolute",
                right: 84,
                top: 8,
                width: "48px",
              }}
              className="p-0 text-center btn"
            >
              <Button
                variant="outlined"
                size="small"
                disabled={loading}
                onClick={() => downloadPDFPayroll(selectedPayroll.id, type)}
                className="ml-2"
                color="primary"
              >
                <span className="material-icons">picture_as_pdf</span>
                File
              </Button>

              {/* <IconButton
                size="small"
                disabled={loading}
                className=""
                color="primary"
              >
              </IconButton> */}
            </span>
          </Tooltip>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-0">
          <div className="mt-0" style={{ height: "100%" }}>
            <div
              style={{
                display: "table-cell",
                width: "100vw",
                height: "80vh",
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              {loading && !onWait && (
                <Box
                  className="justify-content-center "
                  sx={{ display: "flex" }}
                >
                  <CircularProgress />
                  <h5 className="mt-2 ml-2">Please wait ...</h5>
                </Box>
              )}
              {(!loading || onWait) && (
                <div id="container">
                  {[1, 18, 21].includes(selectedPayroll.payrollTypeId) && (
                    <PayrollPreviewSalaryDialog
                      selectedPayroll={selectedPayroll}
                      type={type}
                      viewType={viewType}
                    />
                  )}
                  {[2, 14, 19, 20, 22].includes(
                    selectedPayroll.payrollTypeId
                  ) && (
                    <PayrollPreviewArrearDialog
                      selectedPayroll={selectedPayroll}
                      type={type}
                      viewType={viewType}
                    />
                  )}
                  {[3, 6, 23].includes(selectedPayroll.payrollTypeId) && (
                    <PayrollPreviewTerminalBenefitDialog
                      selectedPayroll={selectedPayroll}
                      type={type}
                      viewType={viewType}
                    />
                  )}
                  {[5, 17].includes(selectedPayroll.payrollTypeId) && (
                    <PayrollPreviewPerformanceBonusDialog
                      selectedPayroll={selectedPayroll}
                      type={type}
                      viewType={viewType}
                    />
                  )}

                  {[7, 11].includes(selectedPayroll.payrollTypeId) && (
                    <PayrollPreviewLumpsumDialog
                      selectedPayroll={selectedPayroll}
                      type={type}
                      viewType={viewType}
                    />
                  )}
                  {selectedPayroll.payrollTypeId === 9 && (
                    <PayrollPreviewPBFDialog
                      selectedPayroll={selectedPayroll}
                      type={type}
                      viewType={viewType}
                    />
                  )}

                  {selectedPayroll.payrollTypeId === 10 && (
                    <PayrollPreviewFuneralIndemnityDialog
                      selectedPayroll={selectedPayroll}
                      type={type}
                      viewType={viewType}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="py-2 d-flex justify-content-center">
          {!!selectedPayroll.isDeclared && (
            <span className={`p-1 badge badge-success`}>
              RSSB Declarations sent
            </span>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  payrollPreviewed,
  payrollDetails,
}) => {
  return { user, loading, onWait, payrollPreviewed, payrollDetails };
};
export default connect(mapStateToProps, {
  downloadPDFPayroll,
  getPayrollDetails,
})(PayrollPreviewDialog);
