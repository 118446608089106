import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import NewUpload from "./pages/NewUpload";
import { useRequiredAuth } from "../common/guard/requiredAuth";
import ESign from "./pages/ESign";
const Layout = () => {
  const { hasPermissions } = useRequiredAuth();
  const history = useHistory();
  const drawerWidth = 240;
  return (
    <React.Fragment>
      <>
        <div style={{ marginTop: "0px" }}>
          <div style={{ backgroundColor: "#078ece", height: "6px" }}></div>
          <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
          <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
        </div>
        <div className={`container-fluid pt-2 px-4 `} id="main-container">
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              height: { sm: `calc(100% - 105px)` },
              position: "fixed",
              top: { sm: `calc(105px)` },
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                height: { sm: `calc(100% - 105px)` },
                position: "fixed",
                top: { sm: `calc(105px)` },
                boxSizing: "border-box",
              },
            }}
          >
            <Box sx={{ overflow: "auto" }}>
              <List>
                {[
                  {
                    text: "New Doc",
                    icon: AddIcon,
                    id: 1,
                    link: "/documents/new",
                  },
                  {
                    text: "Signed Docs",
                    icon: FolderZipIcon,
                    id: 2,
                    link: "/documents/e-sign",
                  },
                ].map((item, index) => (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton onClick={() => history.push(item.link)}>
                      <ListItemIcon>
                        <item.icon />
                      </ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                    <Divider />
                  </ListItem>
                ))}
              </List>
              {/* <Divider />
              <List>
                {["All mail", "Trash", "Spam"].map((text, index) => (
                  <ListItem key={text} disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List> */}
            </Box>
          </Drawer>
          <Box
            component="main"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px`, flexGrow: 1, p: 3 },
            }}
          >
            <Typography sx={{ marginBottom: 2 }}>
              <Switch>
                <Route path="/documents/new" component={NewUpload} />
                <Route path="/documents/e-sign" component={ESign} />

                <Route
                  path="/documents"
                  component={() => <Redirect to="/documents/new" />}
                />
              </Switch>
            </Typography>
          </Box>
        </div>
      </>
    </React.Fragment>
  );
};

export default Layout;
