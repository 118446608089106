import types from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";
import download from "downloadjs";

const isPasswordStrong = (password) => {
  if (
    password &&
    password.length > 7 &&
    // eslint-disable-next-line no-useless-escape
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) &&
    /\d/.test(password) &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password)
  )
    return true;
  return false;
};

export const setUser = (data) => {
  return {
    type: types.SET_USER,
    data,
  };
};

export const getSelectedEmployeeProfilePicture = (idNumber, cb = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SELECTED_EMPLOYEE_PROFILE_PICTURE,
        data: null,
      });

      if (!!cb) dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/auth/user-profile-picture/" + idNumber
      );

      if (!!cb) {
        dispatch({ type: types.END_LOADING });
        cb();
      }

      dispatch({
        type: types.SET_SELECTED_EMPLOYEE_PROFILE_PICTURE,
        data: data.photo,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getUserProfilePicture = (idNumber) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/auth/user-profile-picture/" + idNumber
      );

      dispatch({
        type: types.SET_USER_PROFILE_PICTURE,
        data: data.photo,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEnv = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_ENV, data: null });

      const { data } = await axios.get("/api/env");

      dispatch({ type: types.SET_ENV, data });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getHRMessage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_HR_MESSAGE, data: "" });

      const { data } = await axios.get("/api/payroll/hr-message");

      dispatch({ type: types.SET_HR_MESSAGE, data });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getModules = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/ippis-modules");

      dispatch({ type: types.SET_IPPIS_MODULES, data });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const selectModule = (mod, history) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      await axios.post("/api/auth/select-module", {
        id: mod.id,
      });

      dispatch({
        type: types.SET_SELECTED_MODULE,
        data: mod,
      });
      console.log(mod);

      history.push(mod.to);

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      // dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const login = (credentials, handleCleanForm, doNotRedirect) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/auth/logins", credentials);

      try {
        if (!doNotRedirect) {
          const { data: modules } = await axios.get("/api/ippis-modules");

          dispatch({ type: types.SET_IPPIS_MODULES, data: modules });

          const { data } = await axios.get("/api/auth/users/me");

          let selectedEntitySector = {};

          if (!!data.selectedEntitySectorId) {
            selectedEntitySector = data.entitySectors.find(
              ({ id }) => id === data.selectedEntitySectorId
            );
          }

          delete data.selectedEntitySectorId;

          dispatch({
            type: types.SET_USER,
            data: { ...data, selectedEntitySector },
          });
        } else {
          dispatch({
            type: types.SET_REQUIRE_AUTHENTICATION_OFF,
          });
        }
      } catch (error) {
        if (isSessionExpired(error)) {
          dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
        }
        showError(error);
      }

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const logout = (history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/auth/logins");

      dispatch({ type: types.END_LOADING });

      history.push("/");

      dispatch({ type: types.CLEAN_STATE });
      dispatch({ type: types.LOGOUT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      history.push("/");
    }
  };
};

export const getUser = (history, location) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      let data;
      let _selectedEntitySector = {};

      try {
        const res = await axios.get("/api/auth/users/me");

        data = res.data;

        if (!!data.selectedEntitySectorId) {
          _selectedEntitySector = data.entitySectors.find(
            ({ id }) => id === data.selectedEntitySectorId
          );
        }
      } catch (error) {
        if (!location.pathname.includes("/reset-password")) history.push("/");

        dispatch({ type: types.END_LOADING });
        dispatch({ type: types.CLEAN_STATE });
        dispatch({ type: types.LOGOUT });
        return;
      }

      delete data.selectedEntitySectorId;

      try {
        const { data: modules } = await axios.get("/api/ippis-modules");

        dispatch({
          type: types.SET_USER,
          data: { ...data, selectedEntitySector: _selectedEntitySector },
        });

        dispatch({ type: types.SET_IPPIS_MODULES, data: modules });

        const mod = modules.find(({ id }) => id === data.selectedModule);

        dispatch({ type: types.SET_SELECTED_MODULE, data: mod || {} });

        delete data.selectedModule;

        setUser(data);
        if (!!mod && mod.to && location.pathname === "/") history.push(mod.to);

        dispatch({ type: types.END_LOADING });
      } catch (error) {
        await axios.delete("/api/auth/logins");
        dispatch({ type: types.CLEAN_STATE });
        dispatch({ type: types.LOGOUT });
        history.push("/");
      }
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPSRPUserToken = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/auth/psrp-token");

      window.open(
        "https://services.mifotra.gov.rw/verifyToken=" + data,
        "_blank",
        "noopener,noreferrer"
      );
      window.location.reload();
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const selectEntitySector = (entitySectorId, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/auth/select-entity-sector", {
        id: entitySectorId,
      });

      await axios.get("/api/auth/users/me");

      history.push("/");

      dispatch({
        type: types.CLEAN_STATE,
      });

      dispatch({
        type: types.SET_SELECTED_ENTITY_SECTOR,
        entitySectorId,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getRequestDocumentSignerDetails = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/api/lookup/request-document-signer-details`
      );

      dispatch({
        type: types.SET_REQUEST_DOCUMENT_SIGNER_DETAIL,
        data,
      });
    } catch (error) {
      // showError(error);
    }
  };
};

export const resetPassword = (email) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/auth/reset-password", { email });

      showSuccess(data.message);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const changePassword = (credentials, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/auth/change-password",
        credentials
      );

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.SET_STRONG_PASSWORD, data: true });

      showSuccess(data.message);

      history.push("/");
    } catch (error) {
      dispatch({ type: types.SET_STRONG_PASSWORD, data: false });

      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const register = (user, modelDismiss) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/auth/users", user);

      showSuccess(data.message);

      dispatch({ type: types.END_LOADING });

      modelDismiss.click();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadFile = (query, setShowProgressBar) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });
      setShowProgressBar(true);
      const { data } = await axios.get(
        "/api/lookup/download-file?" + new URLSearchParams(query).toString(),
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `${query.fileName}`,
        query.fileName.split(".").pop()
      );

      setShowProgressBar(false);
    } catch (error) {
      setShowProgressBar(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const handleSessionExpiration = () => {
  return (dispatch) => {
    dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
    return;
  };
};

export const isSessionExpired = (error) => {
  if (
    (error &&
      error.response &&
      error.response.data &&
      error.response.data === "SESSION_EXPIRED") ||
    error === "SESSION_EXPIRED"
  )
    return true;
  return false;
};

export const requestOTP = (credentials, cb) => {
  return async (dispatch) => {
    cb(true, false, null);
    try {
      dispatch({ type: types.START_LOADING });

      const res = await axios.post("/api/auth/request-otp", credentials);

      if (res.data.message !== "Authenticated" && !!res.data.message)
        showSuccess(res.data.message);

      cb(false, true, res.data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      cb(false, false, null);
      showError(error);
    }
  };
};

export const changeOTPSetting = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/auth/otp-setting");

      dispatch({ type: types.SET_OTP_SETTING });

      // showSuccess(data.message);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
